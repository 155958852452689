<template>
	<div v-show="events.length > 0">
		<section class="latest-events">
			<div class="intro txt-theme">
				Aktuelle begivenheder
			</div>
			<div class=" mt-5">
				<div class="row2">
					<carousel :perPageCustom="[[0, 1], [850, 2], [1000, 2], [1424, 3], [1900, 4]]" :autoplay="false"
						:autoplayTimeout="4000" :paginationSize="10" style="gap:20px" :loop="false">
						<slide v-for="(event, index) in events" :key="index">
							<div class="events-slide-content"
								@click="$router.push(`/behandler/${event.practitioner.id}/event/${event.id}`).catch(() => { })">
								<div class="outer">
									<div class="heading">
										<div class="in">
											{{ event.headline }}
										</div>
									</div>
									<div class="photo">
										<div v-if="event.isOnline" class="online">ONLINE</div>
										<img
											:src="event.filename == null ? '/behandlerportal/images/events.jpg' : `/behandlerportal/images/bh/cie_${event.id}.jpg`" />
									</div><!-- /behandlerportal/images/bh/ -->
									<div class="pract">
										<img
											:src="event.practitioner.primaryImage != null ? `/behandlerportal/images/bh/${event.practitioner.primaryImage}` : `/behandlerportal/images/user.png`" />
									</div>
									<div class="info">
										<div class="name">{{ event.practitioner.name }}</div>
										<div class="date">{{ misc.formatDateTimeForDisplay(event.startDateTime) }}</div>
									</div>
								</div>

							</div>
						</slide>
					</carousel>
				</div>
			</div>
		</section>
	</div>
</template>
<style>
.VueCarousel-pagination {
	display: flex !important;
}
</style>
<style lang="scss" scoped>
.latest-events {
	padding: 90px 20px;

	@media(max-width:667px) {
		padding: 40px 25px;
	}

	.events-slide-content {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		cursor: pointer;

		@media(max-width:450px) {
			width: 100%;
			max-width: 280px;
		}

		.outer {
			padding-bottom: 20px;
			margin: 0px 0px;
			//width: 350px;
			text-align: left;

			@media(max-width:450px) {
				width: 280px;
				text-align: center;
			}

			.heading {
				padding: 0px;
				font-size: 14px;
				margin-bottom: 10px;
				height: 54px;
				display: flex;
				vertical-align: text-bottom;

				.in {
					align-self: flex-end;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 18px;
					font-family: 'Raleway-Semibold';
					color: #515050;
					width: 100%;
				}
			}

			.photo {
				height: 210px;

				@media(max-width:450px) {
					// width: 320px;
				}

				.online {
					position: absolute;
					display: inline-block;
					padding: 2px 8px;
					background-color: #1692b6;
					font-family: 'Raleway-Semibold';
					color: white;
					margin-left: 280px;
					margin-top: 15px;
				}

				img {
					object-fit: cover;
					border-radius: 0px;
					width: 100%;
					height: 100%;
					color: white;
				}
			}

			.pract {
				height: 120px;
				width: 120px;
				position: absolute;
				top: 62%;
				margin-left: 15px;

				@media(max-width:450px) {
					height: 100px;
					width: 100px;
					top: 55%;
					left:27%;
				}

				img {
					object-fit: cover;
					border-radius: 50%;
					border: 6px solid white;
					width: 100%;
					height: 100%;
				}
			}

			.info {
				padding-left: 150px;
				padding-top: 10px;

				@media(max-width:450px) {
					width: 100%;
					padding-left: initial;
					padding-top: 20px;
					margin-top: 50px;
				}

				.name {
					font-size: 18px;
					font-family: 'Raleway-Semibold';
					color: #515050;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.date {
					color: #000;
				}

			}
		}

		.VueCarousel-dot {
			margin: 10px 6px;
			width: 10px;
			height: 10px;
			padding: 0 !important;
			background-color: white !important;
			border: 3px solid #D8D8D8 !important;

			&.VueCarousel-dot--active {
				background-color: #0094B3 !important;
			}
		}
	}

	.intro {
		font-size: 38px;
		font-family: 'Raleway-Light';

		@media(max-width:767px) {
			font-size: 28px;
		}
	}
}
</style>

<script>
import { Carousel, Slide } from 'vue-carousel';
import axios from 'axios';
import misc from '@/misc.js'

export default {
	components: {
		Carousel,
		Slide
	},
	data() {
		return {
			misc: misc,
			events: []
		}
	},
	methods: {
		fetchItems() {
			axios
				.get(`/practitioner/events/latest`)
				.then(response => {
					this.events = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
	mounted() {
		this.fetchItems();
	}
};
</script>
