<template>
	<section>
		<div class="TransitionAge px-5">
			<div class="outerItems">
				<div class="items">
					<div class="dropdown"><button type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
							aria-expanded="false" class="text-start w-100 btn bg-white dropdown-toggle"> Bedste match
						</button>
						<ul aria-labelledby="dropdownMenuButton1" class="dropdown-menu">
							<li><a href="#" class="dropdown-item">Action</a></li>
							<li><a href="#" class="dropdown-item">Another action</a></li>
							<li><a href="#" class="dropdown-item">Something else here</a></li>
						</ul>
					</div>
				</div>
				<div class="items">
					{{ searchPagingValue() }}
				</div>
				<div class="items text-center text-nowrap">
					<span class="mx-3 no link" @click="toggleMap">{{ mapVisible ? 'Vis som liste' : 'Vis på kort'}}</span>
					<span class="mx-3 link" @click="resetSearch">Ny søgning</span>
				</div>
			</div>
		</div>
		<div class="row m-0 px-0" v-if="mapVisible">
			<GmapMap :center='center' :zoom="9" style="width:100%;  height: 500px;">
				<GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
					@click="openInfoWindowTemplate(index)" />
				<gmap-info-window :options="{
					maxWidth: 300,
					pixelOffset: { width: 0, height: -35 }
				}" :position="infoWindow.position" :opened="infoWindow.open" @closeclick="infoWindow.open = false">
					<div v-html="infoWindow.template"></div>
				</gmap-info-window>
			</GmapMap>
		</div>
		<div class="container profiles" v-if="!mapVisible" style="border:0px solid">
			<vue-element-loading :active="loading" spinner="bar-fade-scale" color="#FF6700" />
			<div class="row">
				<div class="inner-profile">
					<div class="items3" v-for="(practitioner, index) in searchResults" :key="index">
						<div class="slide-content">
							<div class="photo">
								<router-link v-bind:to="{ path: `/behandler/${practitioner.id}` }">
									<img :src="`/behandlerportal/images/bh/${practitioner.primaryImage}`"
										@error="setAltImg" alt="Foto">
								</router-link>
							</div>
							<div class="name">
								<router-link v-bind:to="{ path: `/behandler/${practitioner.id}` }">{{ practitioner.name
								}}</router-link>
							</div>
							<div class="designation">{{ practitioner.tagline }}</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-lg-12 text-center">
					<router-link to="" class=' profile-link'>Vis flere resultater</router-link>
				</div> -->
			</div>
		</div>

	</section>
</template>
<style lang="scss">
.impression {
	position: absolute;
	right: -24px;
	appearance: none;
	cursor: pointer;

	&::after {
		content: "♡";
		font-size: 27px;
		color: #c3c0c0;
	}

	&:checked {
		&::after {
			content: "♡";
			font-size: 27px;
			color: #f50b0b;
		}
	}
}

.TransitionAge {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 40px 15px 20px 15px;
	margin-bottom: 0px;
	background-color: #ECF3F5;

	@media(max-width:960px) {
		flex-direction: column;
		padding: 70px 15px;
	}

	@media(max-width:767px) {
		padding: 70px 15px 12px;
	}

	.outerItems {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.items {
			flex: 1;
			padding: 0 15px;
			margin-bottom: 15px;

			.link {
				text-decoration: underline;
				cursor:pointer;
				font-family: Raleway;
			}

			&:nth-child(1) {
				@media(max-width:768px) {
					order: 3;
				}
			}

			&:nth-child(2) {
				flex: 1;
				font-size: 21px;
				font-family: 'Raleway-Medium';

				b {
					font-family: 'Raleway-Bold';
				}

				@media(max-width:768px) {
					font-size: 18px;
					text-align: center;
					order: 1;
				}
			}

			&:nth-child(3) {
				font-family: 'Raleway-Bold';

				@media(max-width:768px) {
					order: 2;
				}

				a {
					color: #000;

					&:hover {
						color: #0096B0;
					}
				}
			}

			.dropdown-toggle {
				white-space: nowrap;
				height: 60px;
				border-radius: 4px;
				outline: 0;
				border: 0;
				padding: 0 18px;
				min-width: 250px;

			}

			.dropdown-menu.show {
				width: 100%;
			}

			.dropdown-toggle::after {
				text-align: right;
				position: absolute;
				right: 20px;
				top: 28px;
				border-top: 0.4em solid;
				border-right: 0.4em solid transparent;
				border-bottom: 0;
				border-left: 0.4em solid transparent;
			}
		}
	}
}

.profiles {
	padding: 60px 0 !important;

	.profile-link {
		font-size: 14px;
		font-family: 'Raleway-Medium';
		color: #000000;
		font-size: 18px;
		text-align: center;

		&:hover {
			color: #0096B0;
		}
	}

	.inner-profile {
		display: flex;
		flex-wrap: wrap;

		.items3 {
			flex-basis: 20%;

			@media (max-width:1023px) {
				flex-basis: 50%;
			}

			@media (max-width:768px) {
				flex-basis: 100%;
			}

			.slide-content {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				margin-bottom: 50px;

				a {
					width:100%;
					height:100%;
				}

				.photo {
					height: 146px;
					width: 146px;
					border-radius: 50%;
					display: flex;
					align-content: center;
					justify-content: center;
					margin-bottom: 25px;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}

				.name {
					font-size: 18px;
					font-family: 'Raleway-Semibold';
					color: #515050;
					line-height: 1.2em;
				}

				.designation {
					font-size: 14px;
					padding-top: 3px;
					font-family: "Raleway-Semibold";
					color: #1692b6;
					line-height: 1.5;
				}

				.desc {
					font-size: 14px;
					font-family: 'Raleway-Medium';
					color: #9eacba;
					text-align: center;
				}
			}
		}
	}
}
</style>
<script>
import axios from 'axios'
import { mapGetters } from "vuex";
import VueElementLoading from 'vue-element-loading'

export default {
	components: { VueElementLoading },
	data() {
		return {
			searchValue: '',
			loading: false,
			gettingLocation: false,
			location: null,
			selected: null,
			locationErrorStr: null,
			center: { lat: 55.52244, lng: 11.125111 },
			currentPlace: null,
			markers: [],
			places: [],
			searchResults: [],
			infoWindow: {
				position: { lat: 0, lng: 0 },
				open: false,
				template: ""
			},
			retryGetLocationCount: 0,
		}
	},
	computed: {
		...mapGetters(["mapVisible"]),
	},
	watch: {
		'$route': {
			handler: function (to) {
				this.searchValue = to.query.q;
				this.treatmentId = to.query.treatmentId;
				if (isNaN(this.treatmentId))
					this.treatmentId = -1;
				console.log(">:" + this.treatmentId);
				this.doSearch();
			},
			deep: true,
			immediate: true
		},
	},
	methods: {
		toggleMap() {
			let mapVisible = this.mapVisible;
			mapVisible = !mapVisible;
			this.$store.commit('mapVisible', !this.mapVisible);
		},
		searchPagingValue() {
			if (this.searchResults == null) return "";
			let showCount = this.searchResults.length.toString();
			if (this.searchResults.length == 1)
				showCount += " resultat";
			else if (this.searchResults.length >= 100)
				showCount += "+ resultater"
			else
				showCount += " resultater";
			return `${showCount}`;
		},
		getLocation() {
			navigator.geolocation.getCurrentPosition(pos => {
				this.gettingLocation = false;
				this.location = pos;
				this.center = { lat: this.location.coords.latitude, lng: this.location.coords.longitude };
			}, err => {
				this.gettingLocation = false;
				this.errorStr = err.message;
			});
		},
		setAltImg(event) {
			let r = Math.floor(Math.random() * 10) % 10;
			if (r == 0)	//random user has no image
				event.target.src = "/behandlerportal/images/user.png";
			else
				event.target.src = "https://xsgames.co/randomusers/avatar.php?g=female&rnd=" + Math.floor(Math.random() * 10);
		},
		doSearch() {
			if (this.location == null) {
				if (this.retryGetLocationCount++ < 2) {
					setTimeout(this.doSearch, 100);
					return;
				}
				else
					this.location = { coords: { latitude: 55.52244325631377, longitude: 11.125111306702724 } }
			}
			this.loading = true;
			this.searchResults = null;
			//debugger; // eslint-disable-line no-debugger
			axios
				.get(
					`/search?query=${this.searchValue}&lat=${this.location.coords.latitude}&lng=${this.location.coords.longitude}&treatmentId=${this.treatmentId}`)
				.then((response) => {
					this.searchResults = response.data;
					this.markers = [];
					for (var n = 0; n < this.searchResults.length; n++) {
						if (this.searchResults[n].lat == null)
							continue;
						let marker = {
							lat: this.searchResults[n].lat,
							lng: this.searchResults[n].long,
						};
						this.markers.push({ position: marker });
					}
				}).catch((error) => console.log(error))
				.finally(() => {
					this.loading = false;
				});
		},
		openInfoWindowTemplate(index) {
			const pract = this.searchResults[index];
			this.infoWindow.position = { lat: pract.lat, lng: pract.long };
			const info = pract.tagline ?? "";
			this.infoWindow.template = `<b>${pract.name}</b><br/>${info}<br/>
					<a href='/behandler/${pract.id}' style='font-size:100%'>Klik for profilside</a>`;
			this.infoWindow.open = true;
		},
		resetSearch() {
			this.$emit("resetSearch");
		}
	},
	created() {
		this.getLocation();
	},
};
</script>