<template>
	<div>
		<div class="title"><span class="treatment">{{ treatmentTitle() }}</span></div>
		<div class="wrap">
			<div class="left">
				<DayPilotNavigator id="nav" ref="nav" :config="navigatorConfig" />
			</div>
			<div class="content">
				<div class="mobilePicker">
					Dato: <span @click="toggleDatePicker" class="navigatorCurDate"><b>{{
							misc.formatDateForDisplay(startDate)
					}}</b>&nbsp;
						<b-icon icon="chevron-down" />
					</span><br />
				</div>
				<div style="position:absolute;z-index:100;margin-top:0px" v-if="datePickerShown">
					<DayPilotNavigator id="nav2" ref="nav2" :config="navigatorConfig" />
				</div>
				<!-- <DayPilotCalendar id="dp" :config="config" ref="calendar" /> -->
				<div class="tabletPicker">
					<div class="select">
						<span>{{ misc.formatDateForDisplay(startDate) }}</span> ➖ Vælg et tidspunkt
					</div>
				</div>
				<span v-for="evt in events" :key="evt.start">
					<div class="timePeriod" v-if="evt.timePeriodOfDay">{{ evt.timePeriodOfDay }}</div>
					<span class="pill" @click="selectEmptySlot(evt.start)">{{ misc.formatTime(evt.start) }} </span>
				</span>
				<div v-if="events.length == 0" class="noSlots">
					Ingen ledige tider. Prøv en anden dag
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.title {
	margin-bottom: 20px;
	font-family: 'Raleway-Bold';
	font-weight: 800;

	.treatment {
		padding: 10px;
		background-color: white;
		color: black;
		border-radius: 10px;
	}
}

.wrap {
	display: flex;

	.left {
		margin-right: 10px;

		@media (max-width: 750px) {
			flex-wrap: wrap;
			display: none
		}
	}


	.content {
		flex-grow: 1;
		max-height: 500px;
		overflow-y: auto;
		background-color: white;
		border-radius: 4px;
		padding: 20px;

		.mobilePicker {
			.navigatorCurDate {
				border: 1px solid #ddd;
				border-radius: 5px;
				padding: 5px;
				font-size: 80%;
			}

			@media (min-width: 750px) {
				display: none;
			}

			@media (max-width: 750px) {}
		}

		.tabletPicker {
			@media (max-width: 750px) {
				display: none;
			}
		}

		.chooseTime {
			font-weight: 400;
			color: rgb(96, 96, 96);
		}

		.timePeriod {
			color: #888;
			font-size: 80%;
			margin-top: 10px;
		}

		.pill {
			background-color: #1692B6;
			color: white;
			padding: 3px;
			border-radius: 4px;
			margin-right: 5px;
			font-size: 14px;
			cursor: pointer;
		}

		.noSlots {
			margin-top: 10px;
		}
	}
}
</style>
<style type="text/css">
.calendar_default_event_inner {
	background: #2e78d6;
	color: white;
	border-radius: 5px;
	opacity: 0.9;
}

.navigator-disabled-cell {
	background-color: #ddd !important;
	color: #888;
	cursor: not-allowed !important;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { DayPilot, DayPilotNavigator } from '@daypilot/daypilot-lite-vue'
import misc from '@/misc.js'
export default {
	components: { DayPilotNavigator },
	computed: {
		...mapGetters(["practitioner", "practitionerId"]),
	},
	watch: {
		practitionerId() {
			this.fetchDate(this.startDate);
		},
	},
	props: {
		treatment: Object,
	},
	data() {
		return {
			misc: misc,
			datePickerShown: false,
			showForm: false,
			timePeriods: [
				{ name: "morning", title: "Formiddag" },
				{ name: "midday", title: "Middag" },
				{ name: "afternoon", title: "Eftermiddag" },
				{ name: "evening", title: "Aften" },
			],
			//                  1  2  3  4  5  6  7  8  9 10 11 12 13 14 15 16 17 18 19 20 21 22 23
			timePeriodIndexes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 3],
			lastTimePeriod: null,
			events: [],
			days: 1,
			startDate: DayPilot.Date.today(),
			navigatorConfig: {
				showMonths: 1,
				skipMonths: 1,
				selectMode: "Day",
				startDate: this.startDate,
				selectionDay: this.startDate,
				onTimeRangeSelect: (args) => {
					if (args.day < DayPilot.Date.today()) {
						//debugger // eslint-disable-line no-debugger
						args.preventDefault();
						//this.select(this.startDate, { dontNotify: true, dontFocus: true });
						return;
					}
					else {
						this.startDate = args.start;
					}
				},
				onTimeRangeSelected: args => {
					if (args.day < DayPilot.Date.today()) {
						args.preventDefault();
						return;
					}
					this.config.startDate = args.day;
					//console.log(args.day);
					if (this.datePickerShown) this.datePickerShown = false;
					this.fetchDate(args.day);
				},
				onBeforeCellRender: args => {
					if (args.cell.day < DayPilot.Date.today()) {
						args.cell.cssClass = "navigator-disabled-cell";
					}
				},
			},
			config: {
				viewType: "Days",
				locale: "da-dk",
				days: 3,
				startDate: this.startDate,
				durationBarVisible: false,
				headerDateFormat: "dd MMM",
				timeRangeSelectedHandling: "Enabled",
				heightSpec: "BusinessHoursNoScroll",
				onTimeRangeSelected: async (args) => {
					const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
					const dp = args.control;
					dp.clearSelection();
					if (modal.canceled) {
						return;
					}
					dp.events.add({
						start: args.start,
						end: args.end,
						id: DayPilot.guid(),
						text: modal.result
					});
				},
				eventDeleteHandling: "Disabled",
				onEventMoved: (evt) => {
					console.log("Event moved");
					console.log(evt);
				},
				onEventResized: () => {
					console.log("Event resized");
				},
			},
		}
	},
	methods: {
		toggleDatePicker() {
			this.datePickerShown = !this.datePickerShown;
		},
		selectEmptySlot(time) {
			console.log(time);
			this.$emit("whenSelected", time);
		},
		getDurationForDisplay() {	//01:45
			let durationStr = this.treatment.duration;
			let hours = parseInt(durationStr.substr(0, 2));
			let minutes = parseInt(durationStr.substr(3, 2));
			let res = "";
			if (hours > 0) res = `${hours} time${hours > 1 ? 'r' : ''} og `;
			res += `${minutes} minutter`;
			return res;
		},
		treatmentTitle() {
			if (!this.treatment) return "";
			return this.treatment.treatmentName + ", " + this.getDurationForDisplay();
		},
		getDurationMinutes() {	//01:45
			if (this.treatment == null) return "";
			let durationStr = this.treatment.duration;
			let hours = parseInt(durationStr.substr(0, 2));
			let minutes = parseInt(durationStr.substr(3, 2));
			return hours * 60 + minutes;
		},
		showTitle(event) {
			//console.log(`Start: ${this.cnt++}: ${event.start}`);
			let d = new Date(event.start)
			let timePeriod = this.timePeriods[this.timePeriodIndexes[d.getHours()]];
			if (timePeriod != this.lastTimePeriod) {
				this.lastTimePeriod = timePeriod;
				//console.log(d + ", " + timePeriod.title);
				return true;
			}
			return false;
		},
		fetchDate(date) {
			if (this.treatment == null) return;
			axios
				.get(`/booking/${this.practitioner.id}/free?d=${this.misc.formatDateForApi(date)}&days=${this.days}&minutesduration=${this.getDurationMinutes()}`)
				.then(response => {
					this.lastTimePeriod = null;
					this.cnt = 0;
					let evts = response.data;
					let lastTimePeriodIndex = -1;
					for (let n = 0; n < evts.length; n++) {
						let d = new Date(evts[n].start)
						let timePeriodIndex = this.timePeriodIndexes[d.getHours()];
						if (timePeriodIndex != lastTimePeriodIndex) {
							let timePeriod = this.timePeriods[timePeriodIndex];
							evts[n].timePeriodOfDay = timePeriod.title;
							lastTimePeriodIndex = timePeriodIndex;
						}
					}
					this.events = evts;
				})
				.catch(error =>
					console.log(error)
				);
		},
		init() {
			this.$nextTick(() => { this.fetchDate(this.startDate); });
		}
	},
}
</script>