<template>
	<transition name="fade" v-if="showModal" class="topMost">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Velkommen som behandler!</h5>
							<b-button type="button" @click="closeWindow" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<h4>Sådan kommer du i gang</h4>
							<p>
								<b-icon icon="arrow-right-circle-fill" class="arr" font-scale="1.5" />Ud for hver
								sektion er der et lille blyant ikon:
								<b-icon icon="pencil-square" /><br />
								Klik på ikonet for at administrere sektionen.
							</p>

							<p>
								<b-icon icon="arrow-right-circle-fill" class="arr" font-scale="1.5" /><img
									src="/behandlerportal/images/admin-profil.jpg"
									style="float:right;width:200px;margin-left:10px;" />Når du er tilfreds med dine
								rettelser mv, så kan du 'tænde' for din profil, hvilket
								du gør i menuen øverst højre hjørne. Det kan være en såkaldt “burger-menu”, hvis du
								sidder på en mobil, eller der hvor du kan se dit brugernavn. <br />
								I den menu vil du kunne aktivere din profil.
							</p>
							<br clear="all" />
							<p><input type="checkbox" v-model="neverShowAgain" @click="setTipVersionRead" /> Vis ikke
								igen</p>
						</div>
						<div class="modal-footer" style="justify-content: center !important">
							<button type="submit" class="btn btn-primary" @click="showModal = false">
								OK - lad mig komme i gang
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style scoped>
.arr {
	margin-bottom: 0px;
	float: left;
	margin-right: 5px;
	color: #1692B6;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.topMost {
	z-index: 200;
	;
}

div.invalid {
	border: 1px solid #f1c501;
	font-size: 80%;
	background-color: #ffe77a;
	padding: 5px
}

div.logininfo {
	color: #999;
	font-size: 80%;
	padding-bottom: 10px
}

div.forgot {
	color: #999;
	font-size: 80%;
	padding-bottom: 10px;
	margin: 10px 0px 0px 0px
}

div.forgot a {
	font-size: 14px;
}
</style>
<script>
import axios from 'axios';
import { mapGetters, mapState } from "vuex";

export default {
	components: {},
	data() {
		return {
			neverShowAgain: false,
			showModal: false,
		}
	},
	computed: {
		...mapGetters(["isLoggedIn", "tipVersion"]),
		...mapState(["user", "practitionerId"]),
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
		'$route': {
			handler: function () {
				this.fetchItems();
			},
			deep: true,
			immediate: true
		},
	},
	methods: {
		closeWindowGotoSignUp() {
			this.closeWindow();
		},
		closeWindow() {
			this.showModal = false;
		},
		show() {
			this.showModal = true;
		},
		setTipVersionRead() {
			//debugger; // eslint-disable-line no-debugger
			let val = !this.neverShowAgain ? this.tipVersion : 0;
			axios.get(`/practitioner/entity/${this.practitionerId}/settipversionread?tipversion=${val}`)
				.then(() => {
					//this.showModal = false;
					console.log("tipversion saved");
				})
				.catch(error => {
					console.log(error);
				});
		},
		fetchItems() {
			if (this.user == null || this.practitionerId == null) return;
			if (this.user.practitionerId != this.practitionerId) return;
			axios
				.get(`/practitioner/entity/${this.practitionerId}/tipversion`)
				.then((response) => {
					let practitionerTipVersion = response.data;
					this.showModal = practitionerTipVersion < this.tipVersion;
				})
				.catch((error) => console.log(error));
		},
	},
}

</script>