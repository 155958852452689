<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin " ref="modal" tabindex="1" @keydown.esc="closeWindow()">
			<b-modal id="modal-center" v-model="showDeleteConfirmPopup" dialog-class="confirmmodal" centered
				title="Slet booking?" data-backdrop="false" ok-title="OK" cancel-title="Fortryd" @ok="deleteBookingDo">
				<p>Er du sikker på at du vil slette denne booking?<br />
					<b-form-checkbox id="notifyOnDelete" v-model="notifyOnDelete" value="true" unchecked-value="false">
						&nbsp;Adviser kunde via mail om dette.
					</b-form-checkbox>
				</p>
			</b-modal>
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">{{pageMode == pageModes.create ? 'Opret' : 'Redigér'}} booking
								<span v-if="pageMode == pageModes.edit">(booking id: {{ bookingOrder.bookingDto.id
								}})</span>
							</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<form class="needs-validation" novalidate ref="form" v-on:submit.prevent>
								<div class="section">
									<div class="header">
										<b-icon icon="calendar2-event" class="icon" />Aftale
									</div>
									<b-container>
										<b-form-row>
											<b-col class="">
												<b-form-group>
													<b-form-radio-group id="appointmentType" v-model="appointmentType">
														<b-form-radio value="treatment" class="inline">&nbsp;Behandling
														</b-form-radio>
														<b-form-radio value="other" class="inline">&nbsp;Andet
														</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</b-col>
										</b-form-row>

										<b-form-row v-if="appointmentType == 'other'">
											<b-col>
												<form-group id="typeOther" label-for="aftale"
													:validator="$v.bookingOrder.bookingDto.title">
													<b-form-input type="text" placeholder="<indtast aftale>" id="aftale"
														class="form-control" style="width:100% !important"
														@blur="$v.bookingOrder.bookingDto.title.$touch()"
														v-model="bookingOrder.bookingDto.title" />
												</form-group>
											</b-col>
										</b-form-row>

										<b-form-row v-if="appointmentType == 'treatment'">
											<b-col cols="12">
												<b-form-group id="pt-group" label-for="pt"
													:validator="$v.bookingOrder.bookingDto.practitionerInTreamentId">
													<b-form-select class="form-select form-select-sm" id="pt"
														:options="practitionerTreatments" value-field="id"
														v-model="bookingOrder.bookingDto.practitionerInTreamentId"
														@change="$v.bookingOrder.bookingDto.practitionerInTreamentId.$touch()"
														text-field="treatmentName">
													</b-form-select>
												</b-form-group>
												<div class="help-block"
													v-if="$v.bookingOrder.bookingDto.practitionerInTreamentId.$invalid">
													<span class="error">
														Skal vælges
													</span>
												</div>
											</b-col>
										</b-form-row>

										<b-row class="pt-2">
											<b-col cols="6" style="max-width:255px">
												<b-form-group id="startDateGroup" label="Start"
													:validator="$v.startDate" label-for="startDate">
													<input type="date" id="startDate" class="form-control"
														v-model="startDate" @blur="$v.startDate.$touch()" required
														style="margin-right:5px;" />
												</b-form-group>
											</b-col>
											<b-col cols="2" style="min-width:120px">
												<b-form-group id="startTimeGroup" label="Tid" :validator="$v.startTime"
													label-for="startTime">
													<input type="time" id="startTime" class="form-control"
														v-model="startTime" @blur="$v.startTime.$touch()" required />
												</b-form-group>
											</b-col>
											<b-col cols="2" style="min-width:120px">
												<b-form-group id="endTimeGroup" label="Slut" :validator="$v.endTime"
													label-for="endTime">
													<input type="time" id="endTime" class="form-control"
														v-bind:class="{ error : dateTimeError }" v-model="endTime"
														@input="$v.endTime.$touch()" style="width:100px" />
												</b-form-group>
											</b-col>
											<b-col cols="2"
												style="min-width:180px; display: flex; align-items: center;"><br />
												<span style="color:#888;font-size:14px"><br />
													({{ duration }})</span>
											</b-col>
										</b-row>

										<b-form-row class="pt-2">
											<b-col cols="12">
												<form-group id="input-group-2" label-for="teaser">
													<b-form-textarea id="notes" v-model="bookingOrder.bookingDto.notes"
														placeholder="<indtast evt. interne noter>" rows="3"
														@input="$v.bookingOrder.bookingDto.notes.$touch()" max-rows="5">
													</b-form-textarea>
												</form-group>
											</b-col>
										</b-form-row>
									</b-container>
								</div>

								<div class="section">
									<div class="header">
										<b-icon icon="person-circle" class="icon" />Kunde
									</div>
									<b-container>
										<form-group id="fullnameGroup1" label-for="fullname"
											:validator="$v.bookingOrder.fullname">
											<b-form-input type="text" placeholder="<Navn>" id="fullname"
												class="form-control" @input="$v.bookingOrder.fullname.$touch()"
												v-model="bookingOrder.fullname" />
										</form-group>

										<form-group class="pt-2" label-for="email" :validator="$v.bookingOrder.email">
											<input type="text" placeholder="<Email>" id="email" class="form-control"
												@input="$v.bookingOrder.email.$touch()" v-model="bookingOrder.email" />
										</form-group>
										<form-group class="pt-2">
											<input type="text" placeholder="<Telefon>" class="form-control"
												@input="$v.bookingOrder.phone.$touch()" v-model="bookingOrder.phone" />
										</form-group>
										<form-group class="pt-2">
											<b-form-checkbox id="sendEmail" v-model="sendEmailNotification"
												:value="true" :unchecked-value="false">
												&nbsp;Send mail med {{ pageMode == pageModes.edit ? 'ændringer' :
												'oprettelse' }} til kunde
											</b-form-checkbox>
										</form-group>
									</b-container>
								</div>

							</form>
						</div>
						<div class="modal-footer" style="display:block">
							<div style="float:left" v-if="pageMode == pageModes.edit">
								<button type="button" class="btn btn-delete" @click="deleteBooking()">Slet</button>
							</div>
							<div style="float:right">
								<button type="button" class="btn btn-primary btn-block" @click="saveBooking()"
									:disabled="saveEnabled">Gem</button>
								<button type="button" class="btn btn-secondary" style="margin-left:10px"
									@click="showModal = false">Fortryd</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style lang="scss" scoped>
.btn-delete {
	background-color: #fff;
	border-color: #888;

	&:hover {
		background-color: #ff0000 !important;
		color: white;
	}
}

h5 {
	span {
		font-size: 16px
	}
}

.section {
	margin-top: 20px;
	padding: 0px;
	background-color: white;
	border-radius: 10px;

	.header {
		margin: 0px;
		padding: 10px 20px 8px 20px;
		font-weight: 600;
		font-size: 16px;
		border:5px solid #fff;
		border-radius: 12px 12px 0px 0px;
		background-color: #f1f1f1;
		color:#888;
		text-transform: uppercase;
		letter-spacing: 2px;

		.icon {
			margin-right: 8px
		}
	}

	.container {
		padding: 10px 20px 20px 20px;

		label,
		input,
		select {
			font-family: Roboto !important;
		}

		input,
		textarea,
		select {
			background-color: #f1f1f1 !important;
		}

		input.error {
			border-color: #ff0000;
		}

		.inline {
			display: inline-block;
			margin-right: 10px;
		}
	}
}
</style>
<style scoped>
.modal-dialog,
.modal-content {
	min-height: 400px;
	/*height: 99vh;
	max-height: 89vh;*/
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	/*height: 70vh;*/
	min-height: 40vh;
	max-height: 70vh;
	overflow-y: auto;
	background-color: #e7e7e5;
	padding-top: 0px;
}

.modal-mask {
	position: fixed;
	/* z-index: 100; */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}


@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>
<script>
import misc from '@/misc.js'
import axios from 'axios';
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';

export default {
	components: {},
	computed: {
		...mapGetters(["practitioner", "practitionerId"]),
		saveEnabled() {
			return !this.$v.$anyDirty;
		},
		dateTimeError() {
			return this.hasDateErrors;
		},
		duration() {
			let s = misc.parseDateTime(this.startDate + " " + this.startTime);
			let e = misc.parseDateTime(this.startDate + " " + this.endTime);
			return misc.getMinutes(s, e)
		},
	},
	watch: {
		startTime() {
			this.checkDates();
		},
		endTime() {
			this.checkDates();
		},

	},
	data() {
		return {
			showDeleteConfirmPopup: false,
			notifyOnDelete: true,
			hasDateErrors: false,
			startDate: null,
			startTime: null,
			endTime: '',
			practitionerTreatments: [],
			appointmentType: "treatment",
			pageModes: { create: 0, edit: 1 },
			pageMode: 0,
			showModal: false,
			sendEmailNotification: true,
			bookingOrder: {
				fullname: "",
				email: "",
				phone: "",

				bookingDto: {
					id: -1,
					practitionerInTreamentId: -1,
					title: "",
					startDate: "2022-10-06",
					startTime: null,
					endTime: null,
					notes: null,
				}
			}
		}
	},
	validations: {
		endTime: { required },
		startDate: { required },
		startTime: { required },
		appointmentType: { required },
		bookingOrder: {
			fullname: { required },
			email: {},
			phone: {},
			bookingDto: {
				practitionerInTreamentId: {
					required: requiredIf(function () {
						return this.appointmentType == "treatment";
					}),
				},
				title: {
					required: requiredIf(function () {
						return this.appointmentType != "treatment";
					}),
				},
				notes: {},
			},
		},
	},
	methods: {
		deleteBooking() {
			this.showDeleteConfirmPopup = true;
		},
		deleteBookingDo() {
			axios.delete(`/booking/${this.practitionerId}/delete/${this.bookingOrder.bookingDto.id}/${this.notifyOnDelete}`)
				.then(() => {
					this.$emit("refresh");
					this.showModal = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		saveBooking() {
			this.$v.$touch();
			if (this.$v.$invalid || this.hasDateErrors) {
				return;
			}
			this.bookingOrder.bookingDto.startDateTime = this.startDate + " " + this.startTime.substr(0, 5);
			this.bookingOrder.bookingDto.endDateTime = this.startDate + " " + this.endTime.substr(0, 5);
			this.bookingOrder.sendEmailNotification = this.sendEmailNotification;
			this.bookingOrder.OnCreateNewSkipVerifyBooking = true;
			if (this.appointmentType == "other")
				this.bookingOrder.bookingDto.practitionerInTreamentId = null;

			axios.post(`/booking/${this.practitionerId}/createorupdate`, this.bookingOrder)
				.then(() => {
					this.$emit("refresh");
					this.showModal = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		async fetchTreatments() {
			if (this.practitionerTreatments != null && this.practitionerTreatments.length > 0)
				return;
			await axios
				.get(`/practitioner/treatments/${this.practitionerId}`)
				.then(response => {
					this.practitionerTreatments = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
		checkDates() {
			let s = misc.parseDateTime(this.startDate + " " + this.startTime);
			let e = misc.parseDateTime(this.startDate + " " + this.endTime);
			let diff = s - e;
			this.hasDateErrors = diff > 0;
		},
		async fetchBooking(bookingId) {
			await axios
				.get(`/booking/${this.practitionerId}/${bookingId}`)
				.then(response => {
					this.bookingOrder = response.data;
					this.appointmentType = this.bookingOrder.bookingDto.title ? "other" : "treatment";
					if (this.appointmentType == "other")
						this.bookingOrder.bookingDto.practitionerInTreamentId = -1;
					this.bookingOrder.bookingDto.startDate = misc.getDateStringFromString(this.bookingOrder.bookingDto.startDateTime);	//"2022-10-14 14:30"
					this.bookingOrder.bookingDto.startTime = misc.getTimeStringFromString(this.bookingOrder.bookingDto.startDateTime);
					this.bookingOrder.bookingDto.endTime = misc.getTimeStringFromString(this.bookingOrder.bookingDto.endDateTime);
					this.startDate = this.bookingOrder.bookingDto.startDate;
					this.startTime = this.bookingOrder.bookingDto.startTime;
					this.endTime = this.bookingOrder.bookingDto.endTime;
					this.notifyOnDelete = true;
					this.sendEmailNotification = true;
				})
				.catch(error =>
					console.log(error)
				);
		},
		editBooking(bookingId) {
			this.pageMode = this.pageModes.edit;
			this.fetchBooking(bookingId);
			this.fetchTreatments();
			this.$nextTick(() => { this.$refs.modal.focus(); })
			setTimeout(() => { this.$v.$reset() }, 10)
		},
		addBooking(startDate, endDate) {
			this.fetchTreatments();
			this.appointmentType = "treatment";
			this.pageMode = this.pageModes.create;
			this.notifyOnDelete = true;
			this.bookingOrder.fullname = "";
			this.bookingOrder.email = "";
			this.bookingOrder.phone = "";
			this.bookingOrder.bookingDto.id = -1;
			this.bookingOrder.bookingDto.practitionerInTreamentId = "";
			this.bookingOrder.bookingDto.title = "";
			this.bookingOrder.bookingDto.notes = "";
			this.startDate = misc.getDateStringFromString(startDate.toString());	//"2022-10-14T14:30:00";
			this.startTime = misc.getTimeStringFromString(startDate.toString());
			this.endTime = misc.getTimeStringFromString(endDate.toString());
			//console.log(this);
			this.showModal = true;
			setTimeout(() => { this.$v.$reset() }, 10)
			this.$nextTick(() => { this.$v.$reset() })
		},
		showBooking(bookingId) {
			this.showModal = true;
			this.editBooking(bookingId);
			this.$nextTick(() => { this.$v.$reset() })
			setTimeout(() => { this.$v.$reset() }, 10)
		},
		closeWindow() {
			this.showModal = false;
		}
	},
	mounted() {
		document.addEventListener("keydown", (e) => {
			if (e.key === 'Escape') {
				this.closeWindow();
				// Or any other way you want to close your modal
			}
		})
	}
}
</script>