<script>
import axios from 'axios'
import EditIcon from './EditIcon.vue';
import SomeEdit from './SomeEdit2.vue'
import { mapGetters } from 'vuex'

export default {
	components: { SomeEdit, EditIcon },
	data() {
		return {
			soMeData: {},
			showModal: false,
		}
	},
	computed: {
		...mapGetters(['isPractitionerPageOwner']),
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		getFbUrl() {
			if (this.soMeData == null) return;
			return "https://www.facebook.com/" + this.soMeData.facebookName;
		},
		getInstagramUrl() {
			if (this.soMeData == null) return;
			return "https://www.instagram.com/" + this.soMeData.instagramName;
		},
		hasSoMeUrls() {
			if (this.soMeData.facebookName == null) return false;
			return this.soMeData.instagramName || this.soMeData.facebookName;
		},
		openEditSome() {
			this.$refs.someEdit.show();
		},
		fetchItems() {
			if (this.practitionerId <= 0)
				return -1;
			axios
				.get(
					
					`/practitioner/some/${this.practitionerId}`
				)
				.then((response) => {
					this.soMeData = response.data;
					this.$forceUpdate();
				})
				.catch((error) => console.log(error));
		},
		show() {
			this.showModal = true;
		},
	},
	mounted() {
		setTimeout("window.FB.XFBML.parse()",500);
	},
	created() {
		this.fetchItems();
	}
}
</script>
<template>
	<div v-if="hasSoMeUrls() || isPractitionerPageOwner">
		<div class="facebook drop_shadow">
			<some-edit ref="someEdit" @refresh="fetchItems" />
			<h4 style="padding-bottom:5px">
				<edit-icon title="Rediger facebook og instagram" className="pencilbg" style="float:right;font-size:20px;margin-top:5px" @click="openEditSome" />
				<!-- <b-icon class="editicon" title="Rediger" icon="pencil-square" @click="openEditSome"
					style="float:right;font-size:20px;margin-top:5px" /> -->
				<span>Følg </span>mig
			</h4>
			<div style="width:100%;text-align:center;" v-if="this.soMeData && this.soMeData.facebookName != ''">
				<!-- <div class="fb-page" data-href="https://www.facebook.com/drfrejaeriksen" data-tabs="" data-width="600" data-height="70" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/drfrejaeriksen" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/drfrejaeriksen"></a></blockquote></div> -->

				<div id="__fbPageButton" class="fb-page" v-bind:data-href="getFbUrl()" data-tabs="" data-width="390" data-height="70" 
				data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" 
				data-show-facepile="true"><blockquote v-bind:cite="getFbUrl()" class="fb-xfbml-parse-ignore"><a 
					v-bind:href="getFbUrl()"></a></blockquote></div>
				<!-- <button onclick="FB.XFBML.parse();">Htest</button> -->
			</div>
		</div>
		<div class="instagram drop_shadow" style="margin-top:20px"
			v-if="this.soMeData && this.soMeData.instagramName != ''">
			<div class="row">
				<b-link target="_blank" v-bind:href="getInstagramUrl()"><img class="img-fluid"
						src="/behandlerportal/images/follow-on-instagram2.png" style="width:90%;padding:10px;" />
				</b-link>
			</div>
		</div>
	</div>
</template>
<style scoped>
button {
	padding: 6px !important;
	font-size: 14px !important;
	font-family: Raleway;
	font-weight: 400;
	color: white;
}

.modal-dialog,
.modal-content {
	min-height: 400px;
	/* height: calc(100% - 20px);*/
}

.modal-body {
	overflow-y: scroll;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}


@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>
