<template>
	<section class="review_slider">
		<div class="container">
			<h4 class="title"><span>Andre brugeres</span> anmeldelser af {{ practitionerName }}</h4>
			<div v-if="ratings.length == 0" class="noreviews">Der er ingen anmeldelser for denne behandler
				endnu.</div>
			<div v-else>
				<carousel paginationColor="blue" paginationActiveColor="#aaa" :perPageCustom="[[500, 1], [1000, 2], [1424, 3]]" :autoplay="true" :paginationSize="3">
					<slide v-for="rating in ratings" :key="rating.date" class="slide-content">
						<div class="drop_shadow">
							<star-rating v-model="rating.rating" :star-size="20" :increment="0.5" :read-only="true"
								:rounded-corners="false" :show-rating="false" class="rating" text-class=""
								style="margin-top:10px"></star-rating>
							{{ formatDate(rating.date) }}
							<p class="comment">“{{ rating.comment }}”</p>
						</div>
					</slide>
				</carousel>
			</div>
		</div>
	</section>
</template>
<style lang="scss" scoped>
div.rating {
	display: inline-block;
}

.noreviews {
	text-align: center;
	padding: 60px;
}

.review_slider {
	background: #f5f9fc;
	padding-top: 12px;
	padding: 40px 0px;


	.container {
		h4 span {
			font-family: Raleway;
			font-weight: 200;
			float: none;
			display: contents;
			width: auto;
		}


		.VueCarousel {
			.slide-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				padding-left: 10px;
				padding-right: 10px;


				.drop_shadow {
					min-height: 200px;
					background-color: white;
					border: 1px solid rgb(239, 239, 239);
					filter: drop-shadow(0px 0px 20px #00000020);
					margin: 30px;
					padding: 20px;
					text-align: left;
				}
			}

			.VueCarousel-pagination {
				border: 2px solid red !important;
				margin-top: 300px !important;

				.VueCarousel-dot-container {
					.VueCarousel-dot {
						margin: 10px 6px;
						width: 10px;
						height: 10px;
						padding: 0 !important;
						background-color: white !important;
						border: 3px solid #D8D8D8 !important;

						&.VueCarousel-dot--active {
							background-color: #0094B3 !important;
						}
					}
				}
			}
		}
	}
}
</style>
<script>
import axios from 'axios'
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters, mapState } from "vuex";
import StarRating from 'vue-star-rating'

export default {
	components: {
		StarRating,
		Carousel,
		Slide
	},
	computed: {
		...mapState(['practitionerId']),
		...mapGetters(["practitionerName"]),
	},
	data() {
		return {
			ratings: [],
		}
	},
	methods: {
		formatDate(d) {
			var date = new Date(d)
			var year = date.getFullYear();
			var month = (1 + date.getMonth()).toString();
			month = month.length > 1 ? month : '0' + month;
			var day = date.getDate().toString();
			day = day.length > 1 ? day : '0' + day;
			return day + '/' + month + '-' + year;
		},
		fetchItems() {
			axios
				.get(`/voting/${this.practitionerId}/allratings`)
				.then(response => {
					this.ratings = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>