<template>
	<div>
		<div class="header">Vælg en ydelse</div>
		<div class="treatments">
			<div v-for="pt in practitionerTreatments" :key="pt.id" class="treatment">
				<div class="flexor">
					<div class="name">
						{{ pt.treatmentName }}
					</div>
					<b-button @click="selectTreatment(pt)" class="btn right" size="sm">Vælg&nbsp;<b-icon icon="arrow-right-circle-fill" />
					</b-button>
				</div>
				<div class="duration">{{ misc.getDurationForDisplay(pt.duration) }}, <span>{{ pt.price }},-</span></div>
				<div class="description">{{ pt.description }}
				</div>
			</div>
			<div v-if="practitionerTreatments.length == 0">
				Der er ingen behandlinger der kan bookes.
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.header {
	color: #888;
	margin-bottom: 10px;
}

.treatments {
	max-height: 500px;
	overflow-y: auto;
	background-color: white;
	border-radius: 4px;
	padding: 20px;

	.treatment {
		font-weight: 800;
		margin-top: 0px;
		border-bottom: 0px solid #1692B666;
		padding: 10px 0px 10px 0px;

		.flexor {
			display: flex;
			gap: 10px;
			justify-content: space-between;

			@media (max-width: 750px) {
				flex-wrap: wrap;
			}
			.right {
				padding: 10px;
				background-color: #1692B6;
				font-size:90% !important;
				max-height: 38px;
				white-space:nowrap;
			}
		}


		.name {
			font-family: 'Raleway-Bold' !important;
			font-size: 20px;
			font-weight: 600;
			color: #000;
			background-color: #eee;
			padding: 6px 10px;
			border: 0px solid #eee;
			border-radius: 10px;
			display: inline-block;
		}

		.duration {
			font-family: 'Raleway' !important;
			font-size: 18px;

			span {
				font-size: 140%;
				font-weight: 800;
			}
		}

		.description {
			color: #888;
			font-size: 16px;
			font-family: 'Raleway-Regular' !important;
		}
	}
}
</style>
<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import misc from '@/misc.js'

export default {
	components: {},
	computed: {
		...mapState(["practitionerId"]),
		...mapGetters(["practitioner"]),
	},
	watch: {
		practitionerId() {
			this.fetchData();
		}
	},
	data() {
		return {
			misc: misc,
			practitionerTreatments: [],
		}
	},
	methods: {
		selectTreatment(treatment) {
			this.$emit("treatmentSelected", treatment);
		},
		show() {
			this.fetchData();
		},
		fetchData() {
			if (this.practitionerId < 0) return;
			axios
				.get(`/practitioner/treatments/${this.practitionerId}?onlyBookable=true`)
				.then(response => {
					this.practitionerTreatments = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
}
</script>