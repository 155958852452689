<template>
    <div class="searchBar">
        <h1>Hvad vil du gerne have hjælp til?</h1>
        <div class="search">
            <input placeholder="Søg på symptom eller lidelse" v-model="searchValue" type="search" v-on:keyup.enter="doSearch" name="" id="">
            <button class="d-flex align-items-center justify-content-center" @click="doSearch"><i class="bi bi-search"></i></button>
        </div>
        <p>Er du behandler og ønsker at oprette en profil - <router-link class="link" v-bind:to="{ name: 'CreatePractitionerWelcomePage' }">Klik her</router-link></p>
    </div>
</template> 
<style lang="scss">
.searchBar {
    width: 80%;
    margin: 22vh auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(max-width:768px) {
        margin: 3vh auto;
    }

	a, a:visited {color:#ddd}
	a:hover {color:#fff}

    h1 {
        font-size: 42px;
        font-family: "Raleway-Medium";
        color: #fff;
        text-align: center;

        @media(max-width:768px) {
            font-size: 21px;
        }
    }

    .search {
        position: relative;

        input {
            min-width: 500px;
            margin: 20px 0;
            height: 60px;
            border-radius: 44px;
            border: 0;
            outline: 0;
            padding: 0 25px;
            font-size: 16px;
            font-family: 'Raleway-SemiBold';
            color: #595858;

            @media(max-width:768px) {
                min-width: 81vw;
            }
        }

        button {
            height: 56px;
            width: 56px;
            border-radius: 50%;
            border: 0;
            position: absolute;
            right: 2px;
            top: 22px;
            background: #0096b0;
            color: #fff;
            font-size: 1.4rem;
            position: absolute;
            transition: all 0.3s ease;

            &:hover {
                background: darken($color: #0096b0, $amount: 3%);
            }
        }
    }

    p {
        font-size: 16px;
        font-family: 'Raleway-Regular';
        color: #fff;
        text-align: center;
    }
}
</style>
<script>
export default {
	components: { },
	props: {
		q: { type: String }
	},
	methods: {
		doSearch() {
			this.$router.push(`/search?q=${this.searchValue}`);
			//this.$store.commit('doSearch', this.searchValue);
		}
	},
	data() {
		return {
			searchValue: "",
		};
	},	
}
</script>