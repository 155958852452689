<template>
    <div v-if="isPractitionerPageOwner">
        {{ !hasItems ? noentries : "" }}
        <b-icon v-if="isPractitionerPageOwner" :class="className" :title="title" icon="pencil-square"
            @click="$emit('click')" />
    </div>
</template> 
<style scoped>
.editicon {
    font-size: 18px;
    cursor: pointer !important;
    float: right;
}

.pencil {
    display: inline !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: 50%;
    top: 0%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 99;
    background-color: white;
    color: black;
    border-radius: 4px;
}
</style>
<script>
import { mapGetters } from 'vuex'

export default ({
    props: {
        noentries: {
            type: String,
            default: null
        },
        //if false then show noentries string
        hasItems: {
            type: Boolean,
            default : false,
        },
        className: {
            type: String,
            default: 'editicon',
        },
        title: {
            type: String,
            default: "Redigér"
        }
    },
    computed: {
        ...mapGetters(['isPractitioner', 'isPractitionerPageOwner']),
    },
    created() {
    }
})
</script>