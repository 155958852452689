<template>
	<div v-show="isPractitionerPageOwner || events.length > 0">
		<h5>
			<edit-icon @click="openEditItems" /><span>Aktuelt</span>
		</h5>
		<div v-if="events.length == 0">Ingen kommende begivenheder</div>
		<div v-for="event in events" :key="event.id" class="img_text">
			<div class="img drop_shadow thumbcontainer">
				<router-link v-bind:to="{ path: `/behandler/${event.practitionerId}/event/${event.id}` }">
					<img class="objectfit padtop" v-if="event.filename != null" v-bind:src="getImageUrl(event)"
					alt="blog" />
				</router-link>
			</div>
			<div class="text">
				<h6>
					<router-link v-bind:to="{ path: `/behandler/${event.practitionerId}/event/${event.id}` }">{{ event.headline }}</router-link>
				</h6>
				<p>{{ event.teaser }}</p>
				<p class="date" v-if="event.startDateTime != '0001-01-01 00:00'">
					<b-icon style="font-size:17px;" icon="calendar4-week" />
					{{ formattedDateTime(event) }}
				</p>
			</div>
		</div>
		<events-edit ref="eventsEdit" title="Aktuelt (hvad vil du fremhæve)" @refresh="fetchItemsAfterUpdate" />
	</div>
</template>
<style scoped>
.objectfit {
	object-fit: cover;
	overflow: hidden;
}

@media (min-width: 1199px) {
	.thumbcontainer {
		width: 100%;
		height: 300px;
	}

	.thumbcontainer .objectfit {
		width: 100%;
		height: 100%;
	}
}
</style>
<script>
/* eslint-disable */
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import EventsEdit from './EventsEdit.vue';
import EditIcon from "./EditIcon.vue";

export default {
	components: { EventsEdit, EditIcon },
	props: ['itemType'],
	data() {
		return {
			events: [],
			rndString : "",
		};
	},
	computed: {
		...mapState(["practitionerId"]),
        ...mapGetters(['isPractitioner', 'isPractitionerPageOwner']),
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		getImageUrl(event) {
			let appendRndString = this.rndString != "" ? "?r=" + this.rndString : "";
			return `/behandlerportal/images/bh/cie_${event.id}.jpg${appendRndString}`;
		},
		openEditItems() {
			this.$refs.eventsEdit.show();
		},
		parseDate(dateStr) {
			//debugger; // eslint-disable-line no-debugger
			if (!dateStr)
				return "";
			//comes with format: 2022-09-15 10:00
			let hour = 0, minute = 0;
			let year = parseInt(dateStr.substr(0, 4));
			let month = parseInt(dateStr.substr(5, 2));
			let day = parseInt(dateStr.substr(8, 2));
			if (dateStr.indexOf(":") > 0) {
				hour = parseInt(dateStr.substr(11, 2));
				minute = parseInt(dateStr.substr(14, 2));
			}
			return new Date(year, month - 1, day, hour, minute);
		},
		formattedDateTime(event) {
			let d = this.parseDate(event.startDateTime);
			let dateString = d.getDate() + "/" + (d.getMonth() + 1) + "-" + d.getFullYear() + " ";
			if (d.getHours() != 0 || d.getMinutes() != 0)
				dateString += d.getHours().pad(2) + ":" + d.getMinutes().pad(2);
			return dateString;
		},
		fetchItemsAfterUpdate() {
			this.rndString = Date.now();
			this.fetchItems();
		},
		fetchItems() {
			axios
				.get(
					
					`/practitioner/events/${this.practitionerId}?itemCount=3`
				)
				.then((response) => {
					this.events = response.data;
				})
				.catch((error) => console.log(error));
		},
	},
	created() {
		this.fetchItems();
	}
};
Number.prototype.pad = function (size) {
	var s = String(this);
	while (s.length < (size || 2)) { s = "0" + s; }
	return s;
}
</script>
