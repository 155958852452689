<template>
		<div class="keywords">
			<edit-icon @click="openEditKeywords" :has-items="filteredKeywords.length > 0" noentries="Klik blyant-ikon for at vælge ekspertiser" />
			<keywords-edit ref="keywordsEdit" @refresh="fetchItems" />
			<div class="pill" v-for="keyword in filteredKeywords" :key="keyword.id" nowrap>
				<img :src="keyword.iconSmall" class="small" /><span> {{ keyword.contents }}</span>
			</div>
		</div>
</template>
<style lang="scss" scoped>
.keywords {margin-top:20px}
div.pill {
	// background-color: #669cff15 !important;
	display:inline-block;
	padding: 6px !important;
	margin-right: 10px;
	margin-bottom: 0px;
	font-family: Raleway;
	font-weight: 400;
	border-radius: 12px;
	border: 0px solid #aec6f365 !important;
	font-size: 0.9rem;
	color: #888;
}

img.small { width: 32px; height: 32px }

@media only screen and (max-width:991px) {
	.keywords {
		// text-align: center;
	}
}
</style>
<script>
import axios from 'axios';
import { mapState } from 'vuex';
import KeywordsEdit from './KeywordsEdit.vue';
import EditIcon from './EditIcon.vue';

export default {
	components: { KeywordsEdit, EditIcon },
	data() {
		return {
			keywords : [],
			practitionerKeywordIds : [],
			filteredKeywords : [],
		}
	},
	computed: mapState(['practitionerId']),
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		openEditKeywords() {
			this.$refs.keywordsEdit.show(); 
		},
		fetchMainKeywords() {
			return axios.get(`/keywords/main`);
		},
		fetchPractitionerKeywordIds() {
			return axios.get(`/${this.practitionerId}/keywordIds`);
		},
		fetchItems() {
			if (this.practitionerId < 0) return -1;
			axios
				.all([this.fetchMainKeywords(), this.fetchPractitionerKeywordIds()])
				.then(axios.spread((keywordsResponse, practitionerKeywordIdsResponse) => {
					this.keywords = keywordsResponse.data;
					this.practitionerKeywordIds = practitionerKeywordIdsResponse.data;
					//assign them where chosen
					this.filteredKeywords = [];
					for (let n = 0; n < this.practitionerKeywordIds.length; n++) {
						this.filteredKeywords.push(this.keywords.find(keyword => keyword.id == this.practitionerKeywordIds[n]));
					}
				}));
		}
	},
	created(){
		this.fetchItems();
	}
}
</script>