<template>
	<div>
		<b-card title="">
			<b-form-checkbox id="showOpeningHours" v-model="practitioner.showOpeningHours" style=";">
				&nbsp;Vis åbningstider på profilside
			</b-form-checkbox>
			<div class="dim">Åbningstiderne kan godt være skjulte på profilsiden - selvom du har indtastet tider</div>
		</b-card>
		<div class="text-muted sectioninfo">Her kan du oplyse dine åbningstider. Hvis du ikke har normale åbningstider,
			så udelad denne del og beskriv dette i sektionen 'Om dig'. <br />
			<b>Bemærk: Det er ikke muligt at booke dig hvis du ingen åbningstider har.</b><br/><br/>
			<b>Sådan gør du:</b> Indtast start og slut klokkeslet udfor en dag for at 'aktivere' dagen. Slet indholdet for at angive lukket.
		</div>
		<br />
		<b-table-simple class="tight" small responsive>
			<b-thead>
				<b-th>Dag</b-th>
				<b-th>Åbningstid</b-th>
				<b-th>Lukketid</b-th>
			</b-thead>
			<b-tbody>
				<b-tr v-for="(openDay) in this.openDays" :key="openDay.day"
					v-bind:class="{ 'dimmed': openDay.opens == null }">
					<!--check-circle-fill-->
					<b-td style="width:60%">
						<b-icon class="check" :class="openDay.opens ? 'yes' : ''"
							:icon="openDay.opens ? 'check-circle-fill' : 'dash-circle-fill'" /> {{ days[openDay.day].day
							}}
					</b-td>
					<b-td style="width:20%">
						<input type="text" class="form-control form-control-sm time" maxlength="5" @blur="blur(openDay)"
							placeholder="08:00" v-model="openDay.opens" required />
					</b-td>
					<b-td style="width:20%">
						<input type="text" class="form-control form-control-sm time" maxlength="5" @blur="blur(openDay)"
							placeholder="16:00" v-model="openDay.closes" required />
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
	</div>
</template>
<style scoped>
div.sectioninfo {
	background: #ffe;
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px;
	font-size: 0.8rem;
	line-height: 1.2rem;
	margin-top: 10px;
	margin-bottom: 0px;
	color: #444 !important
}

.check {
	color: rgb(208, 208, 208)
}

.yes {
	color: #04a20a
}

.card-body {
	padding: 8px 12px
}

.dim {
	color: #a4a4a4;
	padding: 0px 0px 5px;
	font-size: 80%;
	line-height: 120%;
}

div.tight table td {
	font-size: 80%
}

div.tight table tbody {
	border-top: 1px solid #eee
}

div.tight table th {
	color: #aaa;
	font-weight: normal;
	font-size: 80%;
}

/*tr.dimmed td {
	color: rgb(238, 198, 170)
}*/

input.time {
	width: 60px
}

input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ddd;
	opacity: 1;
	/* Firefox */
}

a.verysmalltight {
	font-size: 12px;
	text-decoration: underline;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex'

function checkTime(s) {
	const regex = new RegExp('[0-9][0-9]:[0-9][0-9]');
	return regex.test(s);
}

export default {
	computed: {
		...mapGetters(["practitionerId", "practitioner"]),
	},
	data() {
		return {
			isActive: true,
			abc: 'check-circle-fill',
			changesMade: false,
			saveOk: false,
			openDays: [],
			days: [
				{ day: 'Søndag', index: 0 },
				{ day: 'Mandag', index: 1 },
				{ day: 'Tirsdag', index: 2 },
				{ day: 'Onsdag', index: 3 },
				{ day: 'Torsdag', index: 4 },
				{ day: 'Fredag', index: 5 },
				{ day: 'Lørdag', index: 6 },
			]
		}
	},
	//debugger; // eslint-disable-line no-debugger
	methods: {
		blur(openDay) {
			//debugger; // eslint-disable-line no-debugger
			if (!checkTime(openDay.opens))
				openDay.opens = "";
			if (!checkTime(openDay.closes))
				openDay.closes = "";
		},
		async saveItems() {
			await axios
				.post(`/practitioner/${this.practitionerId}/opendays`, this.openDays)
				.then(() => {
					this.saveOk = true;
					this.$store.commit('openingHoursUpdated', true);
				})
				.catch(error => {
					console.log(error);
					this.saveOk = false;
				})
			return this.saveOk;
		},
		fetchItems() {
			axios
				.get(`/practitioner/${this.practitionerId}/opendays`,)
				.then(response => {
					var loadedOpenDays = response.data;
					for (var n = 0; n < 7; n++) {
						var foundOd = loadedOpenDays.find(({ day }) => day === n);
						if (foundOd)
							this.$set(this.openDays, n, foundOd);
						else {
							let t = {
								id: -1,
								day: n,
								opens: null,
								closes: null
							}
							this.$set(this.openDays, n, t);
						}
					}
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>