<template>
    <div>
        <registration-steps></registration-steps>
    </div>
</template>
<script>
import RegistrationSteps from "./RegistrationSteps.vue";

export default {
    components: {
        RegistrationSteps,
    },
    data() {
        return {};
    },
    created: function () {

    }
}
</script>
<style lang="scss">
$white: #ffffff;

.theme--light.application {
    background-color: $white  !important;
}
</style>

<style lang="scss" scoped>
/*small phone*/
@media (max-width: 374px) {}

/*big phone*/
@media (min-width: 375px) {}

/*small tablet*/
@media (min-width: 768px) {}

/*big tablet*/
@media (min-width: 993px) {}

/*laptop*/
@media (min-width: 1264px) {}

/*pc*/
@media (min-width: 1664px) {}
</style>
