<template>
	<div>
		<div v-if="!isLiveEvent()" class="siteHeader" :class="getHeaderClass()">
			<nav class="navbar navbar-expand-md px-4" style="padding-bottom:10px !important">
				<div class="container-fluid">
					<router-link class="navbar-brand" v-bind:to="{ path: '/' }">
						<div class="logo">
							<img v-if="!isRootPage() && !isSearchPage()" src="../../assets/holistisk_logo_blaa.png"
								class="img-fluid" width="190px" style="margin-left:10px;">
							<img v-else src="../../assets/holistisk_logo_hvid.png" style="margin-left:10px;"
								class="img-fluid" width="190px">
						</div>
					</router-link>
					<div>
						<b-navbar tag="div" toggleable="lg" variant="" no-transition class="navCustom"
							:class="getNavClass()">
							<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
						</b-navbar>
						<b-collapse id="nav-collapse" is-nav :class="getNavClass()">
							<b-navbar-nav class="ml-auto">
								<b-nav-item v-if="isProPage()" class="textlink" @click="$refs.terms.show(1)">
									minHolistiskeBehandler Code of Conduct</b-nav-item>
								<b-nav-item class="textlink" :to="{ name: 'About' }">Om
									minHolistiskeBehandler</b-nav-item>
								<b-nav-item class="textlink" :to="{ name: 'CreatePractitionerWelcomePage' }">Opret
									behandler</b-nav-item>
								<b-nav-item v-if="!isLoggedIn">
									<a class="nav-btn" style="line-height:25px" href="#" @click="$refs.login.show()">Log
										ind</a>
								</b-nav-item>
								<b-nav-item-dropdown v-else id="my-nav-dropdown" :text="user.firstName"
									toggle-class="nav-link-custom">
									<b-dropdown-item v-if="isPractitioner && isPractitionerPageOwner"
										@click="showAdmin">Administration
									</b-dropdown-item>
									<b-dropdown-item v-if="calendarEnabled">
										<router-link
											v-bind:to="{ name: 'Calendar', params: { id: user.practitionerId } }">
											Kalender</router-link>
									</b-dropdown-item>
									<b-dropdown-item v-if="isPractitioner"><a href=""
											@click="gotoPractitionerPage">Behandlerside</a></b-dropdown-item>
									<span v-if="isAdmin">
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item class="admin">
											<router-link v-bind:to="{ name: 'AdminIndex' }" class="">Admin
											</router-link>
										</b-dropdown-item>
									</span>
									<b-dropdown-divider></b-dropdown-divider>
									<b-dropdown-item><a href="#" @click="doLogOut">Log ud</a></b-dropdown-item>
								</b-nav-item-dropdown>
							</b-navbar-nav>
						</b-collapse>
					</div>

				</div>
			</nav>
			<div class="topWarning" v-if="topMessage.visible">{{ topMessage.message }}</div>

			<TermsAndConditions ref="terms"></TermsAndConditions>
			<Config ref="config"></Config>
			<Login ref="login"></Login>
			<!-- search panel -->
			<SearchBar v-show="isRootPage()"></SearchBar>
			<SearchFilter v-show="isRootPage() || isSearchPage()"></SearchFilter>
		</div>
	</div>
</template> 
<style lang="scss">
$primary: #1692b6;
$black: #000;
$white: #fff;

.dropdown-menu {
	left: -60px;
}

.topWarning {
	padding: 8px 20px 8px 20px;
	border: 1px solid #cac702;
	background-color: #fcfb9e;
	text-align: center;
	border-radius: 5px;
	font-size: 16px;
	color: #403f00;
	font-family: Raleway;
	font-weight: 500;
	margin: auto;
	width: 80%;
}

#nav-collapse {
	transition: none;
	border-radius: 4px;

	@media(max-width:778px) {
		position: absolute !important;
		background-color: #ffffffee;
		border: 1px solid #aaa;
		width: 95%;
		padding: 20px;
		z-index: 900;
		margin-left: 20px;
		margin-right: 20px;
		left: 0px;
	}
}

.navCustom {
	color: $white;

	ul {
		li.textlink {
			a {
				margin-top: 20px !important;
			}
		}
	}

	ul {
		a.nav-link-custom {
			margin-top: 20px;
		}

		a.nav-btn {
			margin-top: 9px;
		}
	}

	.nav-item {
		// display: flex;
		flex-direction: row;
		align-items: center;
		color: $white;
		position: relative;
		margin: 0 5px;
		vertical-align: middle;
		line-height: 35px;

		.nav-link {
			color: $white !important;
			padding: 10px 18px !important;
			font-family: 'Raleway-Medium';
			transition: all 0.3s ease;
		}

		@media(min-width:1024px) {
			&:first-child {
				margin-right: 30px;
			}

			&:nth-child(2) {
				margin-right: 30px;
			}
		}
	}
}

.notblack {
	color: white;

	.nav-item {
		color: white;
		line-height: 42px;

		.nav-link {
			color: white !important;
			font-size: 500;
		}
	}

	@media(max-width:778px) {
		.nav-item {
			color: $black;

			.nav-link {
				color: #000 !important;
				font-size: 500;
			}
		}
	}
}

.black {
	color: $black;

	.nav-item {
		color: $black;
		line-height: 42px;

		.nav-link {
			color: #000 !important;
			font-size: 500;
		}
	}

}
</style>
<style lang="scss">
.smallSearch {
	//height: 10vh;
	//height: 386px !important;
	//height: 28vh !important;
	background-image: linear-gradient(7deg, #4a4a4a00, #2746877d), url('../../assets/images/meditate_low.jpg');
	background-size: cover;
	background-repeat: no-repeat;
}

.siteHeader {
	//position: relative;
	max-height: 100vh;
	// height: 10vh;
	//height: 100vh;
	//opacity: 0.9;

	.logo {
		width: 190px;

		@media(max-width:333px) {
			width: 120px;
		}
	}

	@media(max-width:768px) {
		background-position: 75% 0;
	}

	@media(max-width:600px) {
		// min-height: 104vh;
		background-position: 75% 0;
	}

	@media(max-width:333px) {
		// height: 95vh;
	}

	.navCustom {
		@media(max-width:767px) {
			margin-top: 12px;
		}
	}

}

.siteHeaderBig {
	//position: relative;
	// height: 680px;
	background-image: linear-gradient(7deg, #4a4a4a00, #2746877d), url('../../assets/images/meditate_low.jpg');
	background-size: cover;
	background-repeat: no-repeat;

	.logo {
		width: 190px;

		@media(max-width:333px) {
			width: 120px;
		}
	}

	@media(max-width:768px) {
		background-position: 75% 0;
	}

	@media(max-width:600px) {
		min-height: 47vh;
		background-position: 75% 0;
	}

	@media(max-width:333px) {
		height: 47vh;
	}

	.navCustom {
		@media(max-width:767px) {
			margin-top: 12px;
		}
	}

}
</style>

<script>
import SearchBar from "./../Search/SearchBar.vue";
import SearchFilter from "../Search/SearchFilter.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Login from "./../Login.vue";
import axios from "axios";
import Config from "./../Profile/Config.vue";
import TermsAndConditions from "../SignUp/TermsAndConditions.vue";
export default {
	components: {
		Login,
		Config,
		SearchBar,
		SearchFilter,
		TermsAndConditions
	},
	data() {
		return {
			topMessage: { visible: false, message: "Du har ingen åbningstider angivet. Det er ikke muligt for brugere at finde en ledig tid! Opdatér åbningstider under din profil/klinik-adresse" },
			showIfNotLive : false
		}
	},
	computed: {
		...mapGetters(["isLoggedIn", "user", "practitioner", "isAdmin", "isPractitioner", "isPractitionerPageOwner", "calendarEnabled", "userHasCalendar"]),
		...mapState(["user", "practitionerId", "openingHoursUpdated"]),
	},
	methods: {
		...mapActions(["logout"]),
		isLiveEvent() {
			return this.$route.path.indexOf("live") > 0;
		},
		gotoPractitionerPage(e) {
			e.preventDefault;
			return this.$router.push({ path: '/behandler/' + this.user.practitionerId });
		},
		showAdmin() {
			this.$refs.config.show();
		},
		doLogOut() {
			this.logout();
		},
		isProPage() {
			return this.$route.path.indexOf("/pro") > -1 ? true : false;
		},
		isRootPage() {
			return this.$route.path == "/" ? true : false;
		},
		isSearchPage() {
			return this.$route.path.indexOf("search") > -1 || this.$route.path.indexOf("om-") > -1 ? true : false;
		},
		isWelcomePage() {
			return this.$route.path.indexOf("pro") > -1 ? true : false;
		},
		getHeaderClass() {
			let css = "";
			if (this.isRootPage())
				css += " siteHeaderBig";
			if (this.isSearchPage())
				css += " smallSearch";
			return css;
		},
		getNavClass() {
			return this.isRootPage() || this.isSearchPage() || this.isWelcomePage() ? "notblack" : "black";
		},
		handleSlugOrId() {
			console.log("this.$route", this.$route);
			if (this.$route.name == null && this.$route.params.id == null) {
				//debugger // eslint-disable-line no-debugger
				this.$router.push("/");
				return;
			}
			this.$store.dispatch('getPractitioner', { idOrSlug: this.$route.params.id, redirect: false });
			//this.$store.dispatch('getPractitioner', this.$route, false);
		},
		checkCalendar() {
			if (!this.userHasCalendar || !this.calendarEnabled) {
				this.topMessage.visible = false;
				return;
			}
			axios
				.get(`/practitioner/${this.practitionerId}/opendays`,)
				.then(response => {
					this.topMessage.visible = response.data.length == 0;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
	watch: {
		openingHoursUpdated() {
			if (this.openingHoursUpdated) {
				this.$nextTick(() => { this.checkCalendar(); })
				this.$store.commit('openingHoursUpdated', false);
			}
		},
		'$route'(to, from) {
			if (to !== from) {
				//debugger // eslint-disable-line no-debugger
				if (to.params.entryid)
					this.$store.commit('entryid', to.params.entryid);
				this.handleSlugOrId();
				this.checkCalendar();
			}
		},
	},
	created() {
		console.log("practitionerid: " + this.practitionerId);
		console.log("calendarEnabled: " + this.calendarEnabled);
		this.handleSlugOrId();
	}
}
</script>