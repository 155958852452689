<template>
	<div style="padding:50px ;border:0px solid blue">
		<h3>minHolistiskeBehandler admin</h3>
		<router-link to="/admin/tilmeldinger">Se live event tilmeldinger</router-link><br/>
		<router-link to="/admin/liverabatter">Live event rabatkoder</router-link><br/>
		<br/>
		<br/>
		<b-form class="searchPractitioner">
			<b>Søg behandler</b>
			<b-input-group>
				<b-input type="text" v-model="lookupName" class="lookupName"></b-input>
				<b-input-group-append>
					<b-button variant="primary" @click="findPractitioner">Button</b-button>
				</b-input-group-append>
			</b-input-group>
		</b-form>
		<div class="practitionerSearch">
			<div v-for="practitioner in practitioners" :key="practitioner.id" class="prac">
				<b-icon v-if="practitioner.createStateId == 2" style="color:#888" icon="check-circle-fill" />&nbsp;
				<router-link
					v-bind:to="{ path: `/behandler/${practitioner.slug ? practitioner.slug : practitioner.id}` }">{{
							practitioner.id
					}} - {{ practitioner.name }} ({{ createState(practitioner.createStateId) }})
				</router-link>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
form.searchPractitioner {
	width: 40% !important;
	min-width: 200px !important;
}

div.practitionerSearch {
	padding-top: 10px;

	.prac a {
		font-size: 80%
	}
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
	components: { },
	data() {
		return {
			lookupName: '',
			practitioners: [],
			createStates: [
				"",				//0
				"PendingCreation",//1
				"Created",		//2
				"Quarantined",	//3
				"Terminated",	//4,
				"ManuallyDisabled"//5
			],
			
		}
	},
	computed: {
		...mapGetters(["isAdmin"]),
	},
	methods: {
		createState(createStateId) {
			return this.createStates[createStateId];
		},
		findPractitioner() {
			let query = this.lookupName;
			this.searchResults = null;
			axios
				.get(
					`/admin/findPractitioner?searchTerm=${query}`)
				.then((response) => {
					this.practitioners = response.data;
				})
				.catch((error) => console.log(error));
		},
	},
	mounted() {
		if (!this.isAdmin)
			this.$router.push("/");
	}
}
</script>