<template>
	<div style="padding:50px ;border:0px solid blue">
		<b>minHolistiskeBehandler admin - tilmeldinger</b><br />
		<select v-model="selectedEventId" @change="getTilmeldinger">
			<option v-for="event in events" :value="event.id" :key="event.id"
				:class="{ 'inactive-event': !event.active }">
				{{event.id}} - {{ event.eventName }}
			</option>
		</select><br />
		&nbsp;<br />
		&nbsp;<br />
		<div class="">
			<div class="payments">
				<b-icon style="color:#00af00" icon="check-circle-fill" />&nbsp;
				<b>{{ countTotalPayed(tilmeldinger) }} betalinger modtaget</b>
			</div>
			&nbsp;<br />
			&nbsp;<br />

			<table class="tb">
				<tr>
					<th></th>
					<th>Id</th>
					<th>Dato</th>
					<th>Antal</th>
					<th>Navn</th>
					<th>Email</th>
					<th>Tlf</th>
				</tr>
				<tr v-for="tilmelding in tilmeldinger" :key="tilmelding.id" class="">
					<td v-if="tilmelding.captured == 1"><b-icon icon="check-circle-fill"></b-icon></td>
					<td v-else><span class='notpayed'></span></td>
					<td>{{ tilmelding.id }}</td>
					<td>{{ misc.formatDateTimeForDisplay(tilmelding.createDate) }}</td>
					<td style="text-align: center;">{{ tilmelding.quantity }}</td>
					<td>{{ tilmelding.fullname }}</td>
					<td>{{ tilmelding.email }}</td>
					<td>{{ tilmelding.phoneNo }}</td>
				</tr>
			</table>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.payments {
	font-size: 100%;
	padding: 10px;
	background-color: #eee;
	border: 1px solid #aaa;
	border-radius: 5px;
	display: inline-block;
}

.inactive-event {
	color: #888;
}

th {
	padding: 0 5px;
}

.tb td {
	padding: 5px 2px;
	font-size: 90%;
}

form.searchPractitioner {
	width: 40% !important;
	min-width: 200px !important;
}

div.practitionerSearch {
	padding-top: 10px;

	.prac a {
		font-size: 80%
	}
}
</style>
<script>
import misc from '@/misc.js'
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
	components: {},
	data() {
		return {
			tilmeldinger: [],
			misc: misc,
			events: [],
			selectedEventId: -1,
		}
	},
	computed: {
		...mapGetters(["isAdmin"]),
	},
	methods: {
		createState(createStateId) {
			return this.createStates[createStateId];
		},
		hasPayed(tilmelding) {
			if (tilmelding.captured) {
				return "Ja";
			}
			return "Nej";
		},
		countTotalPayed(tilmeldinger) {
			if (!tilmeldinger) return;
			//I want to know the count of payed tilmeldinger where .captured is true
			let count = 0;
			for (let i = 0; i < tilmeldinger.length; i++) {
				if (tilmeldinger[i].captured == 1) {
					count += tilmeldinger[i].quantity;
				}
			}
			return count;
		},
		getTilmeldinger() {
			this.tilmeldinger = null;
			axios
				.get(
					`/admin/tilmeldinger?eventId=${this.selectedEventId}`)
				.then((response) => {
					this.tilmeldinger = response.data;
				})
				.catch((error) => console.log(error));
		},
		getEvents() {
			this.events = null;
			axios
				.get(`/liveevents`)
				.then((response) => {
					this.events = response.data.sort((a, b) => {
						if (b.active !== a.active) {
							return b.active - a.active;
						}
						// Then, sort alphabetically by eventName if active status is the same
						return a.id > b.id;
					});
				})
				.catch((error) => console.log(error));
		}
	},
	mounted() {
		if (!this.isAdmin) {
			this.$router.push("/");
			return;
		}
		this.getEvents();
		this.getTilmeldinger();
	}
}
</script>