<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Online booking - trin {{ pageState }} af 4</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<book-select-service @treatmentSelected="treatmentSelected" ref="selectService"
								v-show="pageState == pageStates.selectService"></book-select-service>
							<book-select-when @whenSelected="whenSelected" :treatment="treatment" ref="selectWhen"
								v-show="pageState == pageStates.selectWhen"></book-select-when>
							<book-confirm @bookingConfirmed="bookingConfirmed" :treatment="treatment"
								:when="treatmentDate" ref="bookConfirm" v-show="pageState == pageStates.confirm">
							</book-confirm>
							<book-complete v-show="pageState == pageStates.complete"></book-complete>
						</div>
						<div class="modal-footer">
							<button @click="test()" v-show="false">Test</button>
							<button type="button" ref="backButton" class="btn btn-secondary" @click="navigateBack"
								disabled>Tilbage</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style lang="scss" scoped>
.wrap {
	display: flex;
}

.left {
	margin-right: 10px;
}

.content {
	flex-grow: 1;

	.timePeriod {
		color: #888;
		font-size: 80%;
		margin-top: 10px;
	}

	.pill {
		background-color: #1692B6;
		color: white;
		padding: 3px;
		border-radius: 4px;
		margin-right: 5px;
		font-size: 14px;
	}
}


.calendar_default_event_inner {
	background: #2e78d6;
	color: white;
	border-radius: 5px;
	opacity: 0.9;
}
</style>
<style scoped>
.modal-dialog,
.modal-content {
	min-height: 400px;
	/*height: 99vh;
	max-height: 89vh;*/
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	/*height: 70vh;*/
	min-height: 40vh;
	max-height: 70vh;
	overflow-y: auto;
	background-color: #eee;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}


@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>
<script>
//import axios from 'axios';
import { mapGetters } from 'vuex';
import BookSelectWhen from './BookSelectWhen.vue'
import BookSelectService from './BookSelectService.vue'
import BookConfirm from './BookConfirm.vue';
import BookComplete from './BookComplete.vue';

export default {
	components: { BookSelectWhen, BookSelectService, BookConfirm, BookComplete },
	computed: {
		...mapGetters(["practitioner", "practitionerId"]),
	},
	watch: {
		practitionerId() {
		}
	},
	data() {
		return {
			pageStates: { selectService: 1, selectWhen: 2, confirm: 3, complete: 4 },
			pageState: 1,
			treatmentDate: null,
			treatment: null,
			showModal: false,
		}
	},
	methods: {
		show() {
			this.showModal = true;
			this.treatment = null;
			this.pageState = this.pageStates.selectService;
			this.$nextTick(() => { this.$refs.selectService.show(); })
		},
		navigateBack() {
			this.pageState--;
			this.setButtons();
		},
		setButtons() {
			this.$refs.backButton.disabled = this.pageState < 2 || this.pageState > 3;
		},
		bookingConfirmed() {
			this.pageState = this.pageStates.complete;
			this.setButtons();
		},
		whenSelected(dateStr) {
			this.treatmentDate = dateStr;
			this.pageState = this.pageStates.confirm;
			this.setButtons();
		},
		treatmentSelected(_treatment) {
			this.treatment = _treatment;
			this.pageState = this.pageStates.selectWhen;
			this.$refs.selectWhen.init();
			this.setButtons();
		},
	},
}
</script>