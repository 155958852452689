<script>
import axios from 'axios'
import NewsLetterSignupEdit from './NewsLetterSignupEdit.vue'
import EditIcon from './EditIcon.vue';

export default { 
	components: { NewsLetterSignupEdit, EditIcon },
	data() {
		return {
			newsLetterData : {},
			showModal : false,
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
		practitioner () {
			return this.$store.getters.practitioner;
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		editNewsLetterConfig() {
			this.$refs.newsLetterEdit.show();
		},
		fetchItems() {
			if (this.practitionerId <= 0)
				return -1;
			axios
				.get(
					
					`/practitioner/newslettersignupconfig/${this.practitionerId}`
				)
				.then((response) => {
					this.newsLetterData = response.data;
					//this.$forceUpdate();
				})
				.catch((error) => console.log(error));
		},
		show() {
			this.showModal = true;
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>
<template>
	<div>
		<news-letter-signup-edit ref="newsLetterEdit" @refresh="fetchItems"/>
		<div class="card newsletter drop_shadow" v-if="newsLetterData">
			<ul class="list-group list-group-flush">
				<li class="list-group-item">
					<edit-icon @click="editNewsLetterConfig"/>
					<span><b>{{ this.newsLetterData.signupTitle }}</b></span>
				</li>
				<li class="list-group-item">
					<p>{{ this.newsLetterData.description }}</p>
					<button class="btn btn-outline-primary">JA TAK!</button>
				</li>
			</ul>
		</div>
	</div>
</template>
<style scoped>
	
	.modal-dialog, .modal-content 
	{
			min-height: 400px;
			/* height: calc(100% - 20px);*/ 
	}
	.modal-body 
	{
			overflow-y: scroll;
	}
	.modal-mask {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
		display: table;
		transition: opacity .3s ease;
	}

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;
	}

	@media (min-width: 991px)
{
 .modal-lg {
    max-width: auto !important;
   }
}

@media (min-width: 650px) 
{
	.wider {
    min-width: calc(100% - 20px);
   }
  .modal-dialog {
    max-width: auto !important;
    width: calc(100% - 20px);

  }
}

@media (min-width: 4000px) 
{
	.wider {
    min-width: calc(100% - 2px);
   }
  .modal-dialog {
    max-width: auto !important;
    width: calc(100% - 2px);

  }
}
</style>
