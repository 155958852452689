<template>
	<div>
		<div class="calendar_outer" v-if="calendarEnabled">
			<div class="navigator">
				<div class="spacer">
				</div>
				<DayPilotNavigator id="nav" ref="nav" :config="navigatorConfig" />
			</div>
			<div class="calendar">
				<div class="toolbarright">
					<span @click="toggleDatePicker" class="navigatorCurDate">{{
							misc.formatDateForDisplay(config.startDate)
					}}
						<b-icon icon="chevron-down" />
					</span>
					<div style="position:absolute;z-index:100;margin-top:40px" v-if="datePickerShown">
						<DayPilotNavigator id="nav2" ref="nav2" :config="navigatorConfig" />
					</div>

					<b-button size="sm" id="todayButton" class="clear button" @click="gotoToday">I dag</b-button>
					<b-form-group id="pt-group" label-for="duration" style="width:110px">
						<b-form-select class="form-select form-select-sm" id="calendarView" :options="calendarViews"
							v-model="calendarViewIndex">
						</b-form-select>
					</b-form-group>
				</div>
				<DayPilotCalendar id="dp" :config="config" ref="calendar" />
			</div>
		</div>
		<div v-else style="padding:50px">
			Det ser ikke ud til at din kalender er aktiveret. <br />
			<br />
			Klik 'Administration' og aktivér kalenderen.
		</div>
		<AddEditBooking ref="addEditBooking" @refresh="refreshCalendar"></AddEditBooking>
	</div>
</template>
<style lang="scss" scoped>
.calendar_outer {
	display: flex;
	flex-wrap: nowrap;
	margin: 20px 20px 0px 40px;
	gap: 20px;

	.navigatorCurDate {
		border: 1px solid #ddd;
		border-radius: 5px;
		padding: 5px;
		font-size: 16px;
	}

	@media (max-width: 750px) {
		flex-wrap: wrap;

		.navigator {
			display: none
		}
	}

	@media (min-width: 750px) {
		.navigatorCurDate {
			display: none
		}
	}

	.navigator {
		min-width: 210px;

		.spacer {
			height: 50px;
			margin: 0px 0px 10px;
		}
	}

	.calendar {
		//max-width: 1200px;
		//min-width: 800px;
		overflow-x: auto;

		.toolbarright {
			height: 50px;
			margin: 0px 0px 10px;
			display: flex;
			gap: 0px 10px;
			padding: 5px;
		}

		.button {
			height: 40px;
		}
	}
}

.clear {
	background-color: #eee;
	border-color: #bbb;
	color: #888;
}

.title {
	margin-bottom: 20px;
	font-family: 'Raleway-Bold';
	font-weight: 800;

	.treatment {
		padding: 10px;
		background-color: white;
		color: black;
		border-radius: 10px;
	}
}

.left {
	margin-right: 10px;
}
</style>
<style type="text/css">
.calendar_default_event_inner {
	background: #2e78d6;
	color: black;
	border-radius: 5px;
	opacity: 0.9;
}

.unverified div {
	background: #f1f6fc;
	border-color: #cdd1d6;
	color: #727272;
	border-radius: 5px;
	opacity: 0.9;
}

.verified div {
	background: #bedaff;
	color: black;
	border-radius: 5px;
	opacity: 0.9;
}

.closed div,
.blocked div {
	background-color: #888 !important;
	background: repeating-linear-gradient(45deg,
			#eaeaeb,
			#f5f5f5 20px,
			#eaeaeb 20px,
			#f5f5f5 20px);
	border: 1px solid #eee;
	cursor: default;
}

.navigator-disabled-cell {
	background-color: #ddd !important;
	color: #888;
	cursor: not-allowed !important;
}
</style>
<script>
import axios from 'axios';
import misc from '@/misc.js'
import { mapGetters, mapState } from 'vuex';
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from '@daypilot/daypilot-lite-vue'
import AddEditBooking from './AddEditBooking.vue'
//https://code.daypilot.org/10748/vue-js-weekly-calendar-tutorial
export default {
	name: "Calendar",
	components: { DayPilotNavigator, DayPilotCalendar, AddEditBooking },
	computed: {
		...mapState(["practitionerId"]),
		...mapGetters(["isLoggedIn", "calendarEnabled", "isPractitionerPageOwner"]),
		// DayPilot.Calendar object - https://api.daypilot.org/daypilot-calendar-class/
		calendar() {
			return this.$refs.calendar.control;
		},
		width: {
			get() {
				return this.debouncedWidth;
			},
			set(val) {
				if (this.widthTimeout) clearTimeout(this.widthTimeout);
				this.widthTimeout = setTimeout(() => {
					this.debouncedWidth = val;
				}, 100);
			},
		},
	},
	data() {
		return {
			misc: misc,
			datePickerShown: false,
			timeout: null,
			debouncedWidth: 0,
			widthTimeout: null,
			debouncedInput: '',
			calendarViews: [
				{ text: "Dag", value: 0, navValue: "Day", days: 1 },
				{ text: "5 dage", value: 1, navValue: "WorkWeek", days: 5 },
				{ text: "7 dage", value: 2, navValue: "Week", days: 7 },
				{ text: "14 dage", value: 3, navValue: "Days", days: 14 },
			],
			calendarViewIndex: 1,
			slotTypes: {
				Booked: 2,
				UnVerified: 3,
				Closed: 10
			},
			events: [],
			days: 5,
			startDate: new DayPilot.Date(misc.getMonday()),
			navigatorConfig: {
				showMonths: 1,
				skipMonths: 1,
				locale: "da-dk",
				selectMode: "Week",
				startDate: this.startDate,
				selectionDay: this.startDate,
				// onTimeRangeSelect: (args) => {
				// 	this.startDate = args.start;
				// },
				onTimeRangeSelected: args => {
					this.config.startDate = args.day;
					this.fetchDate(args.day);
					console.log("this.datePickerShown: " + this.datePickerShown);
					if (this.datePickerShown)
						this.datePickerShown = false;
				},
			},
			config: {
				viewType: "Days", //this.calendarViews[this.calendarViewIndex].navValue, //"Days",
				locale: "da-dk",
				days: 7,
				weekStarts: 7,
				startDate: this.startDate,
				durationBarVisible: false,
				eventMoveHandling: "Disabled",
				eventResizeHandling: "Disabled",
				headerDateFormat: "ddd dd/MM",
				timeRangeSelectedHandling: "Enabled",
				heightSpec: "BusinessHoursNoScroll",
				onEventClicked: async (args) => {
					if (args.e.data.clickDisabled || args.e.data.doubleClickDisabled) {
						args.preventDefault();
						return;
					}
					this.editBooking(args.e.data.id);
				},
				onTimeRangeSelected: async (args) => {
					this.addBooking(args);
				},
				eventDeleteHandling: "Disabled",
				onEventMoved: (evt) => {
					console.log("Event moved");
					console.log(evt);
				},
				onEventResized: () => {
					console.log("Event resized");
				},
			},
		}
	},
	watch: {
		calendarViewIndex() {
			this.config.days = this.calendarViews[this.calendarViewIndex].days;
			this.refreshCalendar();
		},
		practitionerId() {
			this.fetchDate(this.startDate);
		},
		'$route': {
			handler: function () {
				if (!this.isPractitionerPageOwner) {
					this.$router.push("/");
					return;
				}
				this.config.startDate = new DayPilot.Date(misc.getMonday());
				console.log(this.config.startDate);
				this.$nextTick(() => { this.fetchDate(this.startDate); })
			},
			deep: true,
			immediate: true
		},
		width() {
			console.log("width: " + this.width);
			this.calendarViewIndex = 0;	//5 days
			if (this.width > 500)
				this.calendarViewIndex = 1;	//5 days
			if (this.width > 1000)
				this.calendarViewIndex = 2;	//week
			if (this.width > 1200)
				this.calendarViewIndex = 3;	//14 days
		}
	},
	methods: {
		toggleDatePicker() {
			this.datePickerShown = !this.datePickerShown;
		},
		gotoToday() {
			//debugger; // eslint-disable-line no-debugger
			this.$refs.nav.control.select(new DayPilot.Date(new Date()));
			this.refreshCalendar();
		},
		refreshCalendar() {
			this.fetchDate(this.startDate);
		},
		addBooking(evt) {
			this.$refs.addEditBooking.addBooking(evt.start, evt.end);
		},
		editBooking(bookingId) {
			this.$refs.addEditBooking.showBooking(bookingId);
		},
		fetchDate(date) {
			if (this.practitionerId < 0) return;
			axios
				.get(`/booking/${this.practitionerId}/bookings?d=${misc.formatDateForApi(date)}&days=${this.config.days}`)
				.then(response => {
					console.log("fetchdate: " + date);
					let events = response.data;
					for (let n = 0; n < events.length; n++) {
						if (events[n].slotType == this.slotTypes.Closed) {
							events[n].clickDisabled = true;
							events[n].doubleClickDisabled = true;
						}
					}
					this.events = events;
					this.$refs.calendar.control.update({ events });
				})
				.catch(error =>
					console.log(error)
				);
		},
		loadEvents() {
			// placeholder for an HTTP call
			const events = [
				{
					id: 4,
					start: "2022-02-28T10:00:00",
					end: "2022-02-28T11:00:00",
					text: "Event RF",
					backColor: "#6aa84f",
					borderColor: "#38761d",
				},
				{
					id: 42,
					start: "2022-02-28T13:00:00",
					end: "2022-02-28T16:00:00",
					text: "Event 2",
					backColor: "#f1c232",
					borderColor: "#bf9000",
				},
				{
					id: 332,
					start: "2022-10-10T13:30:00",
					end: "2022-10-10T16:30:00",
					text: "Event 3",
					backColor: "#cc4125",
					borderColor: "#990000",
				},
				{
					id: 43,
					start: "2022-10-11T10:30:00",
					end: "2022-10-11T12:30:00",
					text: "Event 4rrr"
				},
			];
			console.log("events");
			console.log(events);
			this.calendar.update({ events });
		},
		onBrowserResize() {
			this.width = window.innerWidth;
		}
	},
	mounted() {
		this.width = window.innerWidth;
	},
	created() {
		window.addEventListener("resize", this.onBrowserResize);
	},
	destroyed() {
		window.removeEventListener("resize", this.onBrowserResize);
	}
};
</script>