<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">minHolistiskeBehandler - opsætning</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<b-form-checkbox id="profileIsActive" v-model="practitioner.createStateId"
								@change="saveData" name="checkbox-1" :value="createStates.created"
								:unchecked-value="createStates.manually_disabled">
								&nbsp;Behandlerprofilen er aktiv.
							</b-form-checkbox>
							<div class="muted" v-if="practitioner.createStateId == createStates.created">Din
								profil kan fremsøges.</div>
							<div class="muted red" v-else>Din profil kan <b>ikke</b> fremsøges.</div>

							<span v-if="calendarEnabled">
								<b-form-checkbox id="calendarIsActive" v-model="tmpCalendarIsActive" @change="saveData"
									name="checkbox-2" :value="true" :unchecked-value="false">
									&nbsp;Kalender er aktiv.
								</b-form-checkbox>
								<div class="muted" v-if="tmpCalendarIsActive">
									Bookinger kan foretages.</div>
								<div class="muted red" v-else>Bookinger kan <b>ikke</b> foretages.</div>
							</span>
						</div>
						<div class="modal-footer">
							<b-button type="button" class="btn btn-primary" @click="showModal = false">Luk</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style scoped>
.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

div.muted {
	color: #aaa;
	font-size: 80%;
}

div.red {
	color: red
}
</style>
<script>
import axios from 'axios';
// import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
//import { required } from 'vuelidate/lib/validators'

export default {
	data() {
		return {
			showModal: false,
			tmpCalendarIsActive: false,
			createStates: {
				created: 2,
				manually_disabled: 5
			}
		}
	},
	computed: {
		...mapGetters(["isLoggedIn", "practitionerId", "practitioner", "calendarEnabled"]),
	},
	methods: {
		saveData() {
			this.$store.dispatch('updatePractitionerCalendarState', this.tmpCalendarIsActive == true ? 1 : 0);
			axios.post(`/practitioner/entity`, this.practitioner)
				.then(() => {
					console.log("Save done");
					this.$store.commit('openingHoursUpdated', true);
				})
				.catch(error => {
					console.log(error);
				});
		},
		show() {
			this.showModal = true;
			this.tmpCalendarIsActive = this.calendarEnabled;
		},

	},

}

</script>