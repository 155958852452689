<template>
	<div>
		<div class="editor" v-if="editor">
			<menu-bar class="editor__header" :editor="editor" />
			<editor-content class="editor__content" :editor="editor" />
		</div>
	</div>
</template>
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import MenuBar from './MenuBar.vue'

export default ({
	components: {
		EditorContent,
		MenuBar
	},
	props: {
		htmlinput: String,
	},
	data() {
		return {
			editor: null,
		}
	},
	methods: {
		getHTML() {
			return this.editor.getHTML();
		},
		setEditorContents(value) {
			this.editor.commands.setContent(value);
		},
		test() {
			console.log("editor contents: " + this.getHTML());
		},
		
	},
	computed: {
		//  this.editor.content = props["htmlinput"];
		//}
	},
	mounted() {
		this.editor = new Editor({
			editorProps: {
				transformPastedHTML(html) {
					return html.replace(/<h[1-5]/ig, '<b')
						.replace(/<\/h[1-5]/ig, '</b')
				},
			},
			extensions: [
				StarterKit,
				Link.configure({
					openOnClick: false,
				}),
			],
		});
		this.setEditorContents(this.htmlinput);
	},
	beforeDestroy() {
		this.editor.destroy()
	},
})
</script>
<style lang="scss">
.editor {
	display: flex;
	flex-direction: column;
	max-height: 90vh !important;
	min-height: 400px;
	color: #0D0D0D !important;
	background-color: #FFF;
	border: 3px solid #0D0D0D;
	border-radius: 0.75rem;

	&__header {
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		flex-wrap: wrap;
		padding: 0.25rem;
		border-bottom: 3px solid #0D0D0D;
	}

	&__content {
		padding: 1.25rem 1rem;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	&__footer {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		white-space: nowrap;
		border-top: 3px solid #0D0D0D;
		font-size: 12px;
		font-weight: 600;
		color: #0D0D0D;
		white-space: nowrap;
		padding: 0.25rem 0.75rem;
	}


}
</style>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
	>*+* {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	p {
		line-height: 1.2 !important;
		font-size: 0.95em !important;
		float: none !important;
		margin-top: 0px !important;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0D0D0D;
		color: #FFF;
		font-family: 'JetBrainsMono', monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	mark {
		background-color: #FAF594;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	hr {
		margin: 1rem 0;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0D0D0D, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0D0D0D, 0.1);
		margin: 2rem 0;
	}

	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;

		li {
			display: flex;
			align-items: center;

			>label {
				flex: 0 0 auto;
				margin-right: 0.5rem;
				user-select: none;
			}

			>div {
				flex: 1 1 auto;
			}
		}
	}
}
</style>