<template>
	<div class="skills">
		<span v-for="skill in this.skills" :key="skill.id" class="education">{{ skill.title }}<b-icon
				v-if="skill.approvedByMs" class="bookmark" title="Valideret af minSundhed" icon="check-circle-fill" />
		</span>
	</div>
</template>
<style scoped>
div.skills {
	line-height: 1.8em;
	margin-bottom: 5px;
	display:flex;
	flex-wrap: wrap;
}
svg.bookmark {
	font-size: 20px;
	color: #fff;
	position: relative;
	top: 2px;
	margin-left: 5px;
}
span.education {
	background-color: #82b2c7 !important;
	padding: 0px 8px !important;
	padding-top:0px;
	margin-right: 4px;
	margin-top: 7px;
	font-family: Raleway;
	font-weight: 400;
	border-radius: 8px;
	font-size: 0.7rem;
	color: #ffffff;
	white-space: nowrap;
}
</style>
<script>
import axios from 'axios';
import { mapState } from 'vuex'

export default ({
	data() {
		return {
			skills: [],
		}
	},
	computed: mapState(['practitionerId']),
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		refresh() {
			this.fetchItems();
		},
		fetchItems() {
			if (this.practitionerId < 0) return;
			//console.log(`${this.$options._componentTag}-> fetch:${this.practitionerId}`);
			axios
				.get(`/practitioner/skills/${this.practitionerId}`)
				.then(response => {
					this.skills = response.data;
				})
				.catch(error =>
					console.log(error)
				)
		}
	},
	created() {
		this.fetchItems();
	}
})
</script>
