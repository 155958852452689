<template>
	<div class="discount-container">
		<div >
			<div class="event-select">
				<label for="event-select">Select Event:</label>
				<select v-model="selectedEvent" @change="fetchDiscounts">
					<option disabled value="">Please select an event</option>
					<option v-for="event in events" :key="event.id" :value="event.id">{{ event.eventName }}</option>
				</select>
			</div>
		</div>

		<div v-if="selectedEvent > 0" class="discount-table">
			<h3>Discounts for {{ selectedEventName }}</h3>
			<table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Code</th>
						<th>Amount</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="discount in discounts" :key="discount.id">
						<td><input v-model="discount.name" type="text"/></td>
						<td><input v-model="discount.kode" type="text"/></td>
						<td><input v-model="discount.amount" type="number" /></td>
						<td>
							<button @click="saveDiscount(discount)" class="save-button">Save</button>
							<button @click="deleteDiscount(discount.id)" class="delete-button">Delete</button>
						</td>
					</tr>
				</tbody>
			</table>
			<button @click="addNewDiscount" class="add-button">Add New Discount</button>
		</div>
	</div>
</template>
<style scoped>
.discount-container {
  /*max-width: 800px;*/
  margin: 40px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.event-select {
	margin-bottom: 20px;
}

label {
	font-weight: bold;
	margin-right: 10px;
}

select {
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.discount-table {
	margin-top: 20px;
}

h3 {
	margin-bottom: 10px;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}

table th,
table td {
	padding: 10px;
	border: 0px solid #ccc;
	text-align: left;
}

input[type="text"],
input[type="number"] {
	width: 90%;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
}

button {
	padding: 8px 12px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.save-button {
	background-color: #4caf50;
	color: white;
	margin-right: 5px;
}

.delete-button {
	background-color: #f44336;
	color: white;
}

.add-button {
	background-color: #2196f3;
	color: white;
	margin-top: 10px;
}

button:hover {
	opacity: 0.8;
}
</style>
<script>
import axios from 'axios';

export default {
	data() {
		return {
			selectedEvent: -1, // Initialize as null to match event.id type (integer)
			events: [],
			discounts: [],
		};
	},
	computed: {
		selectedEventName() {
			const event = this.events.find((e) => e.id === this.selectedEvent);
			return event ? event.eventName : '';
		},
	},
	methods: {
		fetchEvents() {
			axios.get('/liveevents')
				.then((response) => {
					this.events = response.data;
				})
				.catch((error) => {
					console.error("Error fetching events:", error);
				});
		},
		fetchDiscounts() {
			if (this.selectedEvent) {
				axios.get(`/liveeventrabat/${this.selectedEvent}`)
					.then((response) => {
						this.discounts = response.data;
					})
					.catch((error) => {
						console.error("Error fetching discounts:", error);
					});
			}
		},
		saveDiscount(discount) {
			if (discount.id < 0) { // Assuming negative ID implies a new discount
				// Add a new discount
				const newDiscount = {
					...discount,
					liveEventId: this.selectedEvent, // Ensure LiveEventId is set
					amount: Number(discount.amount), // Convert amount to number
					active: true, // Set default value for active field
				};
				axios.post('/liveeventrabat', newDiscount)
					.then((response) => {
						// Replace the temporary ID with the new ID returned from the server
						const index = this.discounts.findIndex(d => d.id === discount.id);
						if (index !== -1) {
							this.$set(this.discounts, index, response.data);
						}
						alert('Discount added!');
					})
					.catch((error) => {
						console.error("Error adding discount:", error);
					});
			} else {
				// Update an existing discount
				axios.put(`/liveeventrabat/${discount.id}`, discount)
					.then(() => {
						alert('Discount saved!');
					})
					.catch((error) => {
						console.error("Error saving discount:", error);
					});
			}
		},
		deleteDiscount(id) {
			axios.delete(`/liveeventrabat/${id}`)
				.then(() => {
					this.discounts = this.discounts.filter((d) => d.id !== id);
				})
				.catch((error) => {
					console.error("Error deleting discount:", error);
				});
		},
		addNewDiscount() {
			this.discounts.push({
				id: -1, // Use negative ID to identify new discounts locally
				name: '',
				kode: '',
				amount: 0,
				liveEventId: this.selectedEvent, // Ensure LiveEventId is set
				active: true, // Set default value for active field
			});
		},
	},
	mounted() {
		this.fetchEvents();
	},
};
</script>
