<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Velkommen!</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body" style="padding:40px">
							<div v-if="verificationState == states.Pending" style="display:flex;justify-content:center;align-items:center;width:100%;">
								<b-icon icon="arrow-clockwise" animation="spin" font-scale="2" style="margin-right:10px;"></b-icon> Vent et øjeblik...
							</div>
							<div v-else-if="verificationState == states.Success">
								<b class="yessir">Tillykke!</b><br />
								Du er nu en del af Danmarks stærkeste netværk af holistiske behandlere.<br/>
								<br/>
								Om få minutter vil du modtage en velkomstmail fra os - hold øje med den - også i
								spammappen 😊
							</div>
							<div v-else-if="verificationState == states.Failed">
								Det ser ud til at noget gik galt...
							</div>
							<div v-else-if="verificationState == states.AlreadyCreated">
								Din profil ser ud til at være oprettet allerede! <br />
								Login, og nyd netværket! ❤️
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary"
								@click="$router.replace({ path: '/' })">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style lang="scss" scoped>
.yessir {
	font-size: 1.2em;	
}
</style>

<style lang="scss" scoped>
/*small phone*/
@media (max-width: 374px) {}

/*big phone*/
@media (min-width: 375px) {}

/*small tablet*/
@media (min-width: 768px) {}

/*big tablet*/
@media (min-width: 993px) {}

/*laptop*/
@media (min-width: 1264px) {}

/*pc*/
@media (min-width: 1664px) {}
</style>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			showModal: false,
			verificationId: null,
			verificationState: 1,
			states: {
				Pending: 1,
				Failed: 2,
				Success: 3,
				AlreadyCreated: 4,
			},
		}
	},
	methods: {
		async verifyPractitioner() {
			await axios.get(`/practitioner/verify?verificationId=${this.verificationId}`)
				.then(res => {
					if (res.status != 200)
						this.verificationState = this.states.Failed;
					else {
						this.verificationState = res.data == "Already created" ? this.states.AlreadyCreated : this.states.Success;
					}
				})
				.catch(error => {
					console.log("Request returned error: " + error);
					this.verificationState = this.states.Failed;
				});
		}
	},
	created() {
		this.verificationState = 1;
		if (this.$route.query.verificationid == null)
			return;
		this.verificationId = this.$route.query.verificationid;
		console.log("verificationId: " + this.verificationId);
		this.showModal = true;
		this.verifyPractitioner();
	}
}
</script>

