<template>
    <div>
        <img v-bind:src="getMapsUrl()"/>
    </div>
</template>
<style scoped>

</style>
<script>
import { mapState } from 'vuex'
export default ({ 
    components: {  },
    computed: { 
		...mapState(['practitionerId', 'practitioner'])
    },
    methods: {
		getMapsUrl() {
            let address = encodeURIComponent(this.practitioner.address);
            let city = encodeURIComponent(this.practitioner.zip);
			return `https://maps.googleapis.com/maps/api/staticmap?markers=size:mid%7c${address},${city}&zoom=13&size=400x400&format=png8&key=AIzaSyAGOwBHJoBjcynlDd3aeJ_V7VLJe0FSMKs`;
		},
    }
})
</script>