<template>
	<div v-if="blogPost">
		<div class="backContainer" @click="$router.go(-1)">
			<b-link class="back"><b-icon icon="arrow-left-circle-fill" style="color: #fff;"></b-icon> Tilbage</b-link>		
		</div>
		<h3>{{ blogPost.headline }}</h3>
		<h6>{{ blogPost.teaser }}</h6>
		<div class="image drop_shadow thumbcontainer" style="margin:10px 0px 10px">
			<b-img class="blogImg" v-if="blogPost.filename != null" fluid v-bind:src="getImageUrl(blogPost)" alt="blog" />
		</div>
		<div class="publishDate">Publiseret: {{ blogPost.releaseDate }}</div>
		<div class="content" v-html="blogPost.body"></div>
	</div>
</template>
<style scoped>
h3 {font-weight:900;margin-top:40px;}
h6 {font-weight:400;line-height: 150%;}
img.blogImg {
	border: 0px;
	margin-top:10px;
	border-radius: 5px;
}
.content {
	margin-top:30px;
}
</style>
<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	data() {
		return {
			blogPostId: -1,
			blogPost: null //{ headline : '', filename : ''},
		};
	},
	computed: mapState(["practitionerId"]),
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		getImageUrl(blogPost) {
			return `/behandlerportal/images/bh/${blogPost.filename}`;
		},
		fetchItems() {
			//debugger; // eslint-disable-line no-debugger
			axios
				.get(
					
					`/practitioner/blogs/${this.practitionerId}/blogs?entryid=${this.blogPostId}&itemCount=1`
				)
				.then((response) => {
					this.blogPost = response.data[0];
				})
				.catch((error) => console.log(error));
		},
	},
	created() {
		this.blogPostId = this.$route.params.entryid;
		this.fetchItems();
	}
}
</script>