<template>
	<div>
		<SearchResults @resetSearch="doResetSearch()"></SearchResults>
		<Samarbejder></Samarbejder>
	</div>
</template> 
<script>
import Samarbejder from './../FrontPage/HomeSections/Samarbejder.vue';
import SearchResults from "../Search/SearchResults.vue";

export default {
	components: {
		Samarbejder,
		SearchResults,
	},
	methods: {
		doResetSearch() {
			console.log("reset search");
		}
	}
}
</script>