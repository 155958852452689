<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Behandler- /firmanavn</h5>
							<b-button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true" @click="showModal = false">&times;</span>
							</b-button>
						</div>
						<div class="modal-body" style="font-size:80%">
							<form-group
								:validator="$v.practitioner.name"
								id="input-group-1"
								label="Virksomheds-/personnavn"
								label-for="input-1"
								description=""
							>
								<b-form-input
									id="name"
									@blur="$v.practitioner.name.$touch()"
									v-model="practitioner.name"
									placeholder="Indtast dit navn eller virksomhedens navn"
									required
								></b-form-input>
							</form-group>

							<b-form-group id="input-group-2" label="Tag-line" label-for="tagline">
								<b-form-input
									id="tagline"
									v-model="practitioner.tagline"
									placeholder=""
									required
								></b-form-input>
							</b-form-group>

							<b-form-group id="input-group-2" label="Din 'minHolistiskeBehandler' mundrette webadresse, fx 'JanesErgoterapi', bliver til: https://minHolistiskeBehandler.cfhs.dk/­behandler/­janesergoterapi" label-for="Slug">
								<b-form-input
									id="slug"
									v-model="practitioner.slug"
									placeholder=""
									@blur="checkSlug"
									required
								></b-form-input>
							</b-form-group>
							<div class="slugtaken" v-if="slugtaken">
								Dette navn er desværre allerede taget! 😔
							</div>
							<br/>

							<b-card no-body>
								<b-tabs card>
									<b-tab title="Professioner" active>
										<b-card-text>
											<practitioner-skills />
										</b-card-text>
									</b-tab>
								</b-tabs>
							</b-card>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary"  @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style scoped>
.modal-dialog, .modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	overflow-y: scroll;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}
.slugtaken {
	margin-top: 2px;
	font-family: roboto;
	font-weight: 400;
	background-color: #035882;
	border: 1px solid #00aaff;
	color: white;
	padding: 8px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
</style>
<script>
import PractitionerSkills from './PractitionerSkills.vue'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'

export default ({ 
  components: { PractitionerSkills },
	data() {
		return {
			slug : '',
			slugtaken : false,
			showModal : false,
		}
	},
	validations: {
		practitioner : {
			name: { required }
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
		practitioner () {
			return this.$store.getters.practitioner;
		},
	},
	methods: {
		async checkSlug() {
			if(!this.practitioner.slug) return;
			await axios.get(`/practitioner/entity/${this.practitionerId}/slug/avail/${this.practitioner.slug}`)
				.then((response) => {
					this.slugtaken = !response.data;
				})
				.catch(error => {
					console.log(error);
				});
		},
		async saveForm() {
			axios.post(`/practitioner/entity`, this.practitioner)
				.then(() => {
					this.$emit('refreshPractitioner');
					this.showModal = false;
				})
				.catch(error => {
					console.log(error);
				});        
		},
		show() {
			this.showModal = true;
			this.slug = this.practitioner.slug;
		},
	},

})
</script>
