<template>
	<div v-if="showForm" class="treatment">
		<div class="approved">
			<div class="name">Tak! 🙏</div>
			<div class="doneDescription">Din booking er nu sendt videre i vores system.<br />
				<br/>
				<b>OBS Din booking er ikke endelig, før du har bekræftet via den mail du modtager fra os lige
				om lidt. Så hold øje med din indbakke (og måske din spammappe, hvis vi er så uheldige at lande der). </b><br/>
				<br/>
				Vi ønsker dig en dejlig dag!<br />
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.treatment {
	margin: 0px 0px 0px 20px;
	padding-right:20px;

	.approved {
		.doneDescription {
			margin-top:10px;
			font-family: 'Raleway' !important;
		}
	}
}
</style>
<script>
export default {
	data() {
		return {
			showForm: true,
		}
	},
}
</script>