<template>
	<div>
		<VerifyPractitioner></VerifyPractitioner>
		<Therapists></Therapists>
		<LatestEvents></LatestEvents>
		<TherapistsBanner></TherapistsBanner>
		<TherapistProfiles></TherapistProfiles>
		<Samarbejder></Samarbejder>
	</div>
</template> 
<script>
import Therapists from './HomeSections/Therapists.vue';
import TherapistsBanner from './HomeSections/TherapistBanner.vue';
import TherapistProfiles from './HomeSections/TherapistProfiles.vue';
import Samarbejder from './HomeSections/Samarbejder.vue';
import VerifyPractitioner from './../SignUp/VerifyPractitioner.vue';
import LatestEvents from './HomeSections/LatestEvents.vue';

export default {
	components: {
		VerifyPractitioner,
		Therapists,
		TherapistsBanner,
		TherapistProfiles,
		Samarbejder,
		LatestEvents,
	},
	props: {
		q: { type: String }
	},
}
</script>

