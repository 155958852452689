<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Billeder</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<div v-if="editEntry">
								<form @submit="saveForm" ref="form">
									<form-group id="input-group-2" label="Beskrivelse" label-for="description">
										<b-form-textarea id="description" v-model="entryBeingEdited.description"
											placeholder="Skriv evt. en beskrivelse" rows="2" max-rows="4">
										</b-form-textarea>
									</form-group>

									<form-group id="input-group-4" label="" label-for="active">
										<b-button @click="uploadClicked(entryBeingEdited.id)" variant="success">
											Upload billede</b-button>
										{{ filename }}
										<div v-if="entryBeingEdited.filename != null" class="imageContainer">
											<b-img class="previewImg" ref="previewImg" id="previewImage"
												v-bind:src="getImageUrl(entryBeingEdited.id)" />
										</div>
									</form-group>

									<input style="display: none;" type="file" ref="fileInput"
										@change="onFileSelected" />
								</form>

							</div>
							<!-- ***************************************************************************************************
							VIEW ITEMs
							*************************************************************************************************** -->
							<div v-else>
								<span class="text-muted">De første 6 billeder vises på din profilside. Billeder sorteres automatisk 
									efter oprettelsesdatoen (nyeste øverst). Dog med undtagelse af stjernemarkerede billeder, som altid vil ligge øverst. </span><br />
								<br>
								<b-button size="sm" class="mb-2" variant="primary" @click="addEntry">
									<!-- <span style="font-size:22px"> -->
									<b-icon icon="plus-circle-fill" title="Tilføj ny" variant="" /> Tilføj billede
								</b-button>
								<b-table-simple class="tight" small responsive>
									<b-thead>
										<b-th>&nbsp;Billede</b-th>
										<b-th>Beskrivelse</b-th>
										<b-th>Dato</b-th>
										<b-th cols="3" colspan="4">Funktioner</b-th>
									</b-thead>
									<b-tbody v-for="(item, index) in galleryItems" :key="item.id">
										<b-tr>
											<b-td style="width:30%;height:150px">
												<b-img class="previewImg" ref="previewImg" id="previewImage"
													style="cursor:pointer" v-bind:src="getImageUrl(item.id)"
													@click="editItem(item, index)" />
											</b-td>
											<b-td style="width:40%">
												{{ item.description }}
											</b-td>
											<b-td style="width:15%">
												{{ formattedDateTime(item.createDate) }}
											</b-td>
											<b-td class="align-top" style="vertical-align:middle;">
												<b-icon style="font-size:20px;cursor:hand" title="Stjernemarker"
													v-bind:icon="item.starred ? 'star-fill' : 'star'"
													@click="starItem(item, index)" />
												&nbsp;
												
												<b-icon style="font-size:17px;cursor:hand" title="Rediger"
													icon="pencil-square"
													@click="editItem(item, index)" />
												&nbsp;
												
												<b-icon style="font-size:20px;cursor:hand" title="Slet"
													icon="trash-fill" @click="deleteItem(item, index)" />
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</div>
						</div>
						<div v-if="editEntry" class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="editEntry = false">Fortryd</button>
						</div>
						<div v-else class="modal-footer">
							<button type="button" class="btn btn-primary" @click="closeForm">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style scoped>
div.imageContainer {
	width: 100%;
	min-height: 400px;
	max-height: 500px;
	border: 1px solid #aaa;
	overflow-y: scroll;
	margin-top: 10px;
}

a.removeImageLink {
	font-size: 80%;
	text-decoration: underline;
}

img.previewImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top;
	border-radius: 5px;
	border: 0px solid #aaa;
}

div.tight table td {
	font-size: 80%;
	border-bottom: 0px !important;
	padding: 5px
}

div.tight table tbody {
	border-top: 0px solid #eee
}

div.tight table th {
	color: #aaa;
	font-weight: normal;
	font-size: 80%;
	padding: 5px
}

tbody.inactive td {
	color: rgb(204, 204, 204)
}

div.cropped {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	font-size: 15px;
	line-height: 18px;
	max-height: 90px;
}

.modal-dialog,
.modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	height: 70vh;
	overflow-y: auto;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>
<script>
import axios from 'axios';

export default ({
	components: {

	},
	data() {
		return {
			editEntryIndex: -1,
			editEntry: false,
			newId: -1,
			galleryItems: [],
			entryBeingEdited: null,
			showModal: false,
			clickedEntryId: -1,
			filename: null,
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	methods: {
		formattedDateTime(ds) {
			let d = new Date(ds);
			let dateString = d.getDate() + "/" + (d.getMonth() + 1) + "-" + d.getFullYear() + " ";
			if (d.getHours() != 0 || d.getMinutes() != 0)
				dateString += d.getHours().pad(2) + ":" + d.getMinutes().pad(2);
			return dateString;
		},
		getImageUrl(id) {
			return id < 0 ? `/behandlerportal/images/blank.gif` : `/behandlerportal/images/bh/${this.practitionerId}_images_${id}.jpg`;
		},
		uploadClicked(itemId) {
			this.clickedEntryId = itemId;
			this.$refs.fileInput.click();
		},
		onFileSelected(event) {
			this.filename = event.target.files[0].name;
		},
		closeForm() {
			this.showModal = false;
			this.$emit("refresh");
		},
		saveImage() {
			if (this.filename == null)
				return;
			console.log("name: " + this.$refs.fileInput.files[0].name);
			const fd = new FormData();
			fd.append("practitionerId", this.practitionerId);
			fd.append("entryid", this.clickedEntryId);
			fd.append("file", this.$refs.fileInput.files[0]);
			axios
				.post('/practitioner/gallery/saveimage', fd)
				.then(res => {
					console.log("done: " + res)
				})
				.finally(() => {
					this.fetchItems()
				});
		},
		saveForm(e) {
			e.preventDefault();
			axios.post(`/practitioner/gallery/${this.practitionerId}/${this.entryBeingEdited.id}`, this.entryBeingEdited)
				.then(response => {
					this.clickedEntryId = response.data;
					this.saveImage();
					this.editEntry = false;
					this.fetchItems();
				})
				.catch(error => {
					console.log(error);
				});
		},
		editItem(entry, index) {
			this.filename = null;
			this.entryBeingEdited = {
				id: entry.id,
				description: entry.description,
				filename: entry.filename
			}
			this.editEntryIndex = index;
			this.editEntry = true;
		},
		starItem(item) {
			//count all 
			if (!item.starred) {
				if (this.galleryItems.filter((b) => b.starred).length >= 6) {
					alert("Der er allerede 6 stjernemarkede emner. Fjern en eksisterende stjernemarkering og prøv igen.")
					return;
				}
			}
			axios
				.post(`/practitioner/gallery/${this.practitionerId}/togglestar/${item.id}`)
				.then(() => {
					this.fetchItems();
				})
				.catch(error =>
					console.log(error)
				);
		},
		deleteItem(item) {
			axios
				.delete(`/practitioner/gallery/${this.practitionerId}/delete/${item.id}`)
				.then(() => {
					this.galleryItems = null;
					this.fetchItems();
				})
				.catch(error =>
					console.log(error)
				);
		},
		addEntry() {
			this.editEntryIndex = -1;
			this.entryBeingEdited = {
				id: -1,
				description: "",
			};
			this.editEntry = true;
		},
		fetchItems() {
			console.log("Fetch items..");
			axios
				.get(`/practitioner/gallery/${this.practitionerId}?itemCount=1000`)
				.then(response => {
					this.galleryItems = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
		postForm() {
			console.log("postForm..");
			axios
				.post(`/practitioner/gallery/${this.practitionerId}`, this.entryBeingEdited)
				.then(() => {
					this.editEntry = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		show() {
			this.fetchItems();
			this.showModal = true;
		}
	},
})
</script>

