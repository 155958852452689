<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Ekspertiseområder</h5>
							<b-button @click="closeForm()" type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<b-form-group label="Vælg én eller flere områder, hvori du har ekspertise til at rådgive og behandle">
								<b-form-checkbox-group
									id="checkbox-group-1"
									v-model="selected"
									text-field="contents"
									value-field="id"
									:options="mainKeywords"
									switches
									name="flavour-1">
								</b-form-checkbox-group>
							</b-form-group>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style scoped>
	.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-dialog, .modal-content {
	height: 100% - 60px;
	max-height: 89vh;
}
</style>
<style>
label span { padding-left: 4px; font-weight: 300;}
</style>
<script>
import axios from 'axios';

export default {
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	data() {
		return {
			mainKeywords : [],
			showModal : false,
			selected: []
		}
	},
	methods: {
		closeForm() {
			this.showModal = false;
		},
		saveForm() {
			//debugger; // eslint-disable-line no-debugger
			axios.get(`/keywords/${this.practitionerId}/update?keywordIds=` + this.selected.toString() )
				.then(() => {
					this.showModal = false;
					this.$emit("refresh");
				})
				.catch(error => {
					console.log(error);
				});
		},
		show() {
			this.showModal = true;
			this.fetchItems();
		},
		fetchItems() {
			axios
				.get(`/keywords/main`)
				.then(response => {
					this.mainKeywords = response.data;
				})
				.catch(error =>
					console.log(error)
				);
			//get practitioner selected keywords
			axios
				.get(`/${this.practitionerId}/keywordIds`)
				.then(response => {
					this.selected = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
}
</script>