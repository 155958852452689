<template>
	<div v-if="showForm && treatment" class="treatment">
		<div class="chosen">Godkend dit valg:</div>
		<div class="ordersummary">
			<div class="name">{{ treatment.treatmentName }}</div>
			<div class="minutes">{{misc.getDurationForDisplay(treatment.duration) }}</div>
			<div class="price">Pris: <span>{{treatment.price}}</span></div>
			<div class="hos">Hos:
				<span class="practitioner">{{ practitioner.name }}</span>
			</div>
			<div class="when">
				<div class="date">
					<b-icon class="dot" icon="calendar2-check" />{{ misc.formatDateForDisplay(when) }}
				</div>
				<div class="date">
					<b-icon class="dot" icon="clock" />kl. {{ misc.formatTime(when) }}
				</div>
			</div>
		</div>
		<div v-if="false" class="message">
			<div>Besked fra behandleren</div>
			Lorem ipsom domlelskldksmflksf asdfas fasdf<br />
			1. sdfasldkfjasdfas<br />
			2. vsdfasdf asasdf<br />
		</div>
		<div class="userInfo">
			<b>Bestiller:</b>
			<form ref="form" class="needs-validation loginform" novalidate>
				<div class="row">
					<form-group id="fieldset-1" description="" label="Indtast dit navn" label-for="fullname"
						:validator="$v.fullname">
						<b-form-input id="fullname" type="text" @blur="$v.fullname.$touch()" v-model="fullname" trim>
						</b-form-input>
					</form-group>
				</div>
				<div class="row">
					<form-group :validator="$v.email" label="Indtast emailadresse">
						<b-form-input type="text" @blur="$v.email.$touch()" v-model="email" />
					</form-group>
				</div>
				<div class="row">
					<form-group :validator="$v.phone" label="Indtast dit telefonnr.">
						<b-form-input type="text" @blur="$v.phone.$touch()" maxlength="8" v-model="phone"
							autocomplete="off" style="max-width:150px" />
					</form-group>
				</div>
			</form>
		</div>
		<div class="approve">
			<b-button id="approveButton" variant="success" @click="createBooking">
				<b-icon class="dot" icon="check-circle-fill" /> Godkend
			</b-button>

		</div>
	</div>
</template>
<style lang="scss" scoped>
.treatment {
	margin: 0px 0px 0px 0px;
	padding-right: 5px;

	.ordersummary {
		clear: both !important;
		background-color: #1692B6 !important; // #19f306;
		color: white;
		padding: 20px;
		border-radius: 10px;
		margin-top: 10px;

		.practitioner {
			font-family: 'Raleway-medium' !important;
			font-weight: 500;
			font-size: 100%;
		}

		.name {
			font-family: 'Raleway' !important;
			font-size: 20px;
			font-weight: 600;
		}

		.minutes {
			font-family: 'Raleway' !important;
			display: block;
			font-weight: 400;
			font-size: 18px;
		}

		.price {
			font-family: 'Raleway' !important;
			font-weight: 400;
			font-size: 16px;

			span {
				font-weight: 600;
				font-size: 22px;
			}
		}

		.when {
			display: inline-block;
			background-color:#f1f1f1;
			padding:10px;
			border-radius: 10px;
			margin-top:15px;
			color:#444;
			box-shadow: 0px 0 8px 2px #d9d9d9;

			.date {
				font-family: 'Raleway' !important;
				font-size: 20px;
				font-weight: 600;

				.dot {
					margin-right: 10px;
				}
			}
		}
	}

	.userInfo {
		font-family: 'Raleway' !important;
		margin-top: 20px;
		padding: 20px;
		background-color: #fff;
		border-radius: 8px;

		input,
		textarea,
		select {
			background-color: #f1f1f1 !important;
		}

	}

	.approved {
		.doneDescription {
			margin-top: 10px;
			font-family: 'Raleway' !important;
		}
	}

	.chosen,
	.hos {
		font-family: 'Raleway' !important;
		font-size: 100%;
		clear: both;
		margin-top: 0px;
	}

	.approve {
		margin-top: 20px;
	}

	.message {
		margin-top: 20px;
		padding: 20px;
		font-family: 'Raleway' !important;
		font-size: 18px;
		background-color: #ddd;
		color: #000;
		border-radius: 10px;

		div {
			font-family: 'Raleway-Bold' !important;
			font-weight: 800;
			padding-bottom: 8px;
		}
	}
}
</style>
<script>
import axios from 'axios';
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import misc from '@/misc.js'
export default {
	components: {},
	computed: {
		...mapGetters(["practitioner", "practitionerId"]),
	},
	watch: {
	},
	props: {
		treatment: Object,
		when: String,
	},
	data() {
		return {
			misc: misc,
			showForm: true,
			formStates: { confirm: 1, complete: 2 },
			formState: 1,
			email: "",
			fullname: "",
			phone: "",
		}
	},
	validations: {
		email: { required },
		fullname: { required },
		phone: { required, maxLength: maxLength(8), minLength: minLength(8) },
	},
	methods: {
		createBooking() {
			this.$v.$touch();
			if (this.$v.$invalid)
				return;

			let start = new Date(misc.parseDateTime(this.when));
			let end = new Date(start.getTime() + misc.getDurationMinutes(this.treatment.duration) * 60000);
			const userBooking = {
				fullname: this.fullname,
				email: this.email,
				phone: this.phone,
				bookingDto: {
					id: -1,
					practitionerId: this.practitionerId,
					practitionerInTreamentId: this.treatment.id,
					startDateTime: misc.formatDateTimeForApi(start),
					endDateTime: misc.formatDateTimeForApi(end),
				}
			}
			axios.post(`/booking/${this.practitionerId}/createorupdate`, userBooking)
				.then(() => {
					this.$emit("bookingConfirmed");
				})
				.catch(error => {
					console.log(error);
					alert("Der skete en fejl under oprettelse af din booking :(\nPrøv igen eller kontakt supporten.")
				});
		},
		show() {
			this.showForm = true;
		},
	},
}
</script>