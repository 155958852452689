<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Afbestilling af booking</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body" style="min-height:300px">
							<div v-if="verificationState == states.Pending"
								style="display:flex;justify-content:center;align-items:center;width:100%;">
								<b-icon icon="arrow-clockwise" animation="spin" font-scale="2"
									style="margin-right:10px;"></b-icon> Vent et øjeblik...
							</div>
							<div v-else-if="verificationState == states.Success">
								<b class="yessir">Tak 🙏<br/>
								Din afbestilling er nu registeret og vil blive sendt videre til behandleren.</b><br />
								Du vil om kort tid også modtage en bekræftigelse på mail.<br />
								<br/>
								<i style="color:#888">Er afbestillingen sket i henhold til behandlerens gældende afbudspolitik, sker der ikke yderligere. 
								Er afbuddet kommet for sent, har behandleren ret til at håndhæve sin afbudspolitik. Dette sker udenom 
								minHolistiskeBehandler.dk.</i><br/>
								<br/>
								Du er velkommen til at bestille en ny tid.<br/>
								Vi ønsker dig en dejlig dag!
							</div>
							<div v-else-if="verificationState == states.Failed">
								Det ser ud til at noget gik galt...
							</div>
							<div v-else-if="verificationState == states.AlreadyCancelled">
								Din booking ser ud til allerede at være afbestilt.
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary"
								@click="$router.replace({ path: '/' })">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style lang="scss" scoped>
.yessir {
	font-size: 1em;
	color: #1e96c8
}
</style>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			showModal: false,
			bookingId: null,
			canceltoken: null,
			verificationState: 1,
			states: {
				Pending: 1,
				Failed: 2,
				Success: 3,
				AlreadyCancelled: 4,
			},
		}
	},
	methods: {
		async cancelBooking() {
			await axios.get(`/booking/cancel?bookingId=${this.bookingId}&=canceltoken=${this.canceltoken}`)
				.then(res => {
					console.log("res.data:" + res.data)
					this.verificationState = this.states.Success;
				})
				.catch(error => {
					console.log("Request returned error: " + error);
					if (error.response.data && error.response.data == "Booking already cancelled")
						this.verificationState = this.states.AlreadyCancelled;
					else
						this.verificationState = this.states.Failed;
				});
		}
	},
	created() {
		this.verificationState = 1;
		if (this.$route.query.canceltoken == null || this.$route.query.bookingId == null)
			return;
		this.canceltoken = this.$route.query.canceltoken;
		this.bookingId = this.$route.query.bookingId;
		this.showModal = true;
		this.cancelBooking();
	}
}
</script>

