<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title"></h5>
							<b-button type="button" @click="closeWindow" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div v-if="toShow==1" class="modal-body page">
							<p class="codeofconduct">Code of Conduct ❤️</p>
							<ul>
								<li>Jeg arbejder med en helhedsorienteret tilgang til forebyggelse og behandling</li>

								<li>Jeg er nysgerrig og søger altid at udvide min horisont, både fagligt og personligt
								</li>
								<li>Jeg lægger vægt på, at være fagligt funderet i både praksis, teori og metode</li>
								<li>Jeg omgås mine faglige kompetencer med både ydmyghed og stolthed</li>
								<li>Jeg erkender, at jeg ikke står med ”den fulde sandhed” for noget andet menneske</li>
								<li>Jeg mener, det er vigtigt, at vi behandlere kender egne grænser. At vi ved, hvornår
									vi
									bør sende et menneske videre til en anden behandler/behandling</li>
								<li>Jeg tror på, og ser værdien i tværfaglige samarbejder</li>
								<li>Jeg foretrækker Alliance fremfor Konkurrence</li>
								<li>Jeg udviser altid en venlig, åben og respektfuld tone i interaktionen med andre
									mennesker, også de, som jeg ikke er enige med</li>
								<li>Jeg mener der skal være plads til uenighed, at forskelligheder kan være en styrke
								</li>
								<li>Jeg vil gerne, og gør en indsats for en sundere og mere kærlig verden </li>
								<li>Jeg tror på at ”Sammen bliver vi stærkere”</li>
							</ul>
							<p style="color:">Kære dedikerede behandler - velkommen til 🙂
								Vi glæder os til at have dig med på rejsen mod en mere helhedsorienteret fremtid, hvor I
								har en respekteret og anerkendt plads i sundhedssystemet.<br />
							</p>
							<p>De allerbedste hilsner fra os på minHolistiskeBehandler.dk</p>
						</div>
						<div v-if="toShow==2" class="modal-body page">
							<p><b>Aftalens indgåelse</b><br />
								Det er i 2022 gratis at oprette en behandlerprofil på minHolistiskeBehandler.dk. </p>
							<p>I det nye år vil du modtage en forespørgsel pr. mail fra os, om hvorvidt du ønsker at
								fortsætte som behandler
								hos os. Her vil det stå dig helt frit for at takke nej. </p>
							<p>Ønsker du i 2023 at fortsætte som behandler på minHolistiskeBehandler.dk, vil prisen for en
								fuldgyldig
								behandlerprofilside på søgeportalen, inkl. en basis digital bookingkalender ligge på
								omkring 49,- pr. mdr.
								uden binding. </p>

							<p><b>Virksomheden</b><br />
								minHolistiskeBehandler.dk er del af virksomheden minSundhed ApS – ligesom søster websitet
								minSundhed.com er det.</p>

							<p>Med en behandler brugerprofil på minHolistiskeBehandler.dk, får du derfor også en brugeradgang til
								minSundhed.com. Her
								kan du på linje med alle andre brugere, skrive til eksperterne i vores Ekspertforum
								omkring personlige
								spørgsmål – ikke i forbindelse med klientproblematikker. Spørgsmål og svar er offentligt
								tilgængelige. </p>

							<p>Når du opretter en behandlerprofil på minHolistiskeBehandler.dk, indgår du aftale med minSundhed
								ApS, CVR-nr.
								42284610, tlf. 23 74 14 37, e-mail: kontakt@minsundhed.com. </p>

							<p>Efter oprettelse vil du modtage en bekræftelse pr. mail med informationer om praktiske
								regler for brug af
								administrationssiderne tilknyttet din personlige profilside.</p>

							<p>Personlige oplysninger som alder og lign. benyttes kun internt og eksponeres ikke på
								søgeportalen. </p>

							<p>Du kan altid afmelde dig som behandler på minHolistiskeBehandler.dk på din personlige profilside
								(OBS du skal være
								logget ind for dette).</p>

							<p>Hvis behandlerprofilen misbruges eller misrøgtes, har minSundhed ApS ret til straks at
								lukke profilen og evt.
								kræve erstatning for det tab (med renter og omkostninger), som minSundhed ApS har lidt.
							</p>

							<p><b>Rabatkoder</b><br />
								De rabatkoder man har mulighed for at benytte som bruger på minSundhed.com er alle
								personlige og må kun
								benyttes til eget brug og aldrig videresendes til tredjemand. Misbrug af rabatkoderne
								vil medføre en
								advarsel og ved fortsat misbrug medføre en permanent annullering af behandlerprofil og i
								samråd med de(n)
								relevante butik(ker) et evt. juridisk efterspil.</p>

							<p><b>Opsigelse og fortrydelsesret</b><br />
								Du kan altid afmelde dig din behandlerprofil på minHolistiskeBehandler.dk ved at sende os en mail
								på
								kontakt@minsundhed.com. <br />
								Senere på året 2022, vil du også kunne afmelde dig på din personlige behandlerprofilside
								(OBS du skal være
								logget ind for dette).</p>

							<p>For at sikre en god og hurtig behandling af din henvendelse beder vi dig oplyse følgende:
							<ul>
								<li>Navn</li>
								<li>E-mail adresse oplyst ved oprettelsen</li>
							</ul>
							</p>

							<p>Efter opsigelse vil alle dine oplysninger samt eventuelt lagrede kortoplysninger blive
								slettet i vores
								system.</p>

							<p>minSundhed ApS forbeholder sig retten til fortsat at beholde eventuelle afgivne spørgsmål
								til vores eksperter
								som synlige under anonymt brugernavn på minSundhed.com. Hvis dette også ønskes fjernet
								efter opsigelse,
								bedes du kontakte os for dette særlige ønske på kontakt@minsundhed.com.</p>

							<p><b>Andre brugerbetingelser</b><br />
								Din profil på minHolistiskeBehandler.dk og minSundhed.com er knyttet til dit bruger ID. og er
								personligt for dig. Det
								må derfor ikke deles med andre udenfor din husstand. </p>

							<p>Dine personlige oplysninger opbevares fortroligt og benyttes kun til intern målretning af
								det materiale, vi
								præsenterer dig for. Du kan altid ændre dine personlige indstillinger på dine
								administrationssider på
								minHolistiskeBehandler.dk eller under ”min side” på minSundhed.com.<br />
								Som behandler på minHolistiskeBehandler.dk og bruger på minSundhed.com gælder reglerne for
								almindelig god etik og
								opførsel. Med dette menes, at vi fx ikke tolerer indlæg eller artikler der afspejler
								racisme, had, trusler
								eller lignende. I dette tilfælde forbeholder minSundhed ApS sig retten til øjeblikkeligt
								og uden varsel, at
								fjerne en sådan udtalelse fra hjemmesiden. Andre, for forummet, stødende eller seksuelt
								krænkende udtalelser
								vil ligeledes blive fjernet. I grelle tilfælde kan minSundhed ApS endog slette en profil
								uden varsel og med
								øjeblikkelig virkning.</p>

							<p>Behandlere må ikke med salg for øje promovere eller linke til produkter og/eller
								kommercielle sider på
								minSundhed.com. Indlæg der promoverer produkter og eller indeholder links til
								kommercielle sider vil blive
								slettet uden varsel af minSundhed ApS. Dette gælder ikke for egen personlige
								behandlerprofilside på
								minHolistiskeBehandler.dk, hvor salg af egne produkter, varer og services anses som en naturlig
								del af den personlige
								behandlerprofilside. </p>

							<p><b>Ansvar</b><br />
								minSundhed ApS tager ikke ansvaret for indholdet af de personlige behandlerprofilsider
								på minHolistiskeBehandler.dk.
								Vi vil altid gøre vores bedste for at opretholde god lov og orden på minHolistiskeBehandler.dk,
								og fjerne anstødende
								eller hadefuldt indhold fra personlige behandlerprofilsider.</p>

							<p>minSundhed ApS forbeholder sig retten til at fjerne personlige behandlerprofilsider på
								minHolistiskeBehandler.dk, hvis
								disse benytter sig af titler underlagt Autorisationsloven uden at have uploadet
								dokumentation på
								minHolistiskeBehandler.dk for retten til brug af denne titel.</p>

							<p>Det er ikke tilladt at kopiere indhold fra andre behandlerprofilsider på minHolistiskeBehandler.dk
								uden dokumenteret
								og skriftlig tilladelse.<br />
								Det er ikke tilladt at miskreditere andre behandlere eller privatpersoner på din
								personlige
								behandlerprofilside.<br />
								Det er ikke tilladt at opfinde egne brugeranmeldelser på din personlige
								behandlerprofilside. Opdages dette
								vil anmeldelsen blive fjernet øjeblikkeligt.<br />
							</p>

							<p>minSundhed ApS tager ikke ansvaret for indlæg afgivet i fællesforummet, da vi af
								ressourcemæssige årsager
								ikke til enhver tid kan kontrollere alle disse indlæg.<br />
								Vi opfordrer i stedet vores brugere til at kontakte os på kontakt@minsundhed.com, hvis
								man finder upassende
								indhold, hvorefter vi vil få det fjernet.</p>

							<p>Alle spørgsmål og svar i Ekspertforummet på minSundhed.com er som udgangspunkt offentlige
								for alle besøgende
								på minSundhed.com. Brugere præsenteres altid anonymt og som udgangspunkt med det
								brugernavn, der vælges ved
								tilmelding. OBS indsæt ikke dit personlige navn eller klinknavn som brugernavn ved
								tilmelding af din
								behandlerprofil - det bør være anonymt. Fortvivl dog ej, hvis du har tilmeldt dig med
								klinik eller
								personlige navn ved tilmelding af behandlerprofil. Dit brugernavn kan altid ændres på
								”min side” på
								minSundhed.com - dette har ingen indflydelse på indholdet på din personlige
								behandlerprofilside.</p>

							<p>Alt materiale, alle spørgsmål/svar og indlæg på minSundhed.com tilhører minSundhed ApS og
								er ophavsretligt
								beskyttet. Materialer og information må ikke kopieres, sælges, distribueres eller på
								anden måde gengives
								uden skriftlig tilladelse fra minSundhed ApS.</p>

							<p>Brugere af minSundhed.com kan dog frit benytte materialet til eget personligt
								ikke-kommercielt brug på disse
								betingelser:</p>
							<ul>
								<ol>1. At informationen forbliver hel, uredigeret og uden ændringer.</ol>
								<ol>2. At intet gebyr må tages for privat udlån af dette.</ol>
								<ol>3. At ophavsrettigheder og minSundhed.com udtrykkeligt angives som kilde.</ol>
								<ol>4. At videregivne informationer/links allerede er offentligt tilgængelige</ol>
							</ul>

							<p>Indholdet på minSundhed.com må og kan ikke bruges som basis for at stille en diagnose
								eller fastlægge en
								behandling. Vi opfordrer altid brugere med helbredsproblemer til at opsøge egen læge.
								Rådgivning og
								information på minSundhed.com må aldrig, uden egen læges samtykke, erstatte en medicinsk
								behandling. Skulle
								en bruger alligevel vælge dette, er det derfor på brugerens eget ansvar.</p>

							<p>Vi har stor tillid til vores tilknyttede eksperter, deres tunge faglige viden og har
								udvalgt dem alle med
								omhu. Men rådgivningen på minSundhed.com er beregnet som information og skal modtages på
								eget ansvar. Med
								dette menes, at minSundhed.com ikke kan drages til ansvar, hverken direkte eller
								indirekte, for nogen skader
								eller gener, der måtte være konsekvensen af brugen eller misbrugen af den rådgivning og
								information der er
								indeholdt på hjemmesiden. Det etiske og moralske ansvar i den afgivne rådgivning på
								minSundhed.com påhviler
								den enkelte behandler/ekspert. Eventuelle klager eller indsigelser skal derfor rettes
								mod den rådgivende
								behandler og ikke minSundhed.com.</p>
							<p>MinSundhed ApS kan under ingen omstændigheder drages til ansvar for indirekte skade eller
								tab.
								Ansvarsfraskrivelsen for indirekte skade og tab gælder uanset arten af erstatningsansvar
								og uanset, om
								minSundhed ApS har handlet simpelt uagtsomt.</p>

							<p>minSundhed.com har ingen økonomiske interesser, andele, aktier og lign. i de kosttilskud,
								produkter, butikker
								m.m., som anbefales på hjemmesiden.</p>

							<p><b>Teknik</b><br />
								minSundhed ApS påtager sig ikke ansvar for tab, der måtte opstå som følge af tekniske
								fejl, problemer med
								opkobling til internettet, behandling af data i forbindelse med betaling eller tab
								påført på anden måde.</p>

							<p><b>Kontaktoplysninger</b><br />
								Spørgsmål vedrørende behandlerprofil og brugerprofil på minHolistiskeBehandler.dk og
								minSundhed.com kan rettes
								til:<br />
								kontakt@minsundhed.com eller tlf. 23 74 14 37 i dagstimerne mellem kl. 8.30-14.30.</p>

							<p>Nye brugerbetingelser vil blive notificeret alle behandlerprofiler ultimo 2022, blive
								lagt op på
								minHolistiskeBehandler.dk og træde i kraft pr 2023. Indtil da gælder dette dokument. Ved
								overgangen til de nye
								brugerbetingelser skal behandleren aktivt afgive sit samtykke til disse. </p>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="closeWindow">OK</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style lang="scss" scoped>
.codeofconduct {
	background-color: #1692B6;
	padding: 20px;
	border-radius: 10px;
	color: white;
}
.page {
	padding: 40px 40px;
	
	ul {
		padding-left: 40px !important;
		font-family: Raleway !important;
		line-height: 1.8em;
	}
	ol {
		padding-left: 0px !important;
		font-family: Raleway !important;
	}

	@media (max-width: 568px) {
		font-size:14px;
	}
}
.modal-dialog,
.modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	height: 70vh;
	overflow-y: auto;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}


</style>
<script>
export default {
	data() {
		return {
			showModal: false,
			toShow: 1,	//1 == codeofconduct, 2 == brugerterms
		}
	},
	methods: {
		closeWindow() {
			this.showModal = false;
		},
		show(index) {
			this.toShow = index;
			this.showModal = true;
		},
	}
}
</script>