<template>
    <div>
        <section class="therapists ">
            <h1 class="intro txt-theme">Vi gør det nemt for dig at finde og booke   helhedsorienterede behandlere
            </h1>
            <div class="container mt-5">
                <div class="row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-4" v-for="(item, index) in therapists" :key="index">
                                <div class="content-box my-4">
                                    <div class="title txt-theme">
                                        {{ item.title }}
                                    </div>
                                    <div class="description txt-desc">
                                        {{ item.desc }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>


<script>
export default {
    components: {},
    data() {
        return {
            txt: 'dsfdfd',
            therapists: [
                {
                    title: 'Holistisk sundhed',
                    desc: 'Alle vores behandlere arbejder i større eller mindre grad med en helhedsorienteret tilgang til sundhed og behandling. Det betyder bl.a en respekt for og erkendelse af, at vi ikke bare er et stykke mekanik, og at vi er unikke individer der kan påvirkes af mange forskellige faktorer.',
                },
                {
                    title: 'Din tryghed',
                    desc: 'Alle behandlerprofiler på minHolistiskeBehandler.dk kan anmeldes af brugerne. Det giver dig en unik indsigt i andres oplevelse med den specifikke behandler og kan gøre dit valg nemmere. Vi vil være din “trustpilot”, når du søger helhedsorienteret behandling.',
                },
                {
                    title: 'Tæt på dig',
                    desc: 'Søg og find en behandler i nærheden af dig. Du vælger selv om det skal være i nærheden af dit arbejde eller ved din privat adresse. Du kan nemlig søge og finde ledige tider på vores helhedsorienterede behandlere ud fra by og postnummer.',
                },
                {
                    title: 'Find rette behandler',
                    desc: 'Måske du er i tvivl om hvem der kan hjælpe dig. Søg via dine symptomer eller skriv til eksperterne i Ekspertforummet på minSundhed.com og få deres bud på hvilken behandling der kan hjælpe på din problematik.',
                },
                {
                    title: 'Når det passer dig',
                    desc: 'Har du lidt ekstra tid i frokostpausen eller ved du, at der er et ledigt øjeblik i familiekalenderen torsdag eftermiddag, så kan du søge efter ledige behandlere i netop dette tidsrum. Altid let at finde og booke ledige tider, når det passer dig!OBS denne feature er ikke tilgængelig sept+okt. hvor vi arbejder på kalendersystemet',
                },
                {
                    title: 'Grønne rabatter',
                    desc: 'Med en ganske gratis brugerprofil på minHolistiskeBehandler får du adgang til at nyde løs af skønne rabatter hos minSundhed.coms mange grønne rabatpartnere. Spar penge på lækre økologiske produkter, smukke bæredygtige ting til husholdningen, bøger og meget andet!',
                },
            ]
        }
    }
}
</script>

<style lang="scss">
.therapists {
    padding: 90px 50px;
    background: #ECF3F5;

    @media(max-width:767px) {
        padding: 75px 20px;
    }

    .intro {
        font-size: 38px;
        font-family: 'Raleway-Light';

        @media(max-width:767px) {
            font-size: 28px;
        }
    }

    .content-box {
        .title {
            font-size: 22px;
            margin-bottom: 12px;
            font-family: 'Raleway-Regular';
        }

        .description {
            font-size: 17px;
            line-height: 1.7;
            font-family: 'Raleway-Light';
        }
    }
}
</style>