<template>
	<div>
		<generic-info-edit @refreshOpeningHours="fetchItems" ref="genericInfo" />
		<ul class="list-group list-group-flush">
			<li class="list-group-item">
				<h4>{{ practitionerName() }} </h4>
			</li>
			<li class="list-group-item">
				<h6 class="address">
					<edit-icon @click="editGenericInfo" />Klinik adresse:<span v-html="practitionerFormattedAddress" />
				</h6>
				<div v-html="formattedInfo()"></div>
				<div v-if="openDays.length > 0 && practitioner.showOpeningHours">
					<h6 class="hrs">Åbningstider:</h6>
					<div>
						<div v-for="(openDay) in this.openDays" :key="openDay.day">
							{{ days[openDay.day].day }}: {{ openDay.opens }}-{{openDay.closes}}<br />
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>
<style type="text/css">
.companyname {
	font-size: 110% !important;
	font-weight: 600 !important;
	color: #999;
	margin: 0px !important;
}
</style>
<script>
import { mapState } from 'vuex'
import { mapGetters } from "vuex";
import GenericInfoEdit from './GenericInfoEdit.vue'
import axios from 'axios'
import EditIcon from './EditIcon.vue'

export default ({
	components: { GenericInfoEdit, EditIcon },
	data() {
		return {
			openDays: [],
			days: [
				{ day: 'Søndag', index: 0 },
				{ day: 'Mandag', index: 1 },
				{ day: 'Tirsdag', index: 2 },
				{ day: 'Onsdag', index: 3 },
				{ day: 'Torsdag', index: 4 },
				{ day: 'Fredag', index: 5 },
				{ day: 'Lørdag', index: 6 },
			]
		}
	},
	computed: {
		...mapGetters(['isLoggedIn', 'user', 'practitioner']),
		...mapState(['practitionerId']),
		practitionerFormattedAddress() {
			if (this.practitioner.address == null || this.practitioner.address == '')
				return "Ingen adresse oplyst";
			let address = "";
			if (this.practitioner.companyName)
				address += `<span class='companyname'>${this.practitioner.companyName}</span>`;
			//address += this.practitioner.name;
			if (this.practitioner.onlyShowCity) {
				if (this.practitioner.zip != null) {
					address += "<br/>" + this.practitioner.zip + ' ' + this.practitioner.city;
				}
			}
			else {
				address += "<br/>" + this.practitioner.address;
				address += "<br/>" + this.practitioner.zip + ' ' + this.practitioner.city;
			}
			return address;
		}
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		editGenericInfo() {
			this.$refs.genericInfo.show();
		},
		practitionerName() {
			return this.practitioner.name;
		},
		formattedInfoHelperWeb(classname, text, val) {
			return val == null || val == "" ? '' : `<h6 class='${classname}'>${text}: <span><a href='${val}' target='_blank'>${val}</a></span></h6>`;
		},
		formattedInfoHelperMail(classname, text, val) {
			return val == null || val == "" ? '' : `<h6 class='${classname}'>${text}: <span><a href='mailto:${val}' target='_blank'>${val}</a></span></h6>`;
		},
		formattedInfoHelper(classname, text, val) {
			return val == null || val == "" ? '' : `<h6 class='${classname}'>${text}: <span>${val}</span></h6>`;
		},
		formattedInfo() {
			let res = this.formattedInfoHelper("tlf", "Tlf.", this.practitioner.phone);
			res += this.formattedInfoHelperMail("mail", "Email", this.practitioner.publicEmail);
			res += this.formattedInfoHelperWeb("web", "Website", this.practitioner.website);
			return res;
		},
		fetchItems() {
			//debugger; // eslint-disable-line no-debugger
			if (this.practitioner.id == undefined) return;
			axios
				.get(`/practitioner/${this.practitioner.id}/opendays`,)
				.then(response => {
					this.openDays = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
		checkStateOfPage() {
			if (!this.isLoggedIn)
				return;
			if (this.practitioner.createStateId == 5)
				this.$toast.warning("Bemærk: Behandlerprofil kan ikke fremsøges og er ikke aktiv");
		}
	},
	created() {
		this.fetchItems();
		this.checkStateOfPage();
	}
})
</script>
