<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Anmeld din behandler ❤️</h5>
							<b-button type="button" @click="closeWindow" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<form ref="form">
								Vælg antal stjerner (1-5) og skriv en anmeldelse<br/>
								<star-rating v-model="votingObject.rating" :star-size="30" :show-rating="false" :rounded-corners="true" style="margin-top:10px"></star-rating>
								<div class="form-group">
									<label for="comment" class="col-sm-3 col-form-label">Anmeldelse</label>
									<b-form-textarea v-model="votingObject.comment" rows="6" id="reviewComment"/>
								</div>
								<div class="form-group" v-if="!isLoggedIn">
									<div>
										<label for="email" class="col-sm-3 col-form-label">Email</label>
										<b-form-input type="text" id="email" v-model="votingObject.email" />
									</div>
									<div class="noemail">
										Da du ikke er logget ind på minSundhed.com så skal du verificere din anmeldelse. Hold øje med 
										din spammappe når du indsender den.
									</div>
								</div>
							</form>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="doLogin">Indsend</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style type="text/css">
span.ishidden {color:white}
</style>
<style scoped>
div.noemail {
	font-size:80%;
	margin-top:10px;padding:15px;border:1px solid #f1c501;background-color: #faf1c9;
	border-radius: 5px;
}
div.logininfo {
	color: #999;
	font-size: 80%;
	padding-bottom: 10px
}

div.forgot {
	color: #999;
	font-size: 80%;
	padding-bottom: 10px;
	margin: 10px 0px 0px 0px
}
</style>
<script>
import StarRating from 'vue-star-rating'
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
	components: {
		StarRating
	},
	data() {
		return {
			showModal: false,
			votingObject : {
				practitionerId: this.practitionerId,
				rating: 0,
				comment : '',
				email: '',
				userId: ''
			}
		}
	},
	computed: {
		...mapGetters(["isLoggedIn", "practitionerId", "userId"])
	},
	methods: {
		closeWindow() {
			this.votingObject.email = '';
			this.votingObject.comment = '';
			this.votingObject.rating = 0;
			this.showModal = false;
		},
		doLogin() {
			if(this.isLoggedIn)
				this.votingObject.userId = this.userId.toString();
			this.votingObject.practitionerId = this.practitionerId;
			if(this.votingObject.userId == null || this.votingObject.userId == "" && this.votingObject.email == "")
			{
				alert("Du skal indtaste en email");
				return;
			}
			if(this.votingObject.rating == 0)
			{
				alert("Du mangler at angive en bedømmelse");
				return;
			}
			if(this.votingObject.comment == "")
			{
				alert("Du mangler at skrive en anmeldelse");
				return;
			}
			if(this.votingObject.comment.length < 10)
			{
				alert("Din anmeldelse er altså for kort!");
				return;
			}
			axios.post(`/voting/vote`, this.votingObject)
			.then(res => {
				if(res == null)
				{
					this.$toast.open({ message: "Noget gik galt :()", position: 'top', type: 'warning', duration: 3500, dismissible : true });
					return;
				}
				this.closeWindow();
				this.$toast.open({ message: "Tak ❤️", position: 'top', type: 'success', duration: 3500, dismissible : true });
			})
			.catch(error => {
				console.log(error);
				this.$toast.open({ message: "Noget gik galt :()", position: 'top', type: 'warning', duration: 3500, dismissible : true });
			});
		},
		show() {
			this.showModal = true;
		},

	},
}

</script>