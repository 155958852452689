<template>
	<div>
		<div class="text-muted sectioninfo">Vælg/opret dine professioner</div>
		<form-group :validator="$v.multiselectValue" id="input-group-1"
			label="Tilføj profession. Findes den ikke i listen, så indtast en ny og vælg 'Klik for at tilføje'"
			label-for="multiselects"
			description="Vælg en eksisterende profession eller indtast en ny og den vil blive oprettet">
			<b-container fluid>
				<b-row>
					<b-col cols="9" style="padding-left:0px">
						<multiselect :taggable="true" :options="skills" tag-placeholder="Klik for at tilføje"
							deselectLabel="Klik for at fjerne valget" selectLabel="Klik for at vælge"
							selectedLabel="Valgt" v-model="multiselectValue" track-by="id" label="title"
							@tag="addTag" />
					</b-col>
					<b-col style="padding-left:0px">
						<b-button @click="saveSkill" type="button" variant="success" style="">OK</b-button>
					</b-col>
				</b-row>
			</b-container>
		</form-group>
		<br />
		<b-table-simple class="tight" small responsive>
			<b-thead>
				<b-th>Profession (træk-og-slip for at ændre rækkefølge)</b-th>
				<b-th></b-th>
			</b-thead>
			<b-tbody>
				<draggable ref="draggable" :list="practitionerSkills" style="cursor:grab" @change="dragged">
					<b-tr v-for="(skill) in practitionerSkills" :key="skill.id">
						<b-td>{{ skill.title }}</b-td>
						<b-td cols="2" style="text-align:right">
							<b-icon icon="trash-fill" @click="deleteSkill(skill.practitionerSkillId)" />
						</b-td>
					</b-tr>
				</draggable>
			</b-tbody>
		</b-table-simple>
		<input style="display: none;" type="file" ref="fileInput" @change="onFileSelected" />
	</div>
</template>
<style scoped>
div.tight table tbody {
	border-top: 1px solid #eee
}

div.tight table tbody tr {
	border:0px;
	border-bottom:1px solid #ddd;
}

div.tight table th {
	color: #aaa;
	font-weight: normal;
	font-size: 80%;
}

div.tight table td {
	font-size: 80%;
	width:100%;
}

div.text-muted {
	color: black !important;
}

a.verysmalltight {
	font-size: 12px;
	text-decoration: underline;
}

.overflowy {
	max-height: 200px;
	overflow-y: scroll;
}
</style>
<script>
import axios from 'axios';
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { required } from 'vuelidate/lib/validators'

export default ({
	components: { Multiselect, draggable },
	data() {
		return {
			editEntry: false,
			newId: -1,
			multiselectValue: null,
			skills: [],
			practitionerSkills: [],
		}
	},
	validations: {
		multiselectValue: { required },
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	methods: {
		dragged(e) {
			let newIndex = e.moved.newIndex;
			let id1 = e.moved.element.id;
			axios
				.post(`/practitioner/skills/${this.practitionerId}/moveup?id=${id1}&newIndex=${newIndex}`)
				.catch(error =>
					console.log(error)
				)
				.finally(() => {
					this.multiselectValue = null;
					this.fetchItems()
				}
				);
		},
		addTag(newTag) {
			const pt = {
				id: this.newId--,
				title: newTag
			}
			this.skills.push(pt);
			this.multiselectValue = pt;
			this.saveSkill();
		},
		uploadClicked(skillId) {
			this.clickedSkillId = skillId;
			this.$refs.fileInput.click();
		},
		onFileSelected(event) {
			let skillId = this.clickedSkillId;
			console.log("name: " + event.target.files[0].name);
			console.log("skillid: " + skillId);
			const fd = new FormData();
			fd.append("practitionerId", this.practitionerId);
			fd.append("skillid", skillId);
			fd.append("file", event.target.files[0]);
			axios
				.post(`/practitioner/skills/savedocument`, fd)
				.then(res => {
					console.log("done: " + res)
				})
				.finally(() => {
					this.fetchItems()
				});
		},
		saveSkill() {
			if (this.multiselectValue == null || this.multiselectValue.title === "") {
				alert("Du skal indtaste en titel først");
				return;
			}
			//e.preventDefault();
			//this.$v.$touch();
			axios
				.post('/practitioner/skills/add?skillName=' + this.multiselectValue.title + `&practitionerId=${this.practitionerId}`)
				.catch(error =>
					console.log(error)
				)
				.finally(() => {
					this.multiselectValue = null;
					this.fetchItems()
				}
				);
		},
		deleteSkill(id) {
			axios
				.delete('/practitioner/skills/delete?skillid=' + id + `&practitionerId=${this.practitionerId}`)
				.then(() => {
					this.skills = null;
					this.multiselectValue = null;
					this.fetchItems();
				})
				.catch(error =>
					console.log(error)
				);
		},
		fetchItems() {
			axios
				.get('/practitioner/skills/all')
				.then(response => {
					this.skills = response.data;
				})
				.catch(error =>
					console.log(error)
				);
			axios
				.get(`/practitioner/skills/${this.practitionerId}`)
				.then(response => {
					this.practitionerSkills = response.data;
				})
				.catch(error =>
					console.log(error)
				)
		},
	},
	created() {
		this.fetchItems();
	}
})
</script>
