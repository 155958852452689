<template>
	<div>
		<router-link v-if="showingAllBlogItems" :to="{ path: `/behandler/${practitionerId}` }"
			class="backContainer back">
			<b-icon icon="arrow-left-circle-fill" style="color: #fff;"></b-icon>
			Tilbage
		</router-link>
		<div id="blog_post" v-show="isPractitionerPageOwner || contentPosts.length > 0">
			<h5>
				<edit-icon @click="openEditItems" /><span>Mine artikler</span> / mine blog posts
			</h5>
			<div class="row">
				<div v-for="contentPost in contentPosts" :key="contentPost.id" class="col-xl-4 col-sm-12">
					<div class="image drop_shadow thumbcontainer">
						<router-link
							v-bind:to="{ path: `/behandler/${contentPost.practitionerId}/blog/${contentPost.id}` }"><img
								class="objectfit" v-if="contentPost.filename != null"
								v-bind:src="getImageUrl(contentPost)" alt="blog" /></router-link>
					</div>
					<p class="date">{{ formattedDate(contentPost.releaseDate) }}</p>
					<h5 class="blog_title">
						<router-link
							v-bind:to="{ path: `/behandler/${contentPost.practitionerId}/blog/${contentPost.id}` }">{{
									contentPost.headline
							}}</router-link>
					</h5>
					<p class="blog_text">{{ contentPost.teaser }}</p>
				</div>
			</div>
			<router-link v-if="!showingAllBlogItems && this.totalItems > 3"
				:to="{ path: '/behandler/' + practitionerId + '/blogs' }" class="backContainer back">
				&nbsp;Se flere
			</router-link>
		</div>
		<blog-items-edit ref="blogItemsEdit" itemType="blogs" title="Artikler / Blogindlæg" @refresh="refreshItems" />
	</div>
</template>
<style scoped>
.objectfit {
	object-fit: cover;
	overflow: hidden;
}

.backContainer {
	display: inline-block;
	vertical-align: middle;
}

@media (min-width: 1199px) {
	.thumbcontainer {
		width: 100%;
		height: 200px;
	}

	.thumbcontainer .objectfit {
		width: 100%;
		height: 100%;
	}
}
</style>
<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import BlogItemsEdit from './BlogItemsEdit.vue';
import EditIcon from "./EditIcon.vue";

export default {
	components: { BlogItemsEdit, EditIcon },
	props: ['itemType'],
	data() {
		return {
			contentPosts: [],
			appendRandomParam: false,
			totalItems : 0,
		};
	},
	computed: {
		...mapState(["practitionerId"]),
		...mapGetters(['isPractitioner', 'isPractitionerPageOwner']),
		showingAllBlogItems() {
			return this.$route.path.indexOf('blogs') > 0;
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		refreshItems() {
			this.appendRandomParam = true;
			this.fetchItems();
		},
		getImageUrl(contentPost) {
			let appendRndString = this.rndString != "" ? "?r=" + this.rndString : "";
			return `/behandlerportal/images/bh/${contentPost.filename}`;
		},
		openEditItems() {
			this.$refs.blogItemsEdit.show();
		},
		formattedDate(d) {
			return d;
		},
		fetchItems() {
			let cnt = this.showingAllBlogItems ? 1000 : 3;
			axios
				.get(
					
					`/practitioner/blogs/${this.practitionerId}/${this.itemType}?itemCount=${cnt}`
				)
				.then((response) => {
					this.contentPosts = response.data;
				})
				.catch((error) => console.log(error));
			axios
				.get(
					
					`/practitioner/blogs/${this.practitionerId}/${this.itemType}/count`
				)
				.then((response) => {
					this.totalItems = response.data;
				})
				.catch((error) => console.log(error));
		},
	},
	created() {
		this.fetchItems();
	}
};
</script>
