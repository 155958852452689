<template>
	<transition name="fade" v-if="showModal" class="topMost">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Login som behandler</h5>
							<b-button type="button" @click="closeWindow" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<vue-element-loading :active="loading" spinner="bar-fade-scale" color="#FF6700" />
							<form ref="form" class="needs-validation loginform" novalidate>
								<div class="row">
									<form-group :validator="$v.email" label="Indtast emailadresse">
										<b-form-input type="text" @blur="$v.email.$touch()" v-model="email" />
									</form-group>
								</div>
								<div class="row">
									<form-group id="fieldset-1" description="" label="Indtast password"
										label-for="password" :validator="$v.password">
										<b-form-input id="password" type="password" @blur="$v.password.$touch()"
											v-model="password" trim></b-form-input>
									</form-group>
								</div>

								<div class="forgot">Har du glemt dit password? <a
										href="https://minsundhed.com/forgot-password">Klik her</a></div>
								<div class="forgot"><a ref="#" style="pointer:cursor"
										@click="closeWindowGotoSignUp();">Er du ikke oprettet som behandler? Klik
										her!</a></div>
								<div v-if="invalidLogin" class="invalid">Ukendt email/password kombination</div>
							</form>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary" @click="doLogin">
								Log ind
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style type="text/css">
.loginform label {
	font-size: 14px !important
}
</style>
<style scoped>
.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.topMost {
	z-index: 200;
	;
}

div.invalid {
	border: 1px solid #f1c501;
	font-size: 80%;
	background-color: #ffe77a;
	padding: 5px
}

div.logininfo {
	color: #999;
	font-size: 80%;
	padding-bottom: 10px
}

div.forgot {
	color: #999;
	font-size: 80%;
	padding-bottom: 10px;
	margin: 10px 0px 0px 0px
}

div.forgot a {
	font-size: 14px;
}
</style>
<script>
import axios from 'axios';
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import { required } from 'vuelidate/lib/validators'
import VueElementLoading from 'vue-element-loading'

export default {
	components: {
		VueElementLoading
	},
	data() {
		return {
			email: "",
			password: "",
			showModal: false,
			invalidLogin: false,
			loading: false,
		}
	},
	computed: {
		...mapGetters(["isLoggedIn", "practitionerId"]),
	},
	validations: {
		email: { required },
		password: { required }
	},
	methods: {
		...mapMutations(["setUser", "setToken"]),
		closeWindowGotoSignUp() {
			this.closeWindow();
			this.$router.push({ name: 'CreatePractitionerWelcomePage' });
		},
		closeWindow() {
			this.email = '';
			this.password = '';
			this.showModal = false;
			this.invalidLogin = false;
		},
		doLogin() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			this.loading = true;

			axios.post(`/users/authenticate2`, JSON.stringify({
				username: this.email,
				password: this.password
			}), {
				headers: {
					"Content-type": "application/json",
				}
			}).then(res => {
				if (res == null) {
					this.invalidLogin = true;
					return;
				}
				const { userId, firstName, lastName, access_token, practitionerId, roles } = res.data;
				this.setUser({ id: userId, firstName: firstName, lastName: lastName, practitionerId: practitionerId, roles: roles });
				this.setToken(access_token);
				this.$store.dispatch('setPractitionerServices');
				
				this.closeWindow();
				this.$router.push(`/behandler/${practitionerId}`).catch(()=>{});
				this.$toast.open({ message: "Du er logget ind", position: 'top', type: 'success', duration: 3500, dismissible: true });
			}).catch(error => {
				this.invalidLogin = true;
				console.log(error);
			}).finally(() => {
				this.loading = false;
			});
		},
		show() {
			this.showModal = true;
		},
	},
}

</script>