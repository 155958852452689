import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

Vue.use(Vuex)
//export default new Vuex.Store({
const store = new Vuex.Store({
	state: {
		practitionerId: -1,
		practitioner: {},
		entryid: -1,
		user: null,
		token: null,
		currentStep: 0,
		mapVisible: false,
		searchValue: '',
		tipVersion: 1,
		openingHoursUpdated: false,
	},
	getters: {
		practitionerId(state) {
			return state.practitionerId;
		},
		practitioner(state) {
			return state.practitioner;
		},
		isPractitioner(state) {
			return state.user?.practitionerId > 0;
		},
		isPractitionerPageOwner(state) {
			return state.user?.roles.indexOf("Admin") > 0 || (state.user?.practitionerId > 0 && state.practitionerId === state.user.practitionerId);
		},
		practitionerName(state) {
			return state.practitioner.name;
		},
		entryid(state) {
			return state.entryid;
		},
		isLoggedIn(state) {
			return state.token != null;
		},
		mapVisible(state) {
			return state.mapVisible;
		},
		userId(state) {
			return state.user?.id;
		},
		isAdmin(state) {
			return state.user?.roles.indexOf("Admin") > 0;
		},
		searchValue(state) {
			return state.searchValue;
		},
		tipVersion(state) {
			return state.tipVersion;
		},
		calendarEnabled(state) {
			if (state.practitioner.id == null) return false;
			//debugger // eslint-disable-line no-debugger
			const calendarService = state.practitioner.services?.find(service => service.name == "Calendar");
			return calendarService == null ? false : calendarService.active == 1;
		},
		userHasCalendar(state) {
			if (state.user == null || state.user.id == null) return false;
			//debugger // eslint-disable-line no-debugger
			const calendarService = state.user.services?.find(service => service.name == "Calendar");
			return calendarService == null ? false : calendarService.active == 1;
		}
	},
	mutations: {
		practitionerId(state, practitionerId) {
			state.practitionerId = practitionerId;
		},
		practitioner(state, practitioner) {
			state.practitioner = practitioner;
		},
		entryid(state, entryid) {
			state.entryid = entryid;
		},
		mapVisible(state, mapVisible) {
			state.mapVisible = mapVisible;
		},
		doSearch(state, searchValue) {
			state.searchValue = searchValue;
		},
		setUser(state, user) {
			state.user = user;
			VueCookies.set('user', user);
		},
		setToken(state, token) {
			// console.log("store::setToken() " + token);
			state.token = token;
			VueCookies.set('token', token);
			axios.defaults.headers.common['Authorization'] = "Bearer " + token;
		},
		openingHoursUpdated(state, value) {
			state.openingHoursUpdated = value;
		}
	},
	actions: {
		logout() {
			this.state.token = null;
			this.state.user = null;
			VueCookies.remove('token');
			VueCookies.remove('user');
			axios.defaults.headers.common['Authorization'] = null;
			this.state.openingHoursUpdated = true;
		},
		setPractitionerServices({ dispatch, state }) {
			dispatch('getPractitioner', state.practitionerId);
			state.user.services = state.practitioner.services;
			VueCookies.set('user', state.user);	//TODO: This is not great
			this.state.openingHoursUpdated = true;
		},
		updatePractitionerCalendarState({ state }, newValue) {
			//debugger; // eslint-disable-line no-debugger
			let calendarService = state.practitioner.services?.find(service => service.name == "Calendar");
			if (calendarService != null) {
				calendarService.active = newValue;
			}
		},
		getPractitioner({ commit }, urlOrIdObj) {
			//debugger // eslint-disable-line no-debugger
			console.log("urlOrIdObj", urlOrIdObj);
			if (!urlOrIdObj.idOrSlug) return;
			axios
				.get(`/practitioner/entity/slug/${urlOrIdObj.idOrSlug}`)
				.then(response => {
					if (response.status == 200) {
						commit('practitionerId', response.data.id);
						commit('practitioner', response.data);
					}
				})
				.catch(() => {
					this.$router.push("/");
				});
		},
	}
})

export default store