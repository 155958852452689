<template>
	<div>
		<a name="image" id="image">&nbsp;</a>
			<div class="backContainer" @click="$router.push({ path: '/behandler/' + practitionerId })">
			<b-link class="back"><b-icon icon="arrow-left-circle-fill" style="color: #fff;"></b-icon> Tilbage</b-link>		
		</div>
		<b-img class="previewImg" ref="previewImg" id="previewImage" v-bind:src="getImageUrl(itemId)" />
		<div class="description">
			{{ items.length > 0 ? items[0].description : "" }}
		</div>
		<div>
			<gallery-view :showTitle="false" :itemsToShow="24" />
		</div>
	</div>
</template>
<style scoped>
.description {
	padding: 10px;
}

.gallery_img {
	float: left;
	width: 100%;
	height: 140px;
	display: block;
	border: 1px solid #aaa;
	border-radius: 2px;
	margin-bottom: 6px;
}

img.previewImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 2px;
	border: 1px solid #aaa;
}
</style>
<script>
import axios from "axios";
import { mapState } from "vuex";
import GalleryView from './GalleryView.vue';
export default {
	components: { GalleryView },
	computed: mapState(["practitionerId"]),
	data() {
		return {
			items: [],
			itemId: -1,
		}
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		getImageUrl(id) {
			return id < 0 ? `/behandlerportal/images/blank.gif` : `/behandlerportal/images/bh/${this.practitionerId}_images_${id}.jpg`;
		},
		fetchItems() {
			//debugger // eslint-disable-line no-debugger
			console.log(`${this.$options._componentTag}-> fetch:${this.practitionerId}`);
			if (this.practitionerId < 0) return;
			axios
				.get(
					
					`/practitioner/gallery/${this.practitionerId}?entryid=${this.itemId}`
				)
				.then((response) => {
					this.items = response.data;
				})
				.catch((error) => console.log(error));
		},
	},
	created() {
		this.itemId = this.$route.params.entryid;
		this.fetchItems();
	}
}
</script>

