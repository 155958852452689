<template>
	<transition name="fade" v-if="showModal" appear>
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Profil: Baggrundsbillede</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<center>
								<b-button variant="primary">
									<clipper-upload v-model="imgURL">Upload billede</clipper-upload>
								</b-button>
								<clipper-basic class="my-clipper" ref="clipper" :src="imgURL">
									<div class="placeholder" slot="placeholder">
										<img class="previewImg" :src="imageUrl" @error="fallbackNoImage">
									</div>
								</clipper-basic>
								NB: Benyt helst et så stort billede som muligt - særligt ved små beskæringer!
							</center>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="rotate90">Rotér</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style scoped>
img.previewImg {
	width: 100%;
	max-width: 300px;
	max-height: 300px;
}

.my-clipper {
	width: 100%;
	max-width: 300px;
	max-height: 300px;
	margin: 10px;
}

.placeholder {
	text-align: center;
	margin: 10px !important;
	padding: 20px !important;
	background-color: white;
}

.modal-dialog,
.modal-content {
	min-height: 400px;
	max-height: 530px;
	max-width: 450px;
	/*height: calc(100% - 40px);*/
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	height: 580px;
	overflow-y: auto;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	name: "EditBgPhoto",
	computed: {
		...mapGetters(["practitioner"]),
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	data() {
		return {
			showModal: false,
			imgURL: '',
			resultURL: '',
			imageUrl: '',
			rotate : 0,
		}
	},
	methods: {
		rotate90() {
			this.rotate += 90;
			this.$refs.clipper.rotate = this.rotate;
			if(this.rotate > 90*3) this.rotate = 0;
		},
		fallbackNoImage() {
			console.log("Error loading image");
			this.imageUrl = '/behandlerportal/images/bh/no_profileimg.gif';
		},
		saveForm() {
			//	debugger; // eslint-disable-line no-debugger
			const canvas = this.$refs.clipper.clip();//call component's clip method
			this.resultURL = canvas.toDataURL("image/jpeg", 1);//canvas->image

			axios.post(`/practitioner/${this.practitionerId}/profilebgpicture`,
				JSON.stringify(this.resultURL),
				{
					headers: {
						'Content-type': 'application/json',
					}
				})
				.then(() => {
					this.showModal = false;
					this.$emit("refresh");

				})
				.catch(error =>
					console.log(error)
				);
		},
		show() {
			this.showModal = true;
			this.imageUrl = `/behandlerportal/images/bh/${this.practitioner.bgImage}`;
			console.log(this.practitioner.bgImage);
		},
	},
	mounted() {
		//this.showModal = true;
	}
}
</script>