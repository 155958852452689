<template>
	<section class="banner_profile">
		<edit-photo ref="editPhoto" @refresh="reloadEntity" />
		<edit-bg-photo ref="editBgPhoto" @refresh="reloadEntity" />
		<b-img :src="practitionerBgImage" @error="practitionerBgImage = '/behandlerportal/images/profile_bg.jpg'" class="banner_img" />
		<edit-icon title="Ændre baggrundsbillede" className="pencilbg" @click="$refs.editBgPhoto.show()" style="float:right" />
		<div class="container">
			<div class="row">
				<div class="col-xl-4 col-lg-5 col-sm-12">
					<div class="avatar_main" style="position:relative">
						<div class="drop_shadow" style="">
							<div class="avatar" style="cursor:pointer" @click="$router.push(`/behandler/${practitionerId}`).catch(()=>{})">
								<img :src="practitionerImage"
								@error="practitionerImage = '/behandlerportal/images/bh/no_profileimg.gif'"
								alt="avatar" style="object-fit: cover;width:100%;height:100%">
							</div>
						</div>
						<edit-icon className="pencilbg2" title="Klik for at ændre billede" @click="$refs.editPhoto.show()" />
					</div>
				</div>
				<div class="col-xl-8 col-lg-7 col-sm-12 right-box">
					<div class="user_details">
						<h2 class="user_name">
							<edit-icon @click="editGenericTitle" :has-items="practitionerName!=''" title="Klik for at ændre titel og titler mv."
							noentries="Klik for at ændre behandlernavn og titler mv" />{{ practitionerName }}
						</h2>
						<h3 class="user_tagline">{{ practitionerTagline }}</h3>
						<practitioner-skills-view ref="practitionerSkillsView" />
						<div class="row">
							<div class="col-lg-6 address_review">
								<h6 style="margin-right:10px">{{ practitionerCity }}</h6>
								<PractitionerRating></PractitionerRating>
							</div>
							<!-- <div class="col-lg-6 text-end">
								<button class="btn btn-outline-primary">
									<img src="/behandlerportal/images/heart.png" alt="heart"><span>Gem profil</span>
								</button>
								<button class="btn btn-outline-primary">
									<img src="/behandlerportal/images/share.png" alt="share"><span>Delprofil</span>
								</button>
							</div> -->
						</div>
					</div>
					<keywords-view /><br/>
				</div>
			</div>
		</div>
		<generic-info-title-edit ref="genericInfoTitleEdit" @refreshPractitioner="refreshPractitionerEvent" />
	</section>
</template>
<style lang="scss">
.pencilbg2 {
	display: inline !important;
	width: 20px;
	height: 20px;
	cursor: pointer !important;
	position:relative;
	/* left: 20% !important; */

	/* -webkit-transform: translate(-20%, -20%);
	-moz-transform: translate(-20%, -20%); */
	transform: translate(180px, -40%);
	z-index: 49;
	background-color: white;
	color: black;
	border-radius: 4px;

	@media only screen and (max-width:991px){
		transform: translate(0px, 150px);
	}

	@media only screen and (min-width:1600px){
		transform: translate(-200px, 280px) !important;
	}

}

</style>
<style scoped>
/*====================== banner profile start =======================*/
.banner_profile{
	
	width: 100%;
	display: block;
}

.banner_profile .banner_img{
	width: 100%;
	/* max-width: 1600px; */
	max-height: 300px;
	object-fit: cover;
	display: table;
	margin: auto;
}

.banner_profile .mob_banner{
	display: none;
}

.banner_profile .drop_shadow{
	float: left;
	width: 100%;
	max-width: 396px;
	border-radius: 50%;
	margin-top: -110px;
	position: relative;
}

.banner_profile .drop_shadow::after{
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: #fff;
	filter: drop-shadow(0 8px 8px  #26173b);
	opacity: 0.08;
}

.banner_profile .avatar_main{
	width: 100%;
	display: block;
}

.banner_profile .avatar{
	max-width: 396px;
	background-color: #f8f9f9;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	display: block;
}

.banner_profile .avatar::after{
	content: "";
	position: relative;
	display: block;
	padding-top: 100%;
	border-radius: 50%;
	filter: drop-shadow(0px 9px 10px #156cb1);
	background: #fff;
	opacity: 0.1;
	z-index: 1;
}

.banner_profile .avatar::before{
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	border: 13px solid #fff;
	border-radius: 50%;
	z-index: 3;
}

.banner_profile .avatar img{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	max-width: 100%;
}

.banner_profile .user_name{
	font-size: 40px;
	margin-top: 30px;
	margin-bottom: 0px;
}

.banner_profile .user_tagline{
	font-size: 30px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.banner_profile .user_name span{
	font-family: Raleway;
	font-weight: 200;
}

.banner_profile .address_review{
	display: flex;
	align-items: center;
}

.banner_profile .address_review h6{
	font-family: Raleway;
	font-weight: 400;
	margin-bottom: 5px;
	margin-top:15px;
}


.banner_profile .btn-group-dark{
	margin-top: 25px;
}


.banner_profile .address_review .review_star{
	margin-left: 33px;
} 

/*====================== banner profile end =======================*/
.pencilbg {
	border: 0px solid red;
	display: inline !important;
	width: 20px;
	height: 20px;
	cursor: pointer !important;
	left: 100% !important;

	/* -webkit-transform: translate(-20%, -20%);
	-moz-transform: translate(-20%, -20%); */
	transform: translate(40%, 40%);
	z-index: 49;
	background-color: white;
	color: black;
	border-radius: 4px;
}

@media only screen and (max-width:991px){
.banner_profile .mob_banner{
		width: 100%;
		display: block;
		height: 234px;
		object-fit: cover;
	}

	.banner_profile .avatar_main{
		width: 100%;
	}

	.banner_profile .user_details{
		width: 100%;
		padding-left: 0px;
	}

	.banner_profile .address_review{
		flex-flow: column;
	}

	.banner_profile .address_review .review_star{
		margin-left: 0px;
	}

	.banner_profile button{
		border-width: 0px;
	}

	.banner_profile .btn-group-dark{
		position: absolute;
		top: 0px;
		left: 0px;
		margin-top: 0px;
	}

	.banner_profile button span{
		display: none;
	}

	.banner_profile .container{
		position: relative;
	}

	.banner_profile .drop_shadow{
		float: none;
		margin: auto;
		margin-top: -110px;
	}

	.banner_profile .user_name, .banner_profile .user_tagline, .banner_profile .skills{
		text-align: center;
	}

	.banner_profile .user_details .col-lg-6.text-end{
		position: absolute;
		top: 0px;
		right: 0px;
		width: auto;
	}
}
@media only screen and (max-width:768px){
	.banner_profile .user_name{
		font-size: 36px;
		margin-top: 15px;
	}
	.banner_profile .drop_shadow{
		max-width: 200px;
		margin-top: -92px;
	}
}
@media only screen and (max-width:568px){
.banner_profile .user_details .col-lg-6.text-end{
		top: 7px;
	}

	.banner_profile .user_details .col-lg-6.text-end button{
		padding:10px 5px !important;
	}

	.banner_profile .user_name{
		font-size: 26px;
		margin-top: 5px;
	}

	.banner_profile .address_review h6{
		margin-top: 7px;
		font-size: 18px;
	}

	.btn-outline-dark {
		padding: 7px !important;
		margin-right: 0px !important;
	}

	.banner_profile .btn-group-dark{
		top: 10px;
		left: 5px;
	}
}
</style>
<script>
import EditPhoto from './Profile/EditPhoto.vue'
import GenericInfoTitleEdit from './Profile/GenericInfoTitleEdit.vue'
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import PractitionerSkillsView from './Profile/PractitionerSkillsView.vue'
import EditBgPhoto from './Profile/EditBgPhoto.vue';
import KeywordsView from './Profile/KeywordsView.vue';
import EditIcon from './Profile/EditIcon.vue';
import PractitionerRating from './Rating/PractitionerRating.vue';

export default {
	components: { PractitionerSkillsView, EditPhoto, GenericInfoTitleEdit, EditBgPhoto, KeywordsView, EditIcon, PractitionerRating },
	data() {
		return {
			practitionerImage: '',
			practitionerBgImage: '',
		}
	},
	watch: {
		practitioner() {
			this.setImage();
		},
		practitionerId() {
			this.setImage();
		},
	},
	methods: {
		reloadEntity() {
			this.$store.dispatch('getPractitioner', { idOrSlug : this.$route.params.id, redirect: false });	//when entity is reloaded, the watch'er kicks in
		},
		refreshPractitionerEvent() {
			this.$refs.practitionerSkillsView.refresh();
		},
		editGenericTitle() {
			this.$refs.genericInfoTitleEdit.show();
		},
		setImage() {
			this.practitionerImage = `/behandlerportal/images/bh/${this.practitioner.primaryImage}`;
			this.practitionerBgImage = `/behandlerportal/images/bh/${this.practitioner.bgImage}`;
		},
	},
	computed: {
		...mapGetters(["isLoggedIn", "practitionerName", "practitioner"]),
		...mapState(["practitionerId"]),
		practitionerTagline() {
			return this.$store.getters.practitioner.tagline;
		},
		practitionerCity() {
			return this.$store.getters.practitioner.city;
		}
	},
	created() {
		this.setImage();
	}

}
</script>