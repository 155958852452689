<template>
	<div style="margin-top:10px">
		<edit-icon @click="openEditAboutMe" />
		<about-me-edit ref="aboutMeEdit" @refresh="fetchItems" />
		<h5>{{ this.aboutme.label == null ? "Om mig" : this.aboutme.label }}</h5>
		<div class="ommig" ref="ommig" v-html="this.aboutme.text"></div>
		<div v-if="aboutme.text == null || aboutme.text.length < 8" style="font-size:140%">
			Der mangler en fantastisk inspirerende tekst om denne behandler. Hvis du er behandleren, så skynd dig at
			udfylde en hel masse - og glem ikke de andre sektioner!
		</div>
	</div>
</template>
<style lang="scss">
div.ommig {
	border: 0px solid red;

	li {
		margin-top: 10px;
	}

	p {
		font-family: Raleway;
		font-weight: 400;
		font-size: 29px;
		line-height: 38px;
		margin-top: 21px;
		float: none !important;
		width: 100%;
		display: block;
	}
}
</style>
<script>
/* eslint-disable */
import axios from 'axios';
import { mapState } from 'vuex';
import AboutMeEdit from './AboutMeEdit.vue'
import EditIcon from './EditIcon.vue'

export default {
	components: { AboutMeEdit, EditIcon },
	data() {
		return {
			aboutme: {},
		}
	},
	computed: mapState(['practitionerId']),
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		openEditAboutMe() {
			this.$refs.aboutMeEdit.show();
		},
		fetchItems() {
			if (this.pid < 0) return -1;
			axios
				.get(`/practitioner/${this.practitionerId}/aboutme`)
				.then(response => {
					this.aboutme = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>