<template>
	<div class="bgfullimg">
		<div class="contents">
			<h3 style="padding-top:20px">
				<div style="font-size:35px;font-family:Caveat;line-height:10px;color:#1692b6">Det handler om </div>
				<b style="font-size:80px;color:#1692b6;">MOD</b><br />
			</h3>

			<p><b>Kom til en dag med inspiration, teori, praktiske værktøjer og skønt fagligt fællesskab</b></p>

			<p style="font-size:110%;text-align: center;"><b>Onsdag d. 6 september 2023<br />
					- kl. 14.30 til 21.00</b></p>

			<div
				style="padding:40px;background-color: white; margin:0 auto;  width:50%; margin-bottom:30px;text-align: center;font-weight: bold; border-radius: 10px;">
				Eventet er ikke aktivt længere. Følg med på cfhs.dk for flere spændende events</div>

			<p>
				At være modig er én af de mest inspirerende og imponerende egenskaber, som vi mennesker kan besidde, og den
				kan
				antage mange former. Uanset formen, er MOD en kraftfuld egenskab, der kan hjælpe os med at overvinde
				udfordringer og nå vores mål.</p>

			<p>De mest modige mennesker er sjældent dem, der er født med superheltekræfter. De er mennesker, der har lært at
				overvinde deres frygt og handle på trods af den. De tager fat på en udfordring med en stærk tro på sig selv
				og
				står ved de valg de tager, uanset hvor svært eller udfordrende det kan være.</p>

			<p><b>Glæd dig</b> til at møde Lars, Christine og Jakob, der kender alt til både frygt og mod og lad dig
				inspirere til
				at
				gå med det, der er rigtigt for DIG.
			</p>
			<div class="blue">
				<h4>Program for dagen</h4>
				<p>
					<b>14.30</b> Dørene åbnes. Nyd en kop te, kaffe og lidt sødt til tonerne af den smukke Handpan.<br />
					<b>15.00</b> Vi byder velkommen i salen.<br />
					<b>15.10</b> <b>Lydhealing.</b> Vi lander i os selv, når Karmel tuner os ind med alkymiske
					lydrejser og sange fra en anden verden.<br />
					<b>15.30</b> <b>Lars Muhl</b> deler refleksioner, og et livs oplevelser og erfaringer med at
					følge og stå i egen sandhed. Den tid vi lever i kræver mod. Det kræver mod, når vi følger hjertet, op
					imod normens holdninger og værdier. Dette er Lars et godt eksempel på. Der er god plads til spørgsmål.
					<br />
					<b>16.30</b> <b>Christine Stabell Benn</b> vil dele hendes personlige historie og oplevelser med “at stå
					i
					modvind” uden at miste fodfæstet. Hvordan hun mener det er vigtigt for os alle, at være nysgerrige og
					lytte til hinanden, uden at give køb på egne værdier. Der er god plads til spørgsmål.
					<br />
					<b>17.30</b> Kort pause<br />
					<b>17.40</b> <b>Jakob Lund</b> fra Breathe SMART vil give os et lille teoretisk indblik i åndedrættets
					healende og balancerende kraft og guide os i et par kraftfulde åndedrætsøvelser. Åndedrættet er et
					effektivt værktøj, når vi i et hektisk liv søger kontakten med hjerte og mod.<br />
					<b>18.15</b> Vi skal nyde vegetarisk mad og netværke med hinanden.<br />
					<b>19.00</b> <b>Åbent panel</b>, en dialog med Lars Muhl, Christine Stabell Benn og Jakob Lund.
					Mod i en udfordrende tid. Fællesskab og individualisme. Helhedsorienteret som begreb. Nysgerrighed og
					tolerance. Videnskab og ånd.
					Vi dykker ned i vigtige spørgsmål, og byder op til spørgsmål fra salen. <br />
					<b>20.20</b> <b>Lydhealing.</b> Karmel giver os et lille teoretisk indblik i lydens mange
					potentialer for at balancere nervesystem og helbred, og afslutter vores dag med smukke krystallinske
					vibrationer og sang.<br />
					<b>20.50</b> Vi byder på et glas kombucha og siger tak for i dag 🤎
				</p>

				<p style="font-size:100%;font-style:italic">OBS vi favner alle meninger og holdninger. Dette er ikke en
					debat om
					for/imod vacciner. ALLE er velkomne.</p>
			</div>
			<div class="black" style="padding:40px 0px;">
				<b>Dagens forplejning:</b>
				<p>I kan forsyne jer med dejlig kaffe, te og sødt ved ankomst. Til aften får I serveret en dejlig vegetarisk
					ret med brød og vand. Efter aftenens afsluttende lydhealing vil der være kombucha eller vand med
					rigeligt citron, så vi kan
					understøtte kroppens healing og gå ud i verden, fuld af inspiration og med modet forrest.<br />
					<i>Kombucha er gavmildt sponsoreret af LÆSK - dansk økologisk Kombucha. </i><br />
				</p>

				<b>Adresse:</b>
				<p>Kulturhus Risbjerggaard<br />
					Hvidovrevej 241<br />
					2650 Hvidovre</p>
				<p class="parkTitle" @click="showParkDetails = showParkDetails ^ 1">
					Klik for parkering og
					transport</p>
				<p class="parkDetails" v-show="showParkDetails">
					<b>Parkering</b><br />
					Der er parkering ved kulturhusets østlige og nordlige side. Parkering kan også ske på Hvidovrevej eller
					omkringliggende villaveje.<br />
					Rådhusets parkeringspladser kan anvendes - se skiltning for særlige regler i rådhusets åbningstid.
					OBS Der må ikke være overnattende biler på parkeringspladserne.<br />
					<br />
					Kommer du ikke med bil? Frygt ej, for det kræver ingen lange gåture.<br />
					<br />
					<b>Offentlig transport</b><br />
					Nærmeste S-togsstationer er Hvidovre eller Friheden.<br />
					Fra begge stationer går der busser der stopper direkte ved Kulturhuset.<br />
					<br />
					<b>Fra S-tog station Friheden (linje A) til Kulturhuset.</b><br />
					Bus 200S (mod Buddinge station - 4 min, 1 stop undervejs).<br />
					eller<br />
					Bus 132 (mod Rødovre Centrum eller Tingbjerg Gavlhusvej - 4 min, 4 stop undervejs).<br />
					<br />
					Begge busser holder lige ude foran S-tog stationen på parkeringspladsen/vendepladsen (v/ hjørnet
					Hvidovrevej/Gl Køge Landevej).<br />
					Begge busser stopper foran Kulturhus Risbjerggaard.<br />
					<br />
					<b>Fra S-tog station Hvidovre (linje B) til Kulturhuset.</b><br />
					Bus 132 (mod Friheden station - 8 min, 6 stop undervejs).<br />
					eller<br />
					Bus 22 (mod Glostrup St. - 6 min, 4 stop undervejs).<br />
					<br />
					Begge busser holder lige ude foran S-tog stationen på parkeringspladsen (v/ hjørnet
					Hvidovrevej/Dæmningen).<br />
					Begge busser stopper ved Hvidovre Rådhus, som ligger lige overfor Kulturhus Risbjerggaard.<br />
				</p>
				<h4>Pris for hele dagen, inkl. forplejning:</h4>
				<p class="price"><b>EARLY BIRD KUN dkk 775,-</b> eks. moms<br>
					<br>
					<i>OBS! Der er begrænsede pladser til denne dag, så tilmeld dig nu for at være sikker på en plads.</i>
				</p>

				<!-- <p><b><router-link :to="{ name: 'LiveEvent1Tilmeld' }">TILMELD DIG HER</router-link></b></p> -->
				<div
				style="padding:40px;background-color: white; margin:0 auto;  width:50%; margin-bottom:0px;text-align: center;font-weight: bold; border-radius: 10px;">
				Eventet er ikke aktivt længere. Følg med på cfhs.dk for flere spændende events</div>

			</div>
			<div class="blue tiles">
				<div class="profile">
					<img src="@/assets/images/lars-muhl.jpg" />
					<div class="name">Lars Muhl</div>
					<div class="resume">Forfatter, mystiker og musiker. Lars har siden 1965, sideløbende med sit musikalske
						virke, studeret sammenlignende religion og filosofi, og specialiseret sig i aramæisk, samt kristen
						og
						jødisk mystik. Muhl er et fascinerende og vidende menneske, der "smitter" rummet med en helt særlig
						energi.</div>
				</div>
				<div class="profile">
					<img src="@/assets/images/christine-stabel-benn.jpg" />
					<div class="name">Christine Stabell Benn</div>
					<div class="resume">Læge, ph.d og doktor i medicinsk videnskab. I dag professor i global sundhed fra
						Syddansk Universitet og leder af Bandim Health Project på SDU.<br /> Christine er af mange kendt som
						den
						fagligt stærke, undersøgende og kritiske stemme under pandemien.</div>
				</div>
				<div class="profile">
					<img src="@/assets/images/jacob-lund.jpg" />
					<div class="name">Jakob Lund</div>
					<div class="resume">Stifter af Breathe Smart som hører under den humanitære organisation International
						Association for Human Values (IAHV). Breathe Smart's åndedrætsmetoder hjælper bl.a. med at håndtere
						negative tanker og følelser, stabiliserer nervesystemet og genopretter vores fysiske og mentale
						sundhed. </div>
				</div>
			</div>
			<div>Kombucha er gavmildt sponsoreret af LÆSK - dansk økologisk Kombucha
				<img src="@/assets/images/laesk-logo-kombucha-white2.webp" width="100"
					style=" filter: grayscale(100%) brightness(0);" />
			</div>
			<div class="eventby">
				Eventet afvikles af minSundhed - Center for Holistisk Sundhed, cvr 39073153,
				mail: kontakt@cfhs.dk, tlf nr 23 74 14 37
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.parkTitle {
	display: inline-block;
	//border: 1px solid #00a9c340;
	//padding: 10px;
	border-radius: 10px;
	font-size: 90%;
	margin-bottom: 10px;
	margin: -5px 0px 40px;
	text-decoration: underline;
	cursor: pointer;
}

.parkDetails {
	//background-color: #00a9c3f0;
	//color: white !important;
	//padding: 20px;
	border-radius: 5px;
	margin-top: -20px;
	//b {color:white}
}

.bgfullimg {
	background-image: url('@/assets/images/BG-event-sept_low.jpg');
	background-size: cover;
	padding: 0px;
	background-color: #fff;

	.contents {
		margin: auto;
		max-width: 1000px;
		padding: 40px;
	}
}



.bgfullimg p {
	color: #000000;
}

a {
	color: black;
	font-weight: bold;
}

p,
h3,
h4,
b {
	color: black;
	line-height: 140%;
}

p.modintro {
	font-style: italic;
	line-height: 130%;
}

.blue {
	padding: 40px;
	background-color: #00a9c3f0;
	margin-bottom: 20px;
}

.black {
	color: black;
}

.blue p,
.blue b,
.blue h4 {
	color: white;
}

.tiles {
	background-color: #00a9c3f0;
	display: flex;
	padding: 40px;
	gap: 60px;

	@media (max-width: 800px) {
		font-size: 80%;
	}

	@media (max-width: 650px) {
		flex-direction: column;
	}
}

.profile {
	width: 33%;
	color: white;

	@media (max-width: 650px) {
		width: 100%;
	}
}

.profile .name {
	font-weight: 800;
	padding: 20px 0px 10px 0px;
}

.profile img {
	width: 100%;
}

.white b {
	color: white
}

.eventby {
	padding: 40px;
	color: black;
	font-size: 80%;
}

.white,
div.white p,
.white h4 {
	color: #fff;
	font-size: 110%;
	margin-left: -2px;
}

h3 {
	font-size: 38px;
	font-family: 'Raleway-Light';
}

h4 {
	font-size: 20px !important;
	font-weight: bold;
}

.italic p {
	font-style: italic
}

.pic {
	width: 100%;
	border-radius: 10px;
	margin-bottom: 40px;
}
</style>
<script>
export default {
	data() {
		return {
			showParkDetails: false
		};
	},
	methods: {
	},
	mounted() {
		var metaTags = document.getElementsByTagName('meta');

		// Loop through the meta tags
		for (var i = 0; i < metaTags.length; i++) {
			var metaTag = metaTags[i];

			// Find the desired meta tag by its name or property attribute
			console.log(metaTag);
			if (metaTag.getAttribute('property') === 'og:image') {
				// Update the content attribute with the new value
				metaTag.setAttribute('content', 'https://minbehandler.minsundhed.com/some-live-event.jpg');
			}
		}
	}
}
</script>