<template>
	<div class="gallery">
		<span v-if="showTitle || isPractitionerPageOwner">
			<h4 v-if="galleryItems.length > 0 || isPractitionerPageOwner">
				<edit-icon @click="openEditPictures" :has-items="galleryItems.length > 0 "/>
				<span v-if="showTitle">
					<span>Mine </span>billeder
				</span>
			</h4>
		</span>
		<div class="flexgrid">
			<router-link v-for="item in galleryItems" :key="item.id" :to="{ path: `/behandler/${practitionerId}/billeder/${item.id}`, hash: '#image' }">
				<b-img class="previewImg" ref="previewImg" id="previewImage" v-bind:src="getImageUrl(item.id)" />
			</router-link>
		</div>
		<gallery-items-edit ref="galleryItemsEdit" @refresh="fetchItems"/>
	</div>
</template>
<style scoped>
.gallery h4 {margin-bottom:20px !important}
.flexgrid {
	border:1px solid #fff;
	display: flex;
	flex-wrap: wrap;
	gap: 20px 10px;
	/* row-gap column gap */
}

img.previewImg {
	margin:0px;
	border-radius: 9px;
	width: 110px;
	height: 110px;
	/* width: 100%;
	height: 100%; */
	object-fit: cover;
	border: 1px solid #aaa;
}

.small {
	width: 100%;
	height: 100%;
	display: block;
	border: 1px solid #aaa;
	border-radius: 2px;
	object-fit: cover;
	margin-bottom: 6px;
}

</style>
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import GalleryItemsEdit from './GalleryItemsEdit.vue';
import EditIcon from "./EditIcon.vue";
export default {
	components: { GalleryItemsEdit, EditIcon },
	computed: {
		...mapState(["practitionerId"]),
		...mapGetters(["isPractitionerPageOwner"])
	},
	props: {
		showTitle: Boolean,
		className: {
			type: String,
			default: ""
		},
		itemsToShow: {
			type: Number,
			default: 6
		}
	},
	data() {
		return {
			galleryItems: [],
			//pageUrl : "a",
		}
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		getImageUrl(id) {
			return id < 0 ? `/behandlerportal/images/blank.gif` : `/behandlerportal/images/bh/${this.practitionerId}_images_${id}_small.jpg`;
		},
		openEditPictures() {
			this.$refs.galleryItemsEdit.show();
		},
		fetchItems() {
			//debugger // eslint-disable-line no-debugger
			// console.log(`${this.$options._componentTag}-> fetch:${this.practitionerId}`);
			if (this.practitionerId < 0) return;
			axios
				.get(
					
					`/practitioner/gallery/${this.practitionerId}?itemCount=${this.itemsToShow}`
				)
				.then((response) => {
					this.galleryItems = response.data;
				})
				.catch((error) => console.log(error));
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>

