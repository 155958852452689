<template>
	<div id="app">
		<AppHeader></AppHeader>
		<router-view :key="key"></router-view>
		<AppFooter></AppFooter>
	</div>
</template>
<script>
import AppHeader from './components/FrontPage/AppHeader.vue'
import AppFooter from './components/FrontPage/AppFooter.vue'

export default {
	name: 'App',
	components: {
		AppHeader, AppFooter
	},
	computed: {
		key() { return this.$route.path + Math.random() }
	},
	watch: {
		'$route'(to, from) {
			if (to !== from) {
				//debugger // eslint-disable-line no-debugger
				if (to.params.entryid)
					this.$store.commit('entryid', to.params.entryid);
			}
		},
	}
}
</script>

<style>
</style>
