<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Kontaktinformationer</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body" style="font-size:80%">
							<b-form-group id="input-group-3" label="Firma-/stednavn" label-for="companyName">
								<b-form-input
									id="companyName"
									v-model="practitioner.companyName"
									placeholder="Indtast evt. firma-/stednavn"
								></b-form-input>
							</b-form-group>

							<b-form-group id="addressContainer" label="Adresse" label-for="address">
								<vue-dawa @select="selectItem($event)"
									:val="practitioner.address"
									:showMax="8"
									placeholder="Skriv adresse"
									containerId="addressContainer"
									fieldId="address"
									fieldName="practitioner.address"
									fieldClasses="form-control">
								</vue-dawa>
							</b-form-group>

							<b-form-checkbox id="onlyShowCity" v-model="practitioner.onlyShowCity" style="color: #a4a4a4;">
								&nbsp;Vis kun by-navn på profilside
							</b-form-checkbox>
							<div class="dim">Sæt kryds for kun at vise by-navnet på din offentlige profil</div>

							<b-form-group id="input-group-3" label="Postnr." label-for="zip">
								<b-form-input
									id="zip"
									readonly
									v-model="practitioner.zip"
									style="width:120px"
									placeholder=""
									type="number"
									required
								></b-form-input>
							</b-form-group>

							<b-form-group id="input-group-3" label="By" label-for="city">
								<b-form-input
									id="city"
									readonly
									v-model="practitioner.city"
									placeholder=""
									required
								></b-form-input>
							</b-form-group>

							<b-form-group id="input-group-2" label="Offentlig email" label-for="publicemail">
								<b-form-input
									id="publicemail"
									v-model="practitioner.publicEmail"
									type="email"
									placeholder=""
									required
								></b-form-input>
							</b-form-group>

							<b-form-group id="input-group-3" label="Tlf." label-for="phone">
								<b-form-input
									id="zip"
									v-model="practitioner.phone"
									style="width:120px"
									placeholder=""
									type="number"
									required
								></b-form-input>
							</b-form-group>

							<b-form-group id="input-group-2" label="Webadresse / hjemmeside" label-for="website">
								<b-form-input
									id="website"
									v-model="practitioner.website"
									placeholder=""
									required
								></b-form-input>
							</b-form-group>

							<br/>

							<b-card no-body>
								<b-tabs card>
									<b-tab title="Åbningstider">
										<b-card-text>
											<opening-hours @refreshOpeningHours="$emit('refreshOpeningHours')" ref="openingHours"/>
										</b-card-text>
									</b-tab>
								</b-tabs>
							</b-card>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary"  @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style scoped>
.dim {color:#a4a4a4;padding:0px 0px 5px}
.modal-dialog,
.modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	overflow-y: scroll;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}
</style>
<script>
import OpeningHours from './OpeningHours.vue'
import axios from 'axios'
import VueDawa from 'vue-dawa'
require('vue-dawa/dist/vue-dawa.min.css')

export default ({ 
  components: { OpeningHours, VueDawa },
	data() {
		return {
			showModal : false,
			myAddress : '',
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
		practitioner () {
			return this.$store.getters.practitioner;
		},
	},
	methods: {
		getCoordinates(address) {
			axios
				.get(`/practitioner/entity/LookupLatLongFromAddress?address=${address}`)
				.then((res) => {
					const { lat, lng } = res.data.location;
					this.practitioner.lat = lat;
					this.practitioner.long = lng;
				})
		},
		selectItem(payload) {
			let address = this.translateData(payload);
			this.practitioner.address = address.oneLineAddress.substr(0, address.oneLineAddress.lastIndexOf(","));
			this.practitioner.zip = address.zipCode;
			this.practitioner.city = address.city;
			this.getCoordinates(address.oneLineAddress);
		},
		translateData(address) {
			if (!address || !address.data) {
				return;
			}
			return {
				id: address.data.id,
				street: address.data.vejnavn,
				streetNumber: address.data.husnr,
				floor: address.data.etage,
				door: address.data.dør,
				zipCode: address.data.postnr,
				city: address.data.postnrnavn,
				oneLineAddress: address.tekst
			}
		},
		async saveForm() {
			let res = await this.$refs.openingHours.saveItems();
			if(!res)
			{
				alert("Der var et problem med at gemme åbningstider sektionen. Kontroller venligst din indtastning");
				return;
			}
			axios.post(`/practitioner/entity`, this.practitioner)
				.then(() => {
					this.$emit('refreshOpeningHours');
					this.showModal = false;
				})
				.catch(error => {
					console.log(error);
				});        
		},
		show() {
			this.showModal = true;
		},
	},

})
</script>
