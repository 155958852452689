<template>
	<!-- ====================== header start ================================= -->
	<div>
		<Welcome></Welcome>
		<Header></Header>
		<!-- ====================== header end ================================= -->
		<!-- ====================== profile top section  ================================= -->
		<profile-top-section />
		<!-- ====================== profile top section end ================================= -->
		<!-- ====================== profile content start ================================= -->
		<profile-contents />
		<!-- ====================== profile content end ================================= -->
		<!-- =====================slider section -start ================================= -->
		<reviews />
		<!-- =====================slider section end ================================= -->
		<articles />
		<!-- ===================== footer start ============================= -->
		<Footer />
	</div>
	<!--=====================footer end ===================-->
</template>
<style scoped>

</style>

<script>
import Reviews from './Rating/Reviews.vue'
import Articles from './Articles.vue'
import ProfileTopSection from './ProfileTopSection.vue'
import ProfileContents from './ProfileContents.vue'
import Welcome from './Welcome.vue'

export default {
	components: { Articles, Reviews, ProfileTopSection, ProfileContents, Welcome },
	data() {
		return {
			practitionerImage: '',
		}
	},
	watch: {
		practitionerId() {
			this.practitionerImage = `/behandlerportal/images/bh/behandler_${this.practitionerId}.jpg`;
		},
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	methods: {
		handleSlugOrId() {
			this.$store.dispatch('getPractitioner', { idOrSlug: this.$route.params.id, redirect: false });
		}
	},
	created() {
		//console.log( `${this.$options._componentTag}-> created: ${this.$store.getters.practitionerId}`);
		this.handleSlugOrId();
	}
}
</script>
