<template>
	<div v-if="event">
		<div class="backContainer" @click="$router.go(-1)">
			<b-link class="back">
				<b-icon icon="arrow-left-circle-fill" style="color: #fff;"></b-icon> Tilbage
			</b-link>
		</div>
		<div class="event_info">
			<div class="date">
				<b>{{ getDay(parsedStartDateTime) }}</b><br />
				{{ getMonth(parsedStartDateTime) }}<br />
				{{ getTime(parsedStartDateTime) }}
			</div>
			<div class="desc">
				<h3>{{ event.headline }}</h3>
				<span class="when" v-if="event.startDateTime != '0001-01-01 00:00'">
					<b-icon style="font-size:25px;" icon="calendar2-date-fill" />
					{{ formattedDateTime(event.startDateTime) }}
				</span>
				&nbsp;
				<span class="when" v-if="event.endDateTime != '9999-12-31 23:59'">
					<b-icon style="font-size:25px;" icon="calendar2-date-fill" />
					{{ formattedDateTime(event.endDateTime) }}
				</span>
			</div>
		</div>
		<div class="teaser">
			{{ event.teaser }}
		</div>
		<div class="image thumbcontainer">
			<b-img class="eventImg" v-if="event.filename != null" fluid v-bind:src="getImageUrl(event)" alt="Event" />
		</div>
		<br />
		<div v-html="event.body" />
		<div v-if="event.startDateTime != '0001-01-01 00:00'">
			<span class="when">
				<b-icon style="font-size:17px;" title="Slet" icon="calendar2-date-fill" /> Hvornår
			</span>
			&nbsp;
			<span class="">{{ formattedDateTime(event.startDateTime) }}</span><br>

			<div v-if="event.endDateTime != '9999-12-31 23:59'">
				<span class="when">
					<b-icon style="font-size:17px;" title="Slet" icon="calendar2-date-fill" /> Til
				</span>
				&nbsp;
				<span class="">{{ formattedDateTime(event.endDateTime) }}</span>
			</div>
		</div>
	</div>
</template>
<style scoped>
h3 {
	font-size: 35px !important
}

.teaser {
	font-size: 20px;
	margin-bottom: 20px;
}

div.event_info {
	display: flex;
	font-size: 24px;
	background-color: #eee;
	border-radius: 10px;
	padding: 0px;
	margin: 15px 0px 30px 0px;
	white-space: nowrap;
}

div.event_info .date {
	text-align: center;
	background-color: #aaa;
	padding: 20px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	color: white;
}

div.event_info .date b {
	font-size: 40px;
}

div.event_info .desc {
	vertical-align: text-bottom;
	font-size: 40px;
	padding: 20px 10px 10px 20px;
	white-space: normal;
}

span.when {
	font-size: 17px
}

img.eventImg {
	border: 1px solid #aaa;
	border-radius: 10px;
	margin-bottom: 20px;
}

@media (max-width: 560px) {
	h3 {
		font-size: 25px !important
	}

	.teaser {
		font-size: 14px;
		margin-bottom: 15px;
	}

	div.event_info .date {
		font-size: 20px;
	}

	div.event_info .date b {
		font-size: 30px;
	}

	div.event_info .desc {
		font-size: 40px;
		padding: 10px;
		white-space: normal;
		line-height: 20px;
	}

	span.when {
		font-size: 16px;
		white-space: nowrap;
	}
}
</style>
<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	data() {
		return {
			eventId: -1,
			event: [],
			parsedStartDateTime : null,
			parsedEndDateTime : null,
			months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
		};
	},
	computed: mapState(["practitionerId"]),
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		getMonth(date) {
			if(!date) return;
			return this.months[date.getMonth()];
		},
		getDay(date) {
			if(!date) return;
			return date.getDate();
		},
		getTime(date) {
			if(!date) return;
			let time = "";
			if (date.getHours() != 0 || date.getMinutes() != 0)
				time = date.getHours() + ":" + this.postZeroes(date.getMinutes());
			return time;
		},
		parseDate(dateStr) {
			//debugger; // eslint-disable-line no-debugger
			if (!dateStr)
				return "";
			//comes with format: 2022-09-15 10:00
			let hour = 0, minute = 0;
			let year = parseInt(dateStr.substr(0, 4));
			let month = parseInt(dateStr.substr(5, 2));
			let day = parseInt(dateStr.substr(8, 2));
			if (dateStr.indexOf(":") > 0) {
				hour = parseInt(dateStr.substr(11, 2));
				minute = parseInt(dateStr.substr(14, 2));
			}
			return new Date(year, month - 1, day, hour, minute);
		},
		formattedDateTime(dt) {
			if(!dt) return;
			let d = this.parseDate(dt);
			let dateString = d.getDate() + "/" + (d.getMonth() + 1) + "-" + d.getFullYear() + " ";
			if (d.getHours() != 0 || d.getMinutes() != 0)
				dateString += d.getHours() + ":" + this.postZeroes(d.getMinutes());
			return dateString;
		},
		postZeroes(val) {
			return val == 0 ? "00" : val.toString();
		},
		getImageUrl(event) {
			return `/behandlerportal/images/bh/cie_${event.id}.jpg`;
		},
		fetchItems() {
			axios
				.get(
					
					`/practitioner/events/${this.practitionerId}/?entryid=${this.eventId}&itemCount=1`
				)
				.then((response) => {
					this.event = response.data[0];
					this.parsedStartDateTime = this.parseDate(this.event.startDateTime);
					this.parsedEndDateTime = this.parseDate(this.event.endDateTime);
				})
				.catch((error) => console.log(error));
		},
	},
	created() {
		this.eventId = this.$route.params.entryid;
		console.log("EventId: " + this.eventId);
		this.fetchItems();
	}
}
</script>