<template>
    <div>
        <div class="min-progress-bar min-hidden-xs-only" style="border:0px solid blue">
            <div class="step"  v-on:click="chooseStep(1)" v-bind:class="{ 'disabled': currentStep > 1}">
                <div class="step__container">
                    <div class="oval">
                        <div class="oval__background"></div>
                        <div class="oval__inner"></div>
                    </div>
                    <div class="progress-step">1. OPRET BEHANDLERPROFIL</div>
                </div>
            </div>
            <div class="step" v-bind:class="{ 'disabled': currentStep == 1}">
                <div class="underline"></div>
            </div>
            <div class="step"  v-bind:class="{ 'disabled': currentStep < 2}">
                <div class="step__container">
                    <div class="oval">
                        <div class="oval__background"></div>
                        <div class="oval__inner"></div>
                    </div>
                    <div class="progress-step">2. OPSÆT DIN SIDE</div>
                </div>
            </div>
        </div>

        <div class="min-progress-bar-mobile min-hidden-md-and-up">
            <div class="step" v-bind:class="{ 'disabled': currentStep != 1}"></div>
            <div class="step" v-bind:class="{ 'disabled': currentStep != 2}"></div>
        </div>

    </div>
    
</template>

<script>
    import { mapState } from "vuex";

    export default {
        data() {
            return { }
        },
        computed: mapState({
            currentStep: state => state.currentStep,
        }),
        methods: {
            chooseStep(step) {
                if (step < this.currentStep) {
                    if (!this.userId) {
                        this.$emit('chooseStep', step);
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "./colors.scss";

    .min-progress-bar-mobile {
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;

        .step {
            background-color: $blue;
            &.disabled {
                background-color: $pearl-gray;
            }
        }
    }

    .min-progress-bar {
        display: flex;
        justify-content: space-between;
        margin: 100px auto 0px;
        /*max-width: 1328px;*/
        max-width: 550px !important;
        width: 100%;
        .step {
            display: flex;
            justify-content: center;
            align-items: center;

            &__container {
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }
            }

            .progress-step {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.7px;
                color: $rhino-gray;
                margin-left: 15px;
            }

            .underline {
                width: 100px;
                height: 1px;
                background-image: linear-gradient(to left, $white, $blue 50%, $white);
            }

            .oval {
                position: relative;
                width: 20px;
                height: 20px;

                .oval__inner,
                .oval__background {
                    background-color: $blue;
                    border-radius: 50%;
                }

                .oval__background {
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    opacity: .2;
                }

                .oval__inner {
                    top: 25%;
                    right: 25%;
                    position: absolute;
                    width: 50%;
                    height: 50%;
                    z-index: 10;
                }
            }

            &.disabled {
                .step__container {
                    &:hover {
                        cursor: not-allowed;
                    }
                }
                
                .progress-step {
                    color: $lighter-gray;
                }

                .underline {
                    background-image: linear-gradient(to left, $white, #dddddd 50%, $white);
                }

                .oval {
                    .oval__inner,
                    .oval__background {
                        background-color: $dark-gray;
                    }
                }
            }
        }
    }

    /*small phone*/
    @media (max-width: 374px) {
        .min-progress-bar-mobile {
            max-width: 250px;
            padding: 78px 0 56px;
            .step {
                width: 50px;
                height: 5px;

                &.disabled {
                    height: 1px;
                }
            }
        }

        .min-hidden-xs-only {
             display: none;
        }
    }
    /*big phone*/
    @media (min-width: 375px) {
        .min-progress-bar-mobile {
            padding: 78px 0 56px;
            max-width: 250px;
            .step {
                width: 50px;
                height: 5px;

                &.disabled {
                    height: 1px;
                }
            }
        }

        .min-hidden-xs-only {
            display: none;
        }
    }
    /*small tablet*/
    @media (min-width: 768px) {
        .min-progress-bar-mobile {
            .step {
                width: 100px;
                height: 6px;

                &.disabled {
                    height: 2px;
                }
            }
        }
    }
    /*big tablet*/
    @media (min-width: 993px) {
        .min-hidden-xs-only {
            display: flex;
        }

        .min-hidden-sm-and-up {
            display: none;
        }

        .min-progress-bar {

            .step {
                .progress-step {
                    margin-left: 5px;
                }

                .underline {
                    width: 50px;
                }
            }
        }
    }
    /*laptop*/
    @media (min-width: 1264px) {
        .min-progress-bar {
            /*max-width: 1264px;*/

            .step {
                .progress-step {
                    margin-left: 15px;
                }

                .underline {
                    width: 100px;
                }
            }
        }
    }
</style>
