<template>
	<div>
		<template v-for="(item, index) in items">
			<div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
			<menu-item v-else :key="index" v-bind="item" />
		</template>
	</div>
</template>
<script>
import MenuItem from './MenuItem.vue'

export default {
	components: {
		MenuItem,
	},
	props: {
		editor: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [
				{
					icon: 'bold',
					title: 'Bold',
					action: () => this.editor.chain().focus().toggleBold().run(),
					isActive: () => this.editor.isActive('bold'),
				},
				{
					icon: 'italic',
					title: 'Italic',
					action: () => this.editor.chain().focus().toggleItalic().run(),
					isActive: () => this.editor.isActive('italic'),
				},
				{
					icon: 'strikethrough',
					title: 'Strike',
					action: () => this.editor.chain().focus().toggleStrike().run(),
					isActive: () => this.editor.isActive('strike'),
				},
				{
					type: 'divider',
				},
				// {
				//   icon: 'h-1',
				//   title: 'Heading 1',
				//   action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
				//   isActive: () => this.editor.isActive('heading', { level: 1 }),
				// },
				// {
				//   icon: 'h-2',
				//   title: 'Heading 2',
				//   action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
				//   isActive: () => this.editor.isActive('heading', { level: 2 }),
				// },
				{
					icon: 'paragraph',
					title: 'Paragraph',
					action: () => this.editor.chain().focus().setParagraph().run(),
					isActive: () => this.editor.isActive('paragraph'),
				},
				{
					icon: 'list-unordered',
					title: 'Bullet List',
					action: () => this.editor.chain().focus().toggleBulletList().run(),
					isActive: () => this.editor.isActive('bulletList'),
				},
				{
					icon: 'list-ordered',
					title: 'Ordered List',
					action: () => this.editor.chain().focus().toggleOrderedList().run(),
					isActive: () => this.editor.isActive('orderedList'),
				},
				{
					type: 'divider',
				},
				{
					icon: 'double-quotes-l',
					title: 'Blockquote',
					action: () => this.editor.chain().focus().toggleBlockquote().run(),
					isActive: () => this.editor.isActive('blockquote'),
				},
				{
					icon: 'separator',
					title: 'Horizontal Rule',
					action: () => this.editor.chain().focus().setHorizontalRule().run(),
				},
				{
					type: 'divider',
				},
				{
					icon: 'text-wrap',
					title: 'Hard Break',
					action: () => this.editor.chain().focus().setHardBreak().run(),
				},
				{
					icon: 'format-clear',
					title: 'Clear Format',
					action: () => this.editor.chain()
						.focus()
						.clearNodes()
						.unsetAllMarks()
						.run(),
				},
				{
					icon: 'link',
					title: 'link',
					action: () => this.setLink(),
					// isActive: () => this.editor.isActive('bold'),
				},
				{
					type: 'divider',
				},
				{
					icon: 'arrow-go-back-line',
					title: 'Undo',
					action: () => this.editor.chain().focus().undo().run(),
				},
				{
					icon: 'arrow-go-forward-line',
					title: 'Redo',
					action: () => this.editor.chain().focus().redo().run(),
				},
			],
		}
	},
	methods: {
		setLink() {
			const previousUrl = this.editor.getAttributes('link').href
			const url = window.prompt('URL', previousUrl)

			// cancelled
			if (url === null) {
				return
			}

			// empty
			if (url === '') {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()

				return
			}

			// update link
			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url })
				.run()
		},
	}
}
</script>

<style lang="scss">
.divider {
	width: 2px;
	height: 1.25rem;
	background-color: rgba(#000, 0.1);
	margin-left: 0.5rem;
	margin-right: 0.75rem;
}
</style>