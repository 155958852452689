<template>
	<div v-if="show">
		<form class="row g-3 needs-validation" style="padding:40px" novalidate>
			<form-group :validator="$v.sports" label="Favourite Sports">
				<multiselect v-model='sports' :options='sports_array' @input='$v.sports.$touch()' :searchable="false"
					:show-labels="false" placeholder="Select your favourite sport"></multiselect>
			</form-group>
			<br />
			<form-group :validator="$v.firstname" label="Indtast fornavn">
				<input type="text" v-model="firstname" />
			</form-group>

			<form-group :validator="$v.email" label="Indtast email">
				<input type="text" v-model="email" />
			</form-group>

			<form-group :validator="$v.date" label="Indtast dato">
				<b-form-input type="text" v-mask="'####-##'" v-model="date" />
			</form-group>

			<form-group :validator="$v.multiselectValue" id="input-group-1" label="Behandling / ydelse"
				label-for="multiselects" description="">
				<multiselect :taggable="true" :options="treatments" tag-placeholder="Klik for at tilføje"
					deselectLabel="Klik for at fjerne valget" selectLabel="Klik for at vælge" selectedLabel="Valgt"
					v-model="multiselectValue" @input='$v.multiselectValue.$touch()' />
			</form-group>
			<br />

			<br>
			<button id="sub" type="submit" @click="checkForm">Save</button>
		</form>
		<hr />
		<br />
	</div>
</template>
<style scoped>
form.has-error {
	color: red;
}

div.help-block span {
	color: red !important;
	padding-left: 5px;
	font-size: 42px
}

.error {
	color: red;
	padding-left: 5px;
	font-size: 12px
}
</style>
<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { required, email } from 'vuelidate/lib/validators'

export default ({
	components: { Multiselect },
	data() {
		return {
			show: true,
			multiselectValue: null,
			//treatments : [ { id: 1, treatmentName: "Massage", }, { id: 2, treatmentName: "Terapi" }],
			treatments: ["Massage", "Terapi"],
			formIsValid: false,
			firstname: null,
			lastname: null,
			email: null,
			sports: '',
			date: '',
			sports_array: ['Football', 'Tennis', 'Swimming', 'Body Building'],
		}
	},
	validations: {
		firstname: {
			required
		},
		multiselectValue: {
			required
		},
		sports: {
			required
		},
		email: {
			email
		},
		date: {
			required
		}
	},
	//debugger; // eslint-disable-line no-debugger
	methods: {
		validateForm() {

		},
		checkForm: function (e) {
			e.preventDefault();
			this.$v.$touch();
			console.log(this.$v.$invalid)
			if (this.$v.$invalid) {
				this.formIsValid = false;
			} else {
				// do your submit logic here
				this.formIsValid = true;
			}
			console.log("Submitting " + this.formIsValid);
		},
	},
})
</script>
