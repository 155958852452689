<template>
	<div class="bgfullimg">
		<div class="contents">

			<h3 style="padding-top:20px">
				<div style="font-size:35px;font-family:Caveat;line-height:10px;color:#1692b6">Det handler om </div>
				<b style="font-size:80px;color:#1692b6;">MOD</b><br />
			</h3>

			<h4><b>TILMELDING OG BETALING</b></h4>
			<br />

			<div v-if="failed || state == STATES.FAILED">
				<p style="font-size:150%">
					Betaling fejlede <br />
					Det ser ud til at betalingen fejlede af en eller anden grund. Prøv venligst igen.
				</p>
			</div>
			<div v-else>
				<h4>Kom til en dag med inspiration, teori, praktiske værktøjer og skønt fagligt fællesskab</h4>

				<p style="text-align:center"><b>Onsdag d. 6 september 2023</b><br />
					- kl. 14.30 til 21.00 </p>

				<div v-if="!submitDone">
					<p style="font-size:150%">
						EARLY BIRD <br />
						<b>I alt 969,-</b> <br />
						<span style="font-size:80%">775,- plus 25% moms</span>
					</p>

					<div>Fulde navn:</div>
					<div class="input"><input type="text" v-model.trim="signup.fullname"></div>
					<div>Email adresse:</div>
					<div class="input"><input type="text" v-model.trim="signup.email"></div>
					<span class="dusche">(her får du tilsendt billet og kvittering)</span>
					<div>Tlf. nummer:</div>
					<div class="input"><input type="text" v-model.trim="signup.phoneno"></div>
					<span class="dusche">(for at matche din tilmelding med din betaling på MobilePay)</span>
					<br />&nbsp;
				</div>
				<div style="display:flex;align-items: center">
					<!-- <button v-if="!submitDone" class="btn-theme button" @click="submit">Tilmeld og betal </button> -->
					<b-icon v-if="submitted" icon="arrow-clockwise" animation="spin" font-scale="2"
						style="margin:0px 8px;" />
				</div>
				<br />
				<p v-if="!submitDone" @click="showTerms ^= 1" style="margin-top:20px;cursor:pointer;font-size:14px;"><input
						type="checkbox" id="terms" v-model="terms" /><label for="terms">Jeg accepterer </label> <b
						style="text-decoration:underline">handels- og
						betalingsbetingelser</b> 👇🏻
				</p>
				<p style="font-size:80%">
					Eventet afvikles af minSundhed - Center for Holistisk Sundhed, cvr 39073153, Karise Alle 22, 2650
					Hvidovre,
					mail: kontakt@cfhs.dk, tlf nr 23 74 14 37
				</p>
				<div v-show="showTerms" class="terms">
					<p>Billetter kan ikke ændres, justeres eller byttes efter købets gennemførsel.<br />
						I henhold til "Lov om visse forbrugeraftaler § 17 stk. 1 jf. § 9 stk. 2 nr. 2a" er køb af billetter
						ikke
						omfattet fortrydelsesretten. Billet-, kort- og evt. administrationsgebyrer refunderes heller
						ikke.<br />
						<br />
						Du kan på www.minholistiskebehandler.dk betale med Dankort, Visa/Dankort, Mastercard og
						MobilePay.<br />
						minSundhed.com ApS gemmer ikke kortoplysningerne.<br />
						<br />
						De købte billetter leveres som elektroniske billetter til den e-mail du indtaster i forbindelse med
						dit
						køb.
						Vær opmærksom på at du selv skal printe dine billetter, eller fremvise dem på intakt telefonen, og
						medbringe
						dem til arrangementet. Der vil blive sendt en kvittering til din e-mail umiddelbart efter dit køb.
						Har
						du
						ikke modtaget din billet efter dit køb kan du henvende dig hos: Center for Holistisk Sundhed på
						kontakt@cfhs.dk eller 23 74 14 37.<br />
						<br />
						Alle priser er som udgangspunkt altid angivet inklusiv moms og andre afgifter. Hvor det er anført
						dog
						ex. moms.<br />
						<br />
						I tilfælde af aflysning henvises der til den ansvarlige arrangør for yderligere information og
						eventuel
						refundering. Arrangøren forbeholder sig ret til at erstatte billetten med lignende
						arrangement.<br />
						<br />
						Vi hæver pengene pengene på din konto ved bestilling af billetterne. Du modtager dine billetter kort
						tid
						efter din ordre er gennemført, ca. 5-10 minutter efter. Skulle det ske, at du ikke har modtaget dine
						billetter indenfor dette tidsrum, vil vi først bede dig tjekke dit spamfilter. Kan du heller ikke
						finde
						en
						mail fra os der, bedes du henvende dig til os på kontakt@cfhs.dk eller 23 74 14 37.<br />
						<br />
						Center for Holistisk Sundhed / minSundhed.com ApS forbeholder sig retten til at sende
						servicemeddelelser
						til
						billetkøbere. Servicemeddelelser kan indeholde generel reklame for lignende produkter og
						tjenesteydelser. Vi
						tilmelder dog ikke kontinuerlig markedsføring uden samtykke fra billetkøber.<br />
						<br />
						minSundhed.com ApS giver ingen personlige data eller andre informationer indtastet på denne website
						videre
						til tredjemand.<br />
						<br />
						Eventet afvikles af minSundhed - Center for Holistisk Sundhed, cvr 39073153, Karise Alle 22, 2650
						Hvidovre, mail:
						kontakt@cfhs.dk, tlf nr 23 74 14 37
					</p>
				</div>

				<div class="tak" v-if="submitDone && state == STATES.SUCCESS">Tak - din tilmelding og betaling er modtaget
					og du
					modtager snart en mail.
					🙏</div>
				<div class="tak" v-if="submitDone && state == STATES.PENDING">Din betaling behandles...🙏</div>
			</div>
		</div>
	</div>
</template>
<style type="text/css">
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

body,
html,
#app {
	height: 100vh !important;
}
</style>
<style lang="scss" scoped>
.tak {
	padding: 20px;
	background-color: #ffffffa0;
	max-width: 350px;
	border-radius: 20px;

}

.bgfullimg {
	background-image: url('@/assets/images/BG-event-sept_low.jpg');
	background-size: cover;
	padding: 0px;
	background-color: #fff;
	min-height: 100vh !important;

	.contents {
		margin: auto;
		max-width: 1000px;
		padding: 40px;
	}
}

.button {
	display: block !important;
	background-color: #286b96;
	border: 1px solid #ffffffa0;
	margin: 0px;
	height: 50px;
	min-width: 150px !important;
	padding: initial !important;
	border-radius: 20px;
}

.big {
	font-size: 200%;
}

p,
h3,
h4,
b,
div {
	color: black;
	line-height: 140%;
}

span.dusche {
	color: #444;
}

p.modintro {
	font-style: italic;
	line-height: 130%;
}

.white {
	padding: 20px;
	background-color: #cc661733;
	border-radius: 10px;
	margin-bottom: 20px;
}

.white,
div.white p,
.white h4 {
	color: #fff;
	font-size: 110%;
	margin-left: -2px;
}

h3 {
	font-size: 38px;
	font-family: 'Raleway-Light';
}

h4 {
	font-size: 20px !important;
	font-weight: bold;
}

.italic p {
	font-style: italic
}

.pic {
	width: 100%;
	border-radius: 10px;
	margin-bottom: 40px;
}

.terms {
	border-radius: 10px;
	background-color: #ffffff40;
	padding: 10px;
}

.terms p {
	font-size: 80% !important;
}
</style>
<script>
import axios from 'axios';

export default {
	data() {
		return {
			submitDone: false,
			submitted: false,
			failed: false,
			terms: false,
			signup: {
				fullname: '',
				email: '',
				phoneno: '',
				eventname: 'LiveEvent1',
			},
			STATES: {
				PENDING: 0,
				SUCCESS: 1,
				FAILED: -1,
			},
			state: 0,
			showTerms: false,
		};
	},
	methods: {
		submit() {
			if (!this.signup.email || !this.signup.fullname || !this.signup.phoneno || !this.terms) {
				alert("Du mangler at udfylde felterne og/eller acceptere betingelserne");
				return;
			}
			console.log("Getting quickpay link... hang tight");
			this.submitted = true;
			axios.post('/payments/getquickpaylink', this.signup)
				.then((response) => {
					console.log("Got response: ", response);
					let url = response.data;
					if (url.indexOf("http") < 0) {
						alert("Der opstod en fejl under behandlingen af dine data. Check venligst din indtastning.");
						return;
					}
					this.submitted = false;
					location.href = url;
					//this.submitDone = true;
				})
				.catch(error => {
					console.log(error.response);
					this.submitted = false;
					if (error.response.data.errorMessage) {
						if (error.response.data.errorMessage == "Already purchased or registered")
							alert("Det ser ud til at du allerede er tilmeldt dette event.");
						else
							alert("Der skete en fejl: " + error.response.data.errorMessage);
					}
					else
						alert("Der skete en fejl under aktivering af QuickPay link: " + error.response.toString());
				});
			console.log("done");
			return true;
		},
		getOrderStatus(qpid) {
			//debugger; // eslint-disable-line no-debugger
			axios.get(`/payments/orderstatus/${qpid}`)
				.then((response) => {
					console.log(response.data);
					//data response: { "result": -1, "message" : string }. result = -1 failed, 0, waiting, 1 processed(ok)
					this.state = parseInt(response.data.result);
					if (this.state == 0)
						setTimeout(() => this.getOrderStatus(qpid), 1000);
					this.submitDone = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
	},
	mounted() {
		if (this.$route.query.failed == "1") {
			this.state = this.STATES.FAILED;
		} else if (this.$route.query.qpid != null) {
			this.getOrderStatus(this.$route.query.qpid);
		}
	}
}
</script>