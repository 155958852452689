<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Nyhedsbrev konfiguration</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body" style="font-size:80%">

							<form-group id="input-group-1" label="Overskrift" label-for="signupTitle">
								<input type="text" class="form-control form-control-sm" name="signupTitle"
									v-model="newsLetterData.signupTitle"
									placeholder="Fx: Skriv dig up til mit ugentlige nyhedsbrev" />
							</form-group>

							<form-group id="input-group-1" label="Bekrivelse" label-for="description">
								<b-form-textarea id="description" v-model="newsLetterData.description"
									placeholder="Fx: 'Og få gratis kostplaner eller Lækre opskrifter tilsendt hver uge. Jeg spammer ikke.sender kun gratis og god information ugentligt. Det er altid muligt og nemt af afmelde sig igen'"
									rows="3" max-rows="3" required></b-form-textarea>
							</form-group>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style scoped>
.modal-dialog,
.modal-content {
	min-height: 400px;
	/* height: calc(100% - 20px);*/
}

.modal-body {
	overflow-y: scroll;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}
</style>
<script>
import axios from 'axios'

export default {
	data() {
		return {
			newsLetterData: {
				signupTitle: "",
				description: ""
			},
			showModal: false,
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
		practitioner() {
			return this.$store.getters.practitioner;
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		saveForm() {
			axios.post(`/practitioner/newslettersignupconfig/${this.practitionerId}`, this.newsLetterData)
				.then(() => {
					this.$emit("refresh");
					this.showModal = false;
				})
				.catch(error => {
					alert(error.response.data);
					console.log(error + "\n" + error.response.data);
				});
		},
		fetchItems() {
			//console.log("this.practitionerId: " + this.practitionerId)
			if (this.practitionerId <= 0) return -1;
			axios
				.get(
					`/practitioner/newslettersignupconfig/${this.practitionerId}`
				)
				.then((response) => {
					//console.log(response);
					this.newsLetterData = response.data;
				})
				.catch((error) => console.log(error));
		},
		show() {
			this.showModal = true;
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>
