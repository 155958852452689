<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Mig og mine værdier</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<form-group id="input-group-4"
								label="Personlig overskrift: F.eks: Hvem er jeg, eller Min baggrund, eller Hjertet bag"
								label-for="aboutmeLabel" style="font-size:16px !important">
								<b-form-input type="text" id="aboutmeLabel" v-model="aboutme.label" />
							</form-group>
							<br />

							<html-editor ref="editor" htmlinput="Fortæl om dig selv" />
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="showModal = false">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<style type="text/css">
.editor {
	min-height: 52vh !important;
	max-height: 52vh !important;
}
</style>
<style scoped>
.modal-dialog,
.modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	height: 70vh;
	overflow-y: auto;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}


@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>
<script>
import HtmlEditor from './HtmlEditor.vue'
import axios from 'axios';

export default {
	components: {
		HtmlEditor,
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	data() {
		return {
			aboutme: {},
			showModal: false,
		}
	},
	methods: {
		saveForm() {
			//	debugger; // eslint-disable-line no-debugger
			this.aboutme.text = this.$refs.editor.getHTML();
			axios.post(`/practitioner/${this.practitionerId}/aboutme`, this.aboutme)
				.then(() => {
					this.showModal = false;
					this.$emit("refresh");
				})
				.catch(error => {
					console.log(error);
				});
		},
		show() {
			this.showModal = true;
			this.fetchItems();
		},
		fetchItems() {
			axios
				.get(`/practitioner/${this.practitionerId}/aboutme`)
				.then(response => {
					this.aboutme = response.data;
					if (this.aboutme.label == null)
						this.aboutme.label = "Om mig";
					this.$refs.editor.setEditorContents(this.aboutme.text);
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
}
</script>