<template>
	<div v-if="practitionerTreatments.length > 0 || isPractitionerPageOwner">
		<treatments ref="treatments" @refresh="fetchItems" />
		<table class="services" v-if="practitionerTreatments">
			<thead>
				<tr>
					<th>Jeg tilbyder</th>
					<th>
						<EditIcon @click="openEditTreatments" />Varighed og pris
					</th>
				</tr>
			</thead>
			<tbody v-for="pt in practitionerTreatments" :key="pt.id">
				<tr>
					<td>{{ pt.treatmentName }}</td>
					<td><b-icon icon="clock" />&nbsp;{{ pt.duration }} &nbsp;&nbsp;<b-icon icon="credit-card" />&nbsp;kr.&nbsp;{{ pt.price }}</td>
				</tr>
				<tr v-if="pt.description" class="text-muted smaller">
					<td colspan="2" style="padding-top:0px">{{ pt.description }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<style scoped>
tr.smaller td { font-size:0.9rem !important }
</style>
<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios';
import treatments from './TreatmentEdit.vue'
import EditIcon from './EditIcon.vue'; 

export default ({
	components: {
		treatments, EditIcon
	},
	data() {
		return {
			practitionerTreatments : []
		}
	},
	computed: {
		...mapState(['practitionerId']),
        ...mapGetters(['isPractitioner', 'isPractitionerPageOwner']),
    },	
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		openEditTreatments() {
			this.$refs.treatments.show();
		},
		fetchItems() {
			axios
				.get(`/practitioner/treatments/${this.practitionerId}`)
				.then(response => {
					this.practitionerTreatments = response.data;
				})
				.catch(error => 
					console.log(error)
				);
		},
	},
	created() {
		this.fetchItems();
	}
})
</script>

