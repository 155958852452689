<template>
	<section class="profile_content">
		<div class="container">
			<div class="row">
				<div class="col-xl-4 col-lg-5 col-sm-12 left-box">
					<div class="map drop_shadow">
						<google-static-map />
					</div>
					<div class="card book_time drop_shadow">
						<generic-info-edit-view />
					</div>
					<book ref="book"></book>
					<button class="btn book" @click="openBooking">BOOK TID HOS MIG</button>
					<gallery-view :showTitle="true" />
					<news-letter-sign-up />
					<div class="contact_buttons">
						<a :href="`mailto:${this.practitioner.publicEmail}`" v-if="this.practitioner.publicEmail"><button class="btn">SEND MIG EN MAIL</button></a>
						<a :href="`tel:${this.practitioner.phone}`" v-if="this.practitioner.phone"><button class="btn" v-if="this.practitioner.phone">RING MIG OP</button></a>
					</div>
					<some-view />
				</div>
				<div class="col-xl-8 col-lg-7 col-sm-12 right-box">
					<div>
						<div v-if="isProfileBasePage" class="text">
							<about-me-view />
						</div>
						<div v-if="this.$route.path.indexOf('blogs') > 0">
							<blog-items itemType="blogs"/>
						</div>
						<div v-if="this.$route.path.indexOf('blog/') > 0">
							<blog-item-view />
						</div>
						<div v-if="this.$route.path.indexOf('event/') > 0">
							<event-item-view />
						</div>
						<div v-if="this.$route.path.indexOf('billeder') > 0">
							<gallery-view-item />
						</div>
						&nbsp;<br />
					</div>
					<div class="table drop_shadow">
						<div v-if="isProfileBasePage">
							<treatments-view />
						</div>
					</div>
					<!--========================= img_text ============================-->
					<div class="currently" v-if="isProfileBasePage">
						<events itemType="events" />
					</div>
					<!--========================= img_text ============================-->
					<blog-items v-if="isProfileBasePage" itemType="blogs" />
				</div>
			</div>
		</div>
	</section>
</template>
<style scoped>
button {
	/* padding: 13px 34px 14px !important; */
	background: #1692B6 !important;
	color: #fff !important;
	font-size: 18px !important;
	font-weight: normal !important;
	line-height: normal !important;
	letter-spacing: normal;
	border-radius: 5px;
	padding: 10px !important;
	border: none;
}
</style>
<script>
import AboutMeView from './Profile/AboutMeView.vue'
import TreatmentsView from './Profile/TreatmentsView.vue'
import GenericInfoEditView from './Profile/GenericInfoView.vue'
import Events from './Profile/Events.vue'
import BlogItemView from './Profile/BlogItemView.vue'
import EventItemView from './Profile/EventItemView.vue'
import BlogItems from './Profile/BlogItems.vue'
import GalleryView from './Profile/GalleryView.vue'
import GalleryViewItem from './Profile/GalleryViewItem.vue'
import SomeView from './Profile/SomeView.vue'
import NewsLetterSignUp from './Profile/NewsLetterSignUp.vue'
import GoogleStaticMap from './Profile/GoogleStaticMap.vue'
import { mapGetters } from 'vuex'
import Book from './Booking/Book.vue'

export default {
	components: { SomeView, TreatmentsView, AboutMeView, BlogItems, GenericInfoEditView, Events, BlogItemView, 
		EventItemView, GalleryView, GalleryViewItem, NewsLetterSignUp, GoogleStaticMap, Book },
	computed: {
		...mapGetters(["isLoggedIn", "practitionerId", "practitioner", "isAdmin", "calendarEnabled"]),
		isProfileBasePage() {
			return this.$route.name === "BehandlerProfilePreview";
		}
	},
	methods: {
		openBooking() {
			console.log("OpenBooking: " + this.calendarEnabled);
			if(this.calendarEnabled)
				this.$refs.book.show();
			else
				alert("Kommer snart!");
		},
	}
}
</script>