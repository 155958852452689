<template>
    <section>
        <registration-progress></registration-progress>
        <registration-first-slide v-if="currentStep == steps.firstStep" v-on:fillInformationCompleted="fillInformationCompleted"></registration-first-slide>
        <registration-done v-if="currentStep == steps.doneStep"></registration-done>
    </section>
</template>
<script>
    import { mapState } from "vuex";
    import RegistrationProgress from "./Progress.vue";
    import RegistrationFirstSlide from "./Registration_1.vue";
    import RegistrationDone from "./Registration_done.vue";

    export default {
        components: {
            RegistrationFirstSlide, RegistrationProgress, RegistrationDone
        },
        data() {
            return {
                steps: {
                    firstStep: 1,
                    doneStep: 2,
                },
            };
        },
        computed: mapState({
            currentStep: state => state.currentStep,
        }),
        methods: {
            fillInformationCompleted() {
                //this.currentStep = this.steps.doneStep;
                this.$store.state.currentStep = this.steps.doneStep;
            },
        },
        created() {
            this.$store.state.currentStep = 1;
        },
        beforeDestroy() {
            clearInterval(this.interval)
        },
    }
</script>
<style lang="scss" scoped>
</style>
