import VueCookies from "vue-cookies"
import store from "./store"

const auth = {};

const token = VueCookies.get("token");
if (token != undefined && token) {
	store.commit('setToken', token);
}
const user = VueCookies.get("user");
if (user) {
	store.commit('setUser', user);
}

export default auth;