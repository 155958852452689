const misc = {
	getTimeStringFromString(dateTimeStr) {	//"2022-10-14T14:30:00" -> 2022-10-14
		if (!dateTimeStr)
			return "";
		const tPos = Math.max(dateTimeStr.indexOf(" "), dateTimeStr.indexOf("T"));
		if (tPos < 0) return "";
		return dateTimeStr.substr(tPos + 1);
	},
	getDateStringFromString(dateStr) {	//"2022-10-14T14:30:00" -> 14:30
		if (!dateStr)
			return "";
		return dateStr.substr(0, 10);
	},
	//comes with format: 2022-09-15
	parseDate(dateStr) {
		if (!dateStr)
			return "";
		let year = parseInt(dateStr.substr(0, 4));
		let month = parseInt(dateStr.substr(5, 2));
		let day = parseInt(dateStr.substr(8, 2));
		return new Date(year, month - 1, day);
	},
	//comes with format: 2022-09-15 10:00 OR 2022-09-15T10:00:00 (with the T)
	parseDateTime(dateStr) {
		if (!dateStr)
			return "";
		let hour = 0, minute = 0;
		let year = parseInt(dateStr.substr(0, 4));
		let month = parseInt(dateStr.substr(5, 2));
		let day = parseInt(dateStr.substr(8, 2));
		if (dateStr.indexOf(":") > 0) {
			hour = parseInt(dateStr.substr(11, 2));
			minute = parseInt(dateStr.substr(14, 2));
		}
		return new Date(year, month - 1, day, hour, minute);
	},
	formatDateForApi(d) {
		var date = new Date(d)
		var year = date.getFullYear();
		var month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : '0' + month;
		var day = date.getDate().toString();
		day = day.length > 1 ? day : '0' + day;
		return `${year}-${month}-${day}`;
	},
	formatDateTimeForApi(date) {
		return this.formatDateForApi(date) + " " + this.formatTimeZeroPadded(date);
	},
	//input ? -> 24/12-2024
	formatDateForDisplay(d) {
		var date = new Date(d)
		var year = date.getFullYear();
		var month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : '0' + month;
		var day = date.getDate().toString();
		day = day.length > 1 ? day : '0' + day;
		return `${day}/${month}-${year}`;
	},
	formatTimeZeroPadded(d) {
		let date = new Date(d)
		if (!date) return;
		let time = "";
		if (date.getHours() != 0 || date.getMinutes() != 0)
			time = date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0");
		return time;
	},
	formatTime(d) {
		let date = new Date(d)
		if (!date) return;
		let time = "";
		if (date.getHours() != 0 || date.getMinutes() != 0)
			time = date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0");
		return time;
	},
	formatDateTimeForDisplay(d) {
		return this.formatDateForDisplay(d) + " " + this.formatTime(d);
	},
	//in: 01:24 -> output: 2 timer 24 minutter
	getDurationForDisplay(durationStr) {	//01:45
		let hours = parseInt(durationStr.substr(0, 2));
		let minutes = parseInt(durationStr.substr(3, 2));
		let res = "";
		if (hours > 0) res = `${hours} time${hours > 1 ? 'r' : ''} og `;
		res += `${minutes} minutter`;
		return res;
	},
	getDurationMinutes(durationStr) {	//01:45
		let hours = parseInt(durationStr.substr(0, 2));
		let minutes = parseInt(durationStr.substr(3, 2));
		return hours * 60 + minutes;
	},
	getMinutes(date1, date2) {
		let duration = date2 - date1;
		let minutes = duration / 1000 / 60;
		let hours = Math.floor(duration / 1000 / 60 / 60);
		let res = "";
		if (hours > 0) res = `${hours} time${hours > 1 ? 'r' : ''} `;
		if (minutes - hours * 60 > 0)
			res += `${minutes - hours * 60} minutter`;
		return res;
	},
	getMonday() {
		const now = new Date();
		const res = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 2);
		return res;
	}
}

export default misc;