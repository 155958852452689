<template>
	<div style="padding:20px">
		Root<br />

		<br />
		<a href="/#/forside">Forside</a><br />
		<a href="/#/behandler/4">Behandler 4</a><br />
		<a href="/#/preview">Preview page with signup</a><br />
		<a href="/#/verifypractitioner?verificationid=C88E941A-3573-47DA-92C1-B73CCFBE5B01">Verify
			Practitioner</a><br />
		Login:<br>
		<input v-model="username" placeholder="username" />
		<br />
		<br />
		<input v-model="password" placeholder="password" type="password" />
		<br />
		<br />
		<button type="submit" @click="doLogin">doLogin</button>
		<br />
		Register test<br />
		<br />
		<button type="button" @click="register">Register test</button><br />
		<br />
		<button type="button" @click="msLogin">Ms Login</button><br />
		<br />
		<br />
		<a href="/#/admin">Admin</a><br />
		<br />

		<b-form>
			<b-container>
				<b-row class="pt-2">
					<b-col cols="6" style="min-width:100px;max-width:255px">
						<b-form-group id="endTimeGroup" label="Dato" :label-for="startDate">
							<input type="date" id="startDate" class="form-control" />
						</b-form-group>
					</b-col>
					<b-col cols="2" style="min-width:120px">
						<b-form-group id="endTimeGroup" label="Start" :label-for="endTime">
							<input type="time" id="startTime" class="form-control" />
						</b-form-group>
					</b-col>
					<b-col cols="2" style="min-width:120px">
						<b-form-group id="endTimeGroup" label="Slut" :label-for="endTime">
							<input type="time" id="endTime" class="form-control" />
						</b-form-group>
					</b-col>
					<b-col cols="2" style="min-width:120px; display: flex;
  align-items: center;"><br/>
						(120 min.)
					</b-col>
				</b-row>
			</b-container>
		</b-form>
		<hr />
		<ValidateTest></ValidateTest>
	</div>
</template>
<script>
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import axios from 'axios';
import ValidateTest from "./Profile/ValidateTest.vue";

export default {
	components: { ValidateTest },
	name: "Home",
	computed: {
		...mapGetters(["isLoggedIn"])
	},
	data: () => {
		return {
			username: "ruvan@frontlab.com",
			password: "aabbccddee",
		};
	},
	methods: {
		...mapMutations(["setUser", "setToken"]),
		msLogin() {
			const user = {
				username: "ruvan@frontlab.com",
				password: "aabbccddee"
			};
			axios.post(process.env.VUE_APP_MS_API + `/auth/login`, user, { headers: { "Content-type": "application/json", } })
				.then(res => {
					//debugger; // eslint-disable-line no-debugger
					console.log("login done");
					const response = JSON.parse(res.data);
					const { access_token, userId, userName, firstName, lastName, roles } = response;
					console.log("access_token: " + access_token);
					console.log("userId: " + userId);
					console.log("username: " + userName);
					console.log("firstName: " + firstName);
					console.log("lastName: " + lastName);
					console.log("roles: " + roles);
					//this.setUser({ id: userId, fullname: firstName + ' ' + lastName, practitionerId: practitionerId });
					this.setToken(access_token);
				})
				.catch(error => {
					console.log(error);
				});
		},
		register() {
			/* 	var s = {
				"userInfo":
				{
					"gender": 0,
					"email": "john@john.dk",
					"password": "12345678",
					"confirmPassword": "12345678", "userName": "abcefg", "firstName": "abcdefg", "lastName": "abcdefg", "birthday": "01-01-72", "phoneNumber": "12345678",
					"formData": null }
				}*/
			const data = {
				userInfo: {
					FirstName: "John",
					LastName: "Hansen",
					Email: "aaa@bb.dk",
					Password: "12345678",
					ConfirmPassword: "12345678",
					Gender: "Male",
					Birthday: "01-01-72",
					UserName: "aaa@bbbbb.dk",
					PhoneNumber: "11223344"
				}
			};
			axios.post(process.env.VUE_APP_MS_API + `/auth/register`, data, { headers: { "Content-type": "application/json", } })
				.then(res => {
					//debugger; // eslint-disable-line no-debugger
					console.log("register done");
					const { userid } = res.data;
					console.log("userid: " + userid);
				})
				.catch(error => {
					console.log(error);
				});
		},
		doLogin() {
			axios.post(`/users/authenticate2`, JSON.stringify({
				username: this.username,
				password: this.password
			}), {
				headers: {
					"Content-type": "application/json",
				}
			})
				.then(res => {
					const { userId, firstName, lastName, access_token, practitionerId, roles } = res.data;
					debugger; // eslint-disable-line no-debugger
					this.setUser({ id: userId, firstName: firstName, lastName: lastName, practitionerId: practitionerId, roles: roles });
					this.setToken(access_token);
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
}
</script>