<template>
	<div style="border:0px solid red;">
		<RatePractitioner ref="rate"></RatePractitioner>
		<div class="review_star" @click="showRatePractitioner" title="Klik for at lave en anmeldelse" style="cursor:pointer">
			<star-rating v-model="ratingObj.averageRating" :star-size="20" :increment="0.5" :read-only="true" :rounded-corners="false" 
			:show-rating="false" text-class="" style="margin-top:11px;display:inline-block;padding-bottom:5px"></star-rating>
		</div>
		<b-icon style="font-size:23px;padding-top:5px;cursor:pointer;color:cornflowerblue" @click="showRatePractitioner" title="Lav en anbefaling" icon="hand-thumbs-up" aria-hidden="true" />
	</div>
</template>
<style scoped>
div.review_star {
	display:inline-block;
	align-items: center;
}
span.doReview {
	font-size:14px;
}
.review_star ul{
	display:inline-block;
	align-items: center;
	justify-content: flex-start;
	list-style-type: none;
	padding:0px;
	margin-bottom: 0px;
}

.review_star ul li a{
	float: left;
	width: 100%;
	display:block;
}

.review_star ul li a img{
	float:left;
	width:100%;
	display: block;
	margin-bottom: 0px;
	margin-top:4px;
}
</style>
<script>
import axios from 'axios'
import StarRating from 'vue-star-rating'
import { mapGetters, mapState } from "vuex";
import RatePractitioner from './RatePractitioner.vue';

export default {
	components: {
		RatePractitioner, StarRating
	},
	computed: {
		...mapState(["practitionerId"]),
		...mapGetters(["practitionerId", "isPractitioner", "isLoggedIn", "user"]),
	},
	data() {
		return {
			ratingObj: {}
		}
	},
	methods: {
		showRatePractitioner() {
			this.$refs.rate.show();
		},
		fetchItems() {
			axios
				.get(`/voting/${this.practitionerId}/rating`)
				.then(response => {
					this.ratingObj = response.data;
				})
				.catch(error => 
					console.log(error)
				);			
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
}
</script>