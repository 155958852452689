<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Følg mig - sociale medier</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body" style="font-size:80%">

							<form-group id="input-group-1" label="Indtast Facebook 'navn'. F.eks.: 'minsundhed'"
								label-for="facebookName">
								<input type="text" class="form-control form-control-sm" name="facebookName"
									v-model="soMeData.facebookName"
									placeholder="Indtast det der kommer efter http://facebook.com/" />
							</form-group>

							<form-group id="input-group-1" label="Indtast Instagram 'navn'. F.eks.: 'minsundhed'"
								label-for="instagramName">
								<input type="text" name="instagramName" class="form-control form-control-sm"
									v-model="soMeData.instagramName"
									placeholder="Indtast det der kommer efter http://instagram.com/" />
							</form-group>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="closeForm">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style scoped>
.modal-dialog,
.modal-content {
	min-height: 400px;
	/* height: calc(100% - 20px);*/
}

.modal-body {
	overflow-y: scroll;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}
</style>
<script>
import axios from 'axios'

export default {
	data() {
		return {
			soMeData: {
				facebookName: "",
				instagramName: ""
			},
			showModal: false,
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
		practitioner() {
			return this.$store.getters.practitioner;
		},
	},
	watch: {
		practitionerId() {
			this.fetchItems();
		},
	},
	methods: {
		closeForm() {
			this.showModal = false;
			this.$emit("refresh");
		},
		saveForm() {
			axios.post(`/practitioner/some/${this.practitionerId}`, this.soMeData)
				.then(() => {
					this.closeForm();
				})
				.catch(error => {
					alert("Der opstod en fejl under save-data: " + error.response.data);
					console.log(error + "\n");
					console.log(error.response.data);
				});
		},
		fetchItems() {
			if (this.practitionerId <= 0) return -1;
			axios
				.get(
					
					`/practitioner/some/${this.practitionerId}`
				)
				.then((response) => {
					if(response.data != "")
						this.soMeData = response.data;
				})
				.catch((error) => console.log(error));
		},
		show() {
			this.showModal = true;
		},
	},
	created() {
		this.fetchItems();
	}
}
</script>
