import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router';
import Routes from './router'
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'
import Vuex from 'vuex'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueToast from 'vue-toast-notification';

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
	position: 'top',
	duration: 5000
});
Vue.use(VueMask);
Vue.use(Vuex)
//Vue.use(VueCookies, { expire: '7d'})
Vue.use(VueRx)
Vue.use(VuejsClipper)
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GMAP_API_KEY,
		libraries: 'places',
	}
});
Vue.use(VuelidateErrorExtractor, {
	template: templates.singleErrorExtractor.bootstrap3,
	messages: {
		required: 'Feltet skal udfyldes/vælges',
		sameAsPassword: 'Feltet "{attribute}" skal være ens',
		email: 'Indtast gyldig emailadresse',
		minLength: 'Feltet "{attribute}" skal være længere',
		numeric: 'Feltet "{attribute}" skal kun bestå af tal',
		isEmailAvail: ' ',
		isUsernameAvail: 'Brugernavn er allerede taget',
		maxLength: 'Længden på "{attribute}" er overskrevet'
	},
})

const router = new VueRouter({
	mode: 'history',
	routes: Routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			Vue.nextTick(() => {
				document.getElementById(to.hash.substring(1)).scrollIntoView();
			});
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

import VueGtag from 'vue-gtag';
console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV == "production") {
	Vue.use(VueGtag, {
		config: { id: "G-S53KJXJRLX" },
	}, router);
}

router.beforeEach((to, from, next) => {
	// ...
	// explicitly return false to cancel the navigation
	//debugger // eslint-disable-line no-debugger
	if (to.path.toLowerCase() === "/admin" && !store.getters.isAdmin)
		return false;
	next();
})


// axios.interceptors.response.use(undefined, error => {
// 	console.log("Interceptor error caught: " + error.response);
// })

Vue.config.productionTip = false;

import auth from './auth';
Vue.use(auth);


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@/assets/css/app.scss";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import conf from './conf';
//console.log(location);
var baseUrl;
//var origin = "https://minholistiskebehandler.minsundhed.com";
if (location.origin.indexOf("localhost") < 0) {
//if (origin.indexOf("localhost") < 0) {
	let origin = location.origin;
	//origin = "https://minholistiskebehandler.minsundhed.com";
	var index = origin.indexOf('://') + 3;
	baseUrl = origin.substr(0, index);
	var urlWithoutScheme = origin.substr(index);
	if (urlWithoutScheme.indexOf(":") > 0)
		urlWithoutScheme = urlWithoutScheme.substring(0, urlWithoutScheme.indexOf(":"))
	baseUrl += 'api-';
	if (urlWithoutScheme.indexOf('www.') === 0) {
		baseUrl += urlWithoutScheme.substr(4);
	} else {
		baseUrl += urlWithoutScheme;
	}
	conf.baseUrl = baseUrl;
	baseUrl += '/api/';
	conf.api = baseUrl;
} else {
	conf.api = process.env.VUE_APP_API_URL;
}
 
import axios from 'axios';
axios.defaults.baseURL = conf.api;

console.log("conf api: " + conf.api);

Vue.use(conf);

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')
