<template>
	<transition name="fade" v-if="showModal">
		<div class="modal-mask admin">
			<div class="modal-wrapper">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content wider">
						<div class="modal-header">
							<h5 class="modal-title">Jeg tilbyder</h5>
							<b-button @click="showModal = false" type="button" class="close" data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</b-button>
						</div>
						<div class="modal-body">
							<span class="text-muted" style="">Her kan du oprette de ydelser/behandlinger du tilbyder.
								Behandlingstyper
								som ikke står i listen vil blive tilføjet automatisk når du gemmer.</span>
							<br />&nbsp;

							<div v-if="editEntry">
								<form @submit="saveForm" ref="treatmentForm">
									<form-group :validator="$v.multiselectValue" id="input-group-1"
										label="Behandling / ydelse" label-for="multiselects"
										description="Vælg en eksisterende behandling eller indtast en ny og den vil blive oprettet">

										<multiselect :taggable="true" :options="treatments"
											tag-placeholder="Klik for at tilføje"
											deselectLabel="Klik for at fjerne valget" selectLabel="Klik for at vælge"
											selectedLabel="Valgt" v-model="multiselectValue" track-by="id"
											label="treatmentName" @tag="addTag" @input="$v.multiselectValue.$touch()" />
									</form-group>

									<form-group id="input-group-2" :validator="$v.editPractitionerTreatment.duration"
										label="Varighed" label-for="varighed">
										<multiselect id="varighed" :taggable="true" :options="['00:15','00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', 
										'03:00', '03:15', '03:30', '03:45',
										'04:00', '04:15', '04:30', '04:45' ,
										'05:00', '05:15', '05:30', '05:45' ,
										'06:00', '06:15', '06:30', '06:45' ,
										'07:00', '07:15', '07:30', '07:45' ,
										'08:00', '08:15', '08:30', '08:45' ,
										'09:00', '09:15', '09:30', '09:45' ,
										'10:00', '10:15', '10:30', '10:45' ,
										'11:00', '11:15', '11:30', '11:45' ,
										'12:00', '12:15', '12:30', '12:45' ,												
										]" deselectLabel="Klik for at fjerne valget" selectLabel="" selectedLabel="Valgt"
											v-model="editPractitionerTreatment.duration"
											@input="$v.editPractitionerTreatment.duration.$touch()" />
									</form-group>
									<form-group :validator="$v.editPractitionerTreatment.price" id="input-group-3"
										label="Pris" label-for="input-2">
										<input type="number" class="form-control form-control-sm"
											v-model="editPractitionerTreatment.price" placeholder=""
											@input="$v.editPractitionerTreatment.price.$touch()"
											style="width:75px;height:39px" required />
									</form-group>
									<form-group id="input-group-4" label="Kan bookes via kalender" label-for="bookable">
										<input type="checkbox" class="form-check-input" id="bookable"
											v-model="editPractitionerTreatment.bookable" style="font-size:22px">
									</form-group>
									<form-group id="input-group-5" label="Beskrivelse" label-for="desc">
										<b-form-textarea id="desc" :state="editPractitionerTreatment.description.length <= 1024" v-model="editPractitionerTreatment.description"
											placeholder="Indtast evt. beskrivelse" rows="2" max-rows="5">
										</b-form-textarea>
									</form-group>
								</form>
							</div>
							<!-- *************************************************************************************************** -->
							<!-- *************************************************************************************************** -->
							<div v-else>
								<button size="sm" class="mb-2 btn btn-primary" @click="addTreatment">
									<b-icon icon="plus-circle-fill" title="Tilføj ny" variant="" /> Tilføj ydelse
								</button>
								<b-table-simple class="tight" small responsive>
									<b-thead>
										<b-th width="55%">Ydelser / behandling</b-th>
										<b-th width="25%">Varighed</b-th>
										<b-th style="width:60px;text-align:right">Pris</b-th>
										<b-th width="15%" class="text-center">Kan bookes</b-th>
										<b-th>&nbsp;</b-th>
									</b-thead>
									<b-tbody v-for="(practitionerTreatment, index) in practitionerTreatments"
										:key="practitionerTreatment.id">
										<b-tr>
											<b-td class="header">
												{{ practitionerTreatment.treatmentName }}
											</b-td>
											<b-td>
												{{ practitionerTreatment.duration }}
											</b-td>
											<b-td style="text-align:right">
												{{ practitionerTreatment.price }}
											</b-td>
											<b-td class="text-center">
												{{ practitionerTreatment.bookable ? "Ja" : "Nej" }}
											</b-td>
											<b-td style="vertical-align:middle">
												<b-icon style="font-size:17px;cursor:hand" title="Rediger"
													icon="pencil-square"
													@click="editTreatment(practitionerTreatment, index)" />
											</b-td>
											<b-td style="vertical-align:middle">
												<b-icon style="font-size:17px;cursor:hand" title="Slet"
													icon="trash-fill"
													@click="deleteTreatment(practitionerTreatment, index)" />
											</b-td>
										</b-tr>
										<b-tr>
											<b-td colspan="6">
												<div class="cropped" style="">
													<span class="text-muted">{{ practitionerTreatment.description
													}}</span>
												</div>
											</b-td>
										</b-tr>
										<b-tr>
											<b-td colspan="6">
												<hr />
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</div>
						</div>
						<div v-if="editEntry" class="modal-footer">
							<button type="button" class="btn btn-primary" @click="saveForm">Gem</button>
							<button type="button" class="btn btn-secondary" @click="editEntry = false">Fortryd</button>
						</div>
						<div v-else class="modal-footer">
							<button type="button" class="btn btn-primary" @click="closeForm">Luk</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { required } from 'vuelidate/lib/validators'

export default ({
	components: { Multiselect },
	data() {
		return {
			editPractitionerTreatmentIndex: -1,
			editEntry: false,
			newId: -1,
			multiselectValue: null,
			treatments: [],
			editPractitionerTreatment: null,
			practitionerTreatments: [],
			showModal: false,
		}
	},
	computed: {
		practitionerId() {
			return this.$store.getters.practitionerId;
		},
	},
	validations: {
		multiselectValue: { required },
		editPractitionerTreatment: {
			price: { required },
			duration: { required },
		}
	},
	methods: {
		closeForm() {
			this.showModal = false;
			this.$emit("refresh");
		},
		submit(e) {
			e.preventDefault();
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.formIsValid = false;
			} else {
				this.formIsValid = true;
			}
			console.log("Submitting " + this.formIsValid);
		},
		submit2() {
			const isFormCorrect = this.v$.$validate()
			if (!isFormCorrect)
				console.log("Not correct");

			this.$refs.treatmentForm.submit();
		},
		saveForm(e) {
			e.preventDefault();
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log("form valid: " + this.$v.$invalid);
				return;
			}
			if (this.editPractitionerTreatmentIndex < 0) //adding new
			{
				this.practitionerTreatments.push({ "id": -1, "treatmentName": "" });
				this.editPractitionerTreatmentIndex = this.practitionerTreatments.length - 1;
			}
			else
				this.practitionerTreatments[this.editPractitionerTreatmentIndex].id = this.editPractitionerTreatment.id;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].practitionerId = this.practitionerId;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].treatmentId = this.multiselectValue.id;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].treatmentName = this.multiselectValue.treatmentName;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].duration = this.editPractitionerTreatment.duration;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].price = this.editPractitionerTreatment.price;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].bookable = this.editPractitionerTreatment.bookable;
			this.practitionerTreatments[this.editPractitionerTreatmentIndex].description = this.editPractitionerTreatment.description;
			this.postForm();
			console.log("Submitting ");

			setTimeout(() => {
				this.fetchItems();
				this.editEntry = false;
			}, 100);
		},
		editTreatment(pt, index) {
			//debugger; // eslint-disable-line no-debugger
			this.editPractitionerTreatment = {
				id: pt.id,
				treatmentId: { id: pt.treatmentId, treatmentName: pt.treatmentName },
				treatmentName: pt.treatmentName,
				duration: pt.duration,
				price: pt.price,
				bookable: pt.bookable,
				description: pt.description
			}
			this.editPractitionerTreatmentIndex = index;
			this.multiselectValue = this.editPractitionerTreatment.treatmentId;
			this.editEntry = true;
		},
		deleteTreatment(practitionerTreatment) {
			axios
				.delete(`/practitioner/treatments/${this.practitionerId}/deletetreatment/${practitionerTreatment.id}`)
				.then(() => {
					this.practitionerTreatments = null;
					this.fetchItems();
				})
				.catch(error =>
					console.log(error)
				);
		},
		addTag(newTag) {
			const pt = {
				id: this.newId--,
				treatmentName: newTag
			}
			this.treatments.push(pt);
			this.multiselectValue = pt;
		},
		addTreatment() {
			this.editPractitionerTreatmentIndex = -1;
			this.editPractitionerTreatment = {
				id: -1,
				treatmentName: "",
				duration: null,
				price: 0,
				bookable: true,
				description: "",
			};
			this.multiselectValue = null;
			this.editEntry = true;
		},
		fetchItems() {
			axios
				.get('/practitioner/treatments')
				.then(response => {
					this.treatments = response.data;
				})
				.catch(error =>
					console.log(error)
				);
			axios
				.get(`/practitioner/treatments/${this.practitionerId}`)
				.then(response => {
					this.practitionerTreatments = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
		postForm() {
			axios
				.post(`/practitioner/treatments/${this.practitionerId}/save`, this.practitionerTreatments)
				.then(() => {
					this.editEntry = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		show() {
			this.fetchItems();
			this.showModal = true;
		}
	},
	created() {
	}
})
</script>

<style scoped>
div.tight table td {
	font-size: 100%;
	border-bottom: 0px !important;
	padding-top: 2px;
	padding-bottom: 2px
}

div.tight table tbody {
	border-top: 0px solid #eee
}

div.tight table th {
	color: #aaa;
	font-weight: normal;
	font-size: 100%;
}

div.cropped {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	font-size: 15px;
	line-height: 18px;
	max-height: 90px;
}

.modal-dialog,
.modal-content {
	min-height: 400px;
	height: 99vh;
	max-height: 89vh;
}

.modal-body {
	/* 100% = dialog height, 120px = header + footer */
	height: 80vh;
	overflow-y: auto;
}

.modal-mask {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

@media (min-width: 991px) {
	.modal-lg {
		max-width: auto !important;
	}
}

@media (min-width: 650px) {
	.wider {
		min-width: calc(100% - 20px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 20px);

	}
}

@media (min-width: 4000px) {
	.wider {
		min-width: calc(100% - 2px);
	}

	.modal-dialog {
		max-width: auto !important;
		width: calc(100% - 2px);

	}
}
</style>