<template>
	<div>
		<section class="therapist-profiles">
			<div class="intro txt-theme">
				Senest tilføjede behandlerprofiler…
			</div>
			<div class=" mt-5">
				<div class="row2">
					<carousel :perPageCustom="[[500, 1], [600, 1], [768, 3], [1024, 4], [1300, 5]]" :autoplay="true" :loop="true" :autoplayTimeout="4000"
						:paginationSize="10" style="gap:20px 20px">
						<slide v-for="(practitioner, index) in practitioners" :key="index">
							<div class="slide-content">
								<div class="photo">
									<router-link v-bind:to="{ path: `/behandler/${practitioner.id}` }">
										<img :src="`/behandlerportal/images/bh/${practitioner.primaryImage}`"
											alt="photo">
									</router-link>
								</div>
								<div class="name">{{ practitioner.name }}</div>
								<div class="designation">{{ practitioner.tagline }}</div>
								<!-- <div class="desc">{{ practitioner.desc }}</div> -->
							</div>
						</slide>
					</carousel>
				</div>
			</div>
		</section>
	</div>
</template>
<style lang="scss">
.slide-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-left:10px;
	padding-right:10px;

	.photo {
		height: 146px;
		width: 146px;
		display: flex;
		align-content: center;
		justify-content: center;
		margin-bottom: 25px;

		a {width:100%;height:100%}
		img {
			object-fit: cover;
			border-radius: 50%;
			width: 100%;
			height: 100%;
		}
	}

	.name {
		font-size: 18px;
		font-family: 'Raleway-Semibold';
		color: #515050;
	}

	.designation {
		font-size: 14px;
		font-family: "Raleway-Semibold";
		color: #1692b6;
		line-height: 2.2;
	}

	.desc {
		font-size: 14px;
		font-family: 'Raleway-Medium';
		color: #9eacba;
		text-align: center;
	}
}

.therapist-profiles {
	background: #ECF3F5;
	padding: 90px 50px;

	@media(max-width:667px) {
		padding: 40px 25px;
	}

	.VueCarousel-dot {
		margin: 10px 6px;
		width: 10px;
		height: 10px;
		padding: 0 !important;
		background-color: white !important;
		border: 3px solid #D8D8D8 !important;

		&.VueCarousel-dot--active {
			background-color: #0094B3 !important;
		}
	}

	// .VueCarousel-slide {
	//     // min-width: 300px;
	// }

	.intro {
		font-size: 38px;
		font-family: 'Raleway-Light';

		@media(max-width:767px) {
			font-size: 28px;
		}
	}
}
</style>

<script>
import { Carousel, Slide } from 'vue-carousel';
import axios from 'axios';

export default {
	components: {
		Carousel,
		Slide
	},
	data() {
		return {
			practitioners: []
		}
	},
	methods: {
		fetchItems() {
			axios
				.get(`/practitioner/entity/latest?count=10`)
				.then(response => {
					this.practitioners = response.data;
				})
				.catch(error =>
					console.log(error)
				);
		},
	},
	mounted() {
		this.fetchItems();
	}
};
</script>
	